import React, { createRef } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import keyBy from 'lodash/keyBy'
import Apis from '../../../../../../api';
import RequestByTeamToolbar from './Toolbar/RequestByTeamToolbar';
import { Button } from 'reactstrap'
import Pdf from "react-to-pdf";

const XAxisPading = { left: 60, right: 60 }
const XAxisTick = { fontSize: '0.8em' }

const labelFormatter = (value) => new Date(value).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit'
})

const dateFormatter = (value) =>
    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
    })

const findArryIndex = (arr, list) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.team === list.team && el.material === list.material && el.date === list.date && el.supplier === list.supplier && el.uom === list.uom) {
            return i
        }
    }
    return -1
}

const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [6, 12]
};

const RequestByTeam = ({ taskData, materialList, suppliersList, teamList, attributeList }) => {
    const ref = createRef()
    const wrapperRef = React.useRef()

    const [taskmaterialList, setTaskMaterialList] = React.useState([])
    const [taskmaterialDateFilterList, setTaskMaterialDateFilterList] = React.useState([])
    const [taskmaterialFilterList, setTaskMaterialFilterList] = React.useState([])
    const [requestByTeam, setRequestByTeam] = React.useState([])
    const [graphData, setGraphData] = React.useState([])
    const [material, setMaterial] = React.useState([])

    const [toolbarState, setToolbarState] = React.useState({
        team: '',
        supplier: '',
        material: '',
        from_date: '',
        to_date: ''
    })

    const taskByID = keyBy(taskData, '_id')
    const materialById = keyBy(materialList, '_id')
    const supplierById = keyBy(suppliersList, '_id')
    const teamByID = keyBy(teamList, '_id')
    const attributeById = keyBy(attributeList, '_id')

    /* Fetch Task material List */
    React.useEffect(() => {
        const raw = {
            "filter": [{ status: true }, { order_status: 'pending' }]
        }
        Apis.getTaskMaterialByFilter(raw).then((res) => {
            setTaskMaterialList(res.data.data.taskmaterials)
        }).catch((error) => {
            console.log(error)
        })
    }, [taskData])
    /* End Task material List */
    /* Filter Material List  */
    React.useEffect(() => {
        var list = []
        if (toolbarState.supplier !== '') {
            materialList.map((elm) => {
                if (toolbarState.supplier == elm.supplier) {
                    list.push(elm)
                }
            })
            setToolbarState((prev) => ({ ...(prev ?? {}), ['material']: '' }))
        } else {
            list = materialList
        }
        setMaterial(list)
    }, [materialList, toolbarState.supplier])
    /* End filter material list */
    /* Apply Team filter */
    React.useEffect(() => {
        const listed = [];
        var demo
        taskmaterialList && taskmaterialList.map((list, key) => {
            demo = {
                id: list._id,
                team: taskByID[list.task_id]?.team.value,
                material: list.material,
                supplier: materialById[list.material]?.supplier,
                date: new Date(taskByID[list.task_id]?.startDate).setHours(0, 0, 0, 0),
                quantity: list.quantity,
                uom: materialById[list.material]?.uom,
                sub_quanity: [list.quantity]
            }
            if (toolbarState.team !== '') {
                if (toolbarState.team === taskByID[list.task_id]?.team.value) {
                    var index = findArryIndex(listed, demo);
                    if (index === -1) {
                        listed.push(demo)
                    } else {
                        listed[index].quantity = parseInt(listed[index].quantity) + parseInt(list.quantity)
                        listed[index].sub_quanity.push(list.quantity)
                    }
                }
            } else {
                var index = findArryIndex(listed, demo);
                if (index === -1) {
                    listed.push(demo)
                } else {
                    listed[index].quantity = parseInt(listed[index].quantity) + parseInt(list.quantity)
                    listed[index].sub_quanity.push(list.quantity)
                }
            }
            return listed
        })
        setTaskMaterialDateFilterList(listed)
    }, [taskmaterialList, toolbarState.team])
    /* End Team filter */
    /* Apply Date filter */
    React.useEffect(() => {
        const list = []
        taskmaterialDateFilterList && taskmaterialDateFilterList.map((elm) => {
            if (toolbarState.to_date !== '' || toolbarState.from_date !== '') {
                var to_date = toolbarState.to_date
                var from_date = toolbarState.from_date
                if (to_date !== '' && from_date === '') {
                    if (new Date(to_date).setHours(0, 0, 0, 0) >= elm.date) {
                        list.push(elm)
                    }

                } else if (to_date !== '' && from_date !== '') {
                    if (new Date(to_date).setHours(0, 0, 0, 0) >= elm.date && new Date(from_date).setHours(0, 0, 0, 0) <= elm.date) {
                        list.push(elm)
                    }

                } else if (to_date === '' && from_date !== '') {
                    if (new Date(from_date).setHours(0, 0, 0, 0) <= elm.date) {
                        list.push(elm)
                    }
                }
            } else {
                list.push(elm)
            }
        })
        list.sort(compareNumbers)
        setTaskMaterialFilterList(list)
    }, [taskmaterialDateFilterList, toolbarState])
    /* End Date Filter */
    /* Apply Supplier and Material Filter */
    React.useEffect(() => {
        const list = []
        taskmaterialFilterList.map((elm) => {
            if (toolbarState.supplier !== '' || toolbarState.material !== '') {
                if (toolbarState.supplier === elm.supplier && toolbarState.material === '') {
                    list.push(elm)
                } else if (toolbarState.supplier === elm.supplier && toolbarState.material === elm.material) {
                    list.push(elm)
                } else if (toolbarState.supplier === '' && toolbarState.material === elm.material) {
                    list.push(elm)
                }
            } else {
                list.push(elm)
            }
        })
        setRequestByTeam(list)
    }, [taskmaterialFilterList, toolbarState.supplier, toolbarState.material])
    /* End Supplier and Material Filter */
    /* Prepare Graph Data */
    React.useEffect(() => {
        const list = [];
        requestByTeam && requestByTeam.map((elm) => {
            var demo = {
                startDate: elm.date,
                [elm.team]: elm.quantity,
                quantity: [{
                    team: elm.team,
                    material: elm.material,
                    supplier: elm.supplier,
                    quantity: elm.quantity,
                    sub_quanity: elm.sub_quanity,
                    uom: elm.uom,
                }]
            }
            if (list.length === 0) {
                list.push(demo)
            } else {
                let index = findArryDateIndex(list, elm.date)
                if (index === -1) {
                    list.push(demo)
                } else {
                    if (list[index][elm.team]) {
                        list[index][elm.team] = parseInt(list[index][elm.team]) + parseInt(elm.quantity)
                        list[index].quantity.push({
                            team: elm.team,
                            material: elm.material,
                            supplier: elm.supplier,
                            quantity: elm.quantity,
                            sub_quanity: elm.sub_quanity,
                            uom: elm.uom,
                        })
                    } else {
                        list[index][elm.team] = elm.quantity
                        list[index].quantity.push({
                            team: elm.team,
                            material: elm.material,
                            supplier: elm.supplier,
                            quantity: elm.quantity,
                            sub_quanity: elm.sub_quanity,
                            uom: elm.uom,
                        })
                    }
                }
            }
            return list
        })
        setGraphData(list);
    }, [requestByTeam])
    /* End Graph Date */
    /* Date Index  */
    const findArryDateIndex = (arr, date) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el.startDate === date) {
                return i
            }
        }
        return -1
    }
    /* End Date Index */
    /* Sorting List */
    const compareNumbers = (a, b) => {
        return a.date - b.date;
    }
    /* End sorting list */
    /* Apply Selet filter  */
    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    /* End filter select */
    /* Reset Filter */
    const onResetToolbarState = () => setToolbarState({
        team: '',
        supplier: '',
        material: '',
        from_date: '',
        to_date: ''
    })
    /* End reset filter */
    /* Custom Tooltip View */
    const CustomTooltip = ({ payload, label, active }) => {
        if (payload) {
            if (active) {
                return (
                    <div className="custom-tooltip m-1 p-1 rounded border border-2 border-primary">
                        <div className="tooltip-body">
                            <label className="label mb-1">{labelFormatter(label)}</label>
                            {payload && payload.map((data) => {
                                var quantity = data.payload.quantity
                                return (<>
                                    <div className="mb-2">
                                        <label className="label mb-0" style={{ color: data.fill }}>{teamByID[data.name]?.name}<span style={{ color: '#000' }}>{': ' + data.value}</span></label>
                                        {quantity && quantity.map((elm) => {
                                            if (elm.team === data.name) {
                                                var html = '';
                                                var sum_quantity = elm.sub_quanity
                                                html = sum_quantity.toString();
                                                html = html.replaceAll(',', '+')
                                                return (<>
                                                    <p className="mb-0">
                                                        {supplierById[elm.supplier]?.company_name + ', ' + materialById[elm.material]?.material_name + ', ' + attributeById[elm.uom]?.tag_name}
                                                        <span style={{ color: '#000' }}>{': ' + elm.quantity}
                                                            {' (' + html + ')'}</span>
                                                    </p>
                                                </>)
                                            }

                                        })}
                                    </div>
                                </>
                                )
                            })}
                        </div>
                    </div>
                );
            }
        }
    }
    /* End Tooltip view */

    return (
        <>
            <Row>
                <Col className='col-lg-12 mb-5'>
                    <Row>
                        <Col className='col-lg-9'>
                            <RequestByTeamToolbar
                                teams={teamList.map((i) => ({ value: i._id, label: i.name }))}
                                suppliers={suppliersList.map((i) => ({ value: i._id, label: i.company_name }))}
                                materials={material.map((i) => ({ value: i._id, label: i.material_name }))}
                                onChange={onToolbarStateChange}
                                state={toolbarState}
                                showTeam={true}
                            />
                        </Col>
                        <Col className='col-lg-3 mt-3'>
                            <div className='container-fluid'>
                                <Row className=" align-self-end justify-content-end">
                                    <Col className="col-lg-6 d-flex justify-content-end ">
                                        <Pdf targetRef={ref} filename={"Material-Request-By-Team-" + new Date().toDateString() + ".pdf"} options={options} x={.6} y={.6} scale={0.7}  >
                                            {({ toPdf }) => <Button className='btn btn-success' onClick={toPdf}>Generate Pdf</Button>}
                                        </Pdf>
                                    </Col>
                                    <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                        <Button
                                            color="danger"
                                            className="btn-primary btn waves-effect waves-light"
                                            onClick={onResetToolbarState}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <div ref={ref} >
                    <Col className='col-lg-12 text-center '>
                        <Card ref={wrapperRef}>
                            <Card.Body>
                                <div style={{ height: '400px' }}>
                                    <p className='fs-3 '>{(graphData.length == 0) ? 'Sorry, no record found.' : ''}</p>
                                    <ResponsiveContainer width="100%" height="100%" >
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={graphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" stroke='#f8f8f8' vertical={false} />
                                            <XAxis
                                                dataKey="startDate"
                                                domain={["dataMin", "dataMax"]}
                                                tickFormatter={dateFormatter}
                                                axisLine={true}
                                                tick={XAxisTick}
                                                height={70}
                                                fill="#5b626b"
                                                tickLine={false}
                                                padding={XAxisPading}
                                                interval="preserveStartEnd"
                                            />
                                            <YAxis
                                                tickCount={10}
                                                axisLine={false}
                                                tickLine={false}
                                                type="number"
                                            />
                                            <Tooltip
                                                content={<CustomTooltip />}
                                            />
                                            <Legend
                                                formatter={(value) => teamByID[value]?.name ? teamByID[value]?.name : "Trend Line"}
                                            />
                                            {teamList && teamList.map((t, key) => {
                                                if (toolbarState.team !== '') {
                                                    if (toolbarState.team === t._id) {
                                                        return (
                                                            <Bar
                                                                key={t._id}
                                                                dataKey={t._id}
                                                                fill={t.color}
                                                            />
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Bar
                                                            key={t._id}
                                                            dataKey={t._id}
                                                            fill={t.color}
                                                        />
                                                    )
                                                }

                                            })}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card.Body>
                            <Card.Footer className='text-center fs-4 fw-normal' style={{ backgroundColor: '#ffffff' }}>
                                <label>Material Forecast Report (Required on site)</label>
                            </Card.Footer>
                        </Card>
                    </Col>
                </div>

            </Row>
        </>
    )
}

export default RequestByTeam