import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import Alert from '../../../helpers/Alert'
import CostAttributeApi from './CostAttributeApi'

function CostAttribute() {
    const { costAttributes,
        values,
        error,
        handleChange,
        deleteAttribute,
        handleSubmitTag,
        handleEdit } = CostAttributeApi()

    const Cost_list = (lists) => {
        let list = null
        let i = 1
        if (lists.length > 0) {
            return (list = lists.costattribute.map((elm) => {
                if (elm.attribute_name === "Cost Type") {
                    if (elm.status === 1) {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <div>{elm.tag_name}</div>
                                    </td>
                                    <td>{elm.handle}</td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" data-bs-target="#CostTag" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                            <button onClick={() => deleteAttribute(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" ><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <strike><div>{elm.tag_name}</div></strike>
                                    </td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button onClick={() => deleteAttribute(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" ><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    }

                } else {
                    return list
                }
            }))
        } else {
            return list
        }


    }

    const Unit_list = (lists) => {
        let list = null
        let i = 1
        if (lists.length > 0) {
            return (list = lists.costattribute.map((elm) => {
                if (elm.attribute_name === "Unit of Measurement") {
                    if (elm.status === 1) {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <div>{elm.tag_name}</div>
                                    </td>
                                    <td>{elm.handle}</td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" data-bs-target="#unitPackage" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                            <button onClick={() => deleteAttribute(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" ><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <strike><div>{elm.tag_name}</div></strike>
                                    </td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td>
                                        <div className="d-flex addremove_btn">

                                            <button onClick={() => deleteAttribute(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" ><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    }

                }
                return list
            }))
        }
        return list

    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Cost Attributes | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='CostTracker' show='CostAttributes'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Cost Attributes</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Cost Tracker</li>
                                            <li className="breadcrumb-item active">Cost Attributes</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Unit of Measurement</h4>
                                                        <button type="button" data-bs-target="#unitPackage" className="btn btn-primary" data-bs-toggle="modal" data-bs-whatever="@mdo" >Add<i className="fas fa-plus"></i></button>
                                                        <div id="unitPackage" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Unit of Measurement Attributes</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Unit of Measurement Attributes</label>
                                                                                        <input type="text" name='unit_tag' onChange={handleChange} value={values.unit_tag} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                        <input type="text" className="form-control" name='unit_handle' value={values.unit_handle} onChange={handleChange} readOnly />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.work_tag !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Unit of Measurement')}> {(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">UoM</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Unit_list(costAttributes)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Cost Type</h4>
                                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#CostTag" data-bs-whatever="@mdo" >Add<i className="fas fa-plus"></i></button>
                                                        <div id="CostTag" className={"modal fade"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Cost Type Attributes</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Cost Type Attributes </label>
                                                                                        <input type="text" name='tag_name' onChange={handleChange} value={values.tag_name} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                        <input type="text" className="form-control" name='handle' value={values.handle} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.loction_tag !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Cost Type')}> {(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Cost Type</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Cost_list(costAttributes)}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default CostAttribute