import React from 'react'
import { Link } from 'react-router-dom'
import Auth from './Auth'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { Helmet } from "react-helmet";
import config from '../../config'
import Apis from '../../api'


function ForgetPassword() {
    const { values, error, color, open, handleChange, handleForgetPassword, handleClose } = Auth()
    const [loginImage, setLoginImage] = React.useState({
        id: '',
        BackgroundImage: './assets/images/login-background.jpg'
    })
    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const loginImageSetting = data.find(setting => setting.key === 'login_image');
                const loginImageTextShow = data.find(setting => setting.key === 'show_login_image_text');
                if (loginImageSetting) {
                    setLoginImage({
                        ...loginImageSetting,
                        id: loginImageSetting._id,
                        BackgroundImage: config.api_url + '/api/login-image/view/' + loginImageSetting._id,
                        show_text: loginImageTextShow ? loginImageTextShow.value : true
                    })
                }
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, []);
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    return (
        <>
            <Helmet>
                <title>Forget Password | P2IC</title>
            </Helmet>
            <Snackbar
                open={open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <div class="main_root">


                <div
                    className="accountbg"
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${loginImage.BackgroundImage})`,
                    }}
                >
                </div>
                <div className='login_background ' style={loginImage.show_text ? { display: 'block' } : { display: 'none' }}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_1'><span className="login_background_span" >Discipline</span> leads to habits.</div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_2'><span className="login_background_span" >Habits</span> lead to consistency.</div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_3'><span className="login_background_span" >Consistency</span> lead to results.</div>
                        </div>
                    </div>
                </div>

                <div className="account_loginpage forget-pass">
                    <div className="login-logo mt-4">
                        <Link to="/"><img src="./assets/images/logo-light.png" alt='' /></Link>
                    </div>
                    <div className="text-center mt-5">
                        <h4>Reset Password</h4>

                    </div>
                    <div className="email_instruction mt-3 mb-1">Enter your Email and instructions will be sent to you!</div>
                    <form onSubmit={handleForgetPassword} className="mt-4">
                        <div className="form-group">
                            <label htmlFor="username">Email</label>
                            <input type="text" className="form-control" name="email" value={values.email} onChange={handleChange} id="username" placeholder="Enter email" />
                        </div>

                        <div className="form-group row align-items-center justify-content-end">

                            <div className="col-sm-6 submit_login">
                                <button className="btn btn-primary">Reset</button>
                            </div>
                        </div>
                        <div className="form-group mt-5 mb-0 row text-center">
                            <div className="col-12 mt-3">
                                <span>Remember It ?</span><Link to="/"> Sign In here</Link>

                            </div>
                        </div>

                    </form>

                    <div className="builtby_text text-center pt-3">
                        <p className="text-start">&copy; {(new Date().getFullYear())} P2IC. Built and Engineered by by Blue Ocean HPA</p>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ForgetPassword
