import React, { forwardRef } from 'react'
import styles from '../../Analytics/helpers/styles.module.scss'
import { Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { Label } from 'reactstrap'


const TOOLBAR_FIELD_NAMES = {
    TEAMS: 'team',
    RESET: 'reset',
    ALL_DATES: 'all_dates',
    FROM_DATE: 'from_date',
    TO_DATE: 'to_date',
}

const CustomDatePickerInput = forwardRef(({ value, ...props }, ref) => {
    const formattedValue = value
        ? new Date(value).getDate() + '/' + parseInt(new Date(value).getMonth() + 1) + '/' + new Date(value).getFullYear()
        : ''

    return <Input type="text" {...props} value={formattedValue} ref={ref} />
})



const AnalyticsToolbar = ({ state = {}, onChange }) => {


    const onFromDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: TOOLBAR_FIELD_NAMES.FROM_DATE,
            value: date,
        })
    }

    const onToDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: TOOLBAR_FIELD_NAMES.TO_DATE,
            value: date,
        })
    }

    return (
        <div className='container-fluid'>
            <div className="row justify-content-between">
                <div className="col">
                    <Label for="analytics-filter-panel-from-date" className={styles.ganttToolbarSelectLabel}>
                        From Date
                    </Label>
                    <DatePicker
                        id="analytics-filter-panel-from-date"
                        selected={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        onChange={onFromDateChange}
                        selectsStart
                        startDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        endDate={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        portalId="analytics-filter-panel-from-date-portal"
                        name={TOOLBAR_FIELD_NAMES.FROM_DATE}
                        customInput={<CustomDatePickerInput />}
                        showPopperArrow={false}


                    />
                </div>

                <div className="col">
                    <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                        To Date
                    </Label>
                    <DatePicker
                        id="analytics-filter-panel-to-date"
                        selected={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        onChange={onToDateChange}
                        selectsEnd
                        startDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        endDate={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        minDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        portalId="analytics-filter-panel-to-date-portal"
                        name={TOOLBAR_FIELD_NAMES.TO_DATE}
                        customInput={<CustomDatePickerInput />}
                        showPopperArrow={false} />
                </div>


            </div>
        </div>
    )
}

export default AnalyticsToolbar
