import React from 'react'
import Apis from '../../../../api'
import swal from 'sweetalert'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";

const headers = [
    { title: '#', prop: 'id' },
    { title: 'UOM', prop: 'tag_name', isFilterable: true, isSortable: true },
    { title: 'Handle', prop: 'handle', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' }
];

const Attribute = ({ refresh, handleEdit }) => {
    const [attributeList, setAttributeList] = React.useState([])
    const [attribute, setAttribute] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(true)
    React.useEffect(() => {
        Apis.getmaterialAttribute().then((res) => {
            setAttributeList(res.data.data.attribute)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh, refreshList])

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 0;
        attributeList && attributeList.map((elm) => {
            i++
            if (elm.status) {
                demo = {
                    id: i,
                    tag_name: elm.tag_name,
                    handle: elm.handle,
                    status: false,
                    action: <>
                        <div class="d-flex addremove_btn">
                            <a className="btn btn-primary btn-sm" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></a>
                            <a className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 0)}><i className="fas fa-trash-alt"></i></a>
                        </div></>
                }
            } else {
                demo = {
                    id: i,
                    tag_name: elm.tag_name,
                    handle: elm.handle,
                    status: 'line-through',
                    action: <> <div class="d-flex addremove_btn">
                        <a className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 1)}><i className="fas fa-trash-restore"></i></a>
                    </div></>
                }
            }
            list.push(demo)

        })
        setAttribute(list)
    }, [attributeList])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                Apis.deletematerialAttribute(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }
    return (
        <>
            <div className="table-responsive">
                <DatatableWrapper
                    body={attribute}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [15, 25, 50, 100, 200]
                        }
                    }}>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter placeholder="Type here to search" />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody rowProps={(row) => ({
                            style: { textDecorationLine: row.status }
                        })} />
                    </Table>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >

                        </Col>
                    </Row>
                </DatatableWrapper>
            </div>
        </>
    )
}

export default Attribute