import React, { useState, useEffect, useRef } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Apis from '../../../../../../api';

import keyBy from 'lodash/keyBy'

/* const transformCommitmentPlans = (plan) => ({
    ...plan,
    commit_date: new Date(plan.commit_date),
    closing_date: new Date(plan.closing_date),
}) */

const dateFormatter = (value) =>

    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',

    })

const XAxisPading = { left: 60, right: 60 }
const XAxisTick = { fontSize: '0.8em' }
const LegendWrapperStyle = { lineHeight: 4 }
/* const YAxisDomain = [0, 100] */
const labelFormatter = (value) => new Date(value).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',

})

const Disciplinegraph = ({ teamId, fromDate, toDate, teams, disciplineList, loading = false }) => {

    const [taskData, setTaskDatas] = useState([])
    const [disciplineData, setdisciplineData] = useState([])

    useEffect(() => {

        const filter = [{ type: 'Task' },
        { delete_status: false }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = {
                "team.value": teamId
            }
            filter.push(demo)
        }
        if (fromDate) {
            demo = { startDate: { $gte: new Date(fromDate).setHours(0, 0, 0, 0) } }
            filter.push(demo)
        } else {
            demo = { startDate: { $gte: new Date().setHours(0, 0, 0, 0) } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { startDate: { $lte: new Date(toDate).setHours(0, 0, 0, 0) } }
            filter.push(demo)
        } else {
            demo = { startDate: { $lte: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0) } }
            filter.push(demo)
        }

        const raw = {
            "filter": filter
        }
        Apis.taskfilter(raw).then((res) => {
            setTaskDatas(res.data.tasks)

        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [fromDate, teamId, toDate])


    const disciplineById = keyBy(disciplineList, 'id')


    const findArryIndex = (arr, date, id) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el.startDate === date) {
                return i
            }
        }
        return -1

    }

    useEffect(() => {
        let list = []
        if (taskData.length) {
            taskData.map((task) => {
                var startDate = new Date(task.startDate).setHours(0, 0, 0, 0)
                let demo = {
                    startDate: startDate,
                    team_id: task.team.value,
                    [task.discipline.value]: task.crewsize
                }
                if (list.length === 0) {
                    list.push(demo)
                } else {
                    let index = findArryIndex(list, startDate, task.team.value)
                    if (index === -1) {
                        list.push(demo)
                    } else {
                        if (list[index][task.discipline.value]) {
                            list[index][task.discipline.value] = parseInt(list[index][task.discipline.value]) + parseInt(task.crewsize)
                        } else {
                            list[index][task.discipline.value] = task.crewsize
                        }
                    }
                }
                return list
            })
        }
        setdisciplineData(list)
    }, [taskData])
    const wrapperRef = useRef()

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <div style={{ height: '600px' }}>
                        <ResponsiveContainer width="100%" height="100%" ref={wrapperRef}>
                            <BarChart
                                width={500}
                                height={300}
                                data={disciplineData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid stroke='#f8f8f8' vertical={false} />
                                <XAxis
                                    dataKey="startDate"
                                    domain={["dataMin", "dataMax"]}
                                    tickFormatter={dateFormatter}
                                    /* dy={15}
                                    dx={25} */
                                    axisLine={true}
                                    tick={XAxisTick}
                                    height={70}
                                    fill="#5b626b"
                                    tickLine={false}
                                    padding={XAxisPading}
                                    interval="preserveStartEnd"
                                />
                                <YAxis
                                    tickCount={10}
                                    axisLine={false}
                                    tickLine={false}
                                    type="number"
                                /* domain={YAxisDomain} */
                                />

                                <Tooltip
                                    labelFormatter={labelFormatter}
                                    formatter={(value, name) => [`${value.toFixed(0)}`, disciplineById[name]?.name]}
                                />
                                <Legend
                                    wrapperStyle={LegendWrapperStyle}
                                    formatter={(value) => disciplineById[value]?.name}
                                />
                                {disciplineList.map((t, key) => {
                                    return (
                                        <Bar key={t.id} fill={t.color} dataKey={t.id} stackId="a" />
                                    )
                                })}

                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-md-2 text-center">
                    <label> Team</label>
                    <ul className="">
                        {teamId && teamId === '$$all' ? <>
                            <li className='nav-item'>
                                <span class="nav-link text-success" >
                                    All
                                </span>
                            </li>

                        </> : ''}
                        {teams.map((t, key) => {
                            if (teamId && teamId !== '$$all') {
                                if (t._id === teamId) {
                                    return (
                                        <>
                                            <li className='nav-item'>
                                                <span class="nav-link text-success" >
                                                    {t.name}
                                                </span>
                                            </li>
                                        </>
                                    )
                                }                                
                            }
                            return false
                        })}

                    </ul>
                    <label>Date Range</label>
                    <ul className='nav justify-content-center'>
                        <li className='nav-item'>
                            {(fromDate) ? labelFormatter(new Date(fromDate).setHours(0, 0, 0, 0)) : labelFormatter(new Date().setHours(0, 0, 0, 0))}
                        </li>
                        <li className='nav-item'>
                            - {(toDate) ? labelFormatter(new Date(toDate).setHours(0, 0, 0, 0)) : labelFormatter(new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))}
                        </li>
                    </ul>


                </div>
            </div >


        </>
    )
}

export default Disciplinegraph