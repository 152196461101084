import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import keyBy from 'lodash/keyBy'
import {
    DatatableWrapper,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import Apis from '../../../../../api';

const comment_header = [
    { title: 'What’s the notes', prop: 'comment' },
    { title: 'Who Posted', prop: 'username' },
    { title: 'Date', prop: 'date' }
]
const CommentList = ({ order_id, comment_refresh, type, members, company }) => {
    const [comments, setComments] = React.useState([])
    const [comment_body, setCommentBody] = React.useState([])
    const memberById = keyBy(members, '_id')

    React.useEffect(() => {
        const commentData = {
            filter: [
                {
                    order_id: order_id
                },
                {
                    type: type
                }
            ]
        }

        Apis.getOrderComments(commentData).then((res) => {
            setComments(res.data.data.comments)
        }).catch((error) => {
            console.log(error)
        })
    }, [order_id, comment_refresh, type])
    React.useEffect(() => {
        let list = []
        let demo = null        
        comments.map((elm) => {
            var startDate = new Date(elm.timeStamp)
            var username = memberById[elm.created_by_id]?.first_name + ' ' + memberById[elm.created_by_id]?.last_name
            if (memberById[elm.created_by_id]?.role === 'supplier') {
                username += ' / ' + company
            }
            demo = {
                comment: elm.comment,
                username: username,
                date: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()
            }
            list.push(demo)
            return list
        })

        setCommentBody(list.reverse())

    }, [comments])
    return (
        <div>
            <DatatableWrapper
                body={comment_body}
                headers={comment_header}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <div className='table-responsive table-scrollspy'>
                    <Table>
                        <TableHeader />
                        <TableBody />
                    </Table>
                </div>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={12}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end">
                                <Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0">
                                <PaginationOptions /></Col>
                        </Row>
                    </Col>
                </Row>
            </DatatableWrapper>
        </div>
    );
};

export default CommentList;