import React, { createRef } from 'react'
import Header from '../../../Layout/Header'
import Sidebar from '../../../Layout/Sidebar'
import Footer from '../../../Layout/Footer'
import CollaboratorApi from '../../../ProjectCollaborator/CollaboratorApi'
import AnalyticsToolbar from '../../../Analytics/helpers/AnalyticsToolbar'
import { Row, Col, Button } from 'reactstrap'
import Card from 'react-bootstrap/Card';
import Pdf from "react-to-pdf";
import Disciplinegraph from './helper/Disciplinegraph'
import AttributeApiCall from '../../../ProjectTags/AttributeApiCall';

const ContainerStyle = { overflow: 'auto', display: 'flex', flexDirection: 'column' }
const GraphsRowStyle = { minHeight: 500 }
const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [6, 12]

};

function DisciplineReports() {
  const ref = createRef();
  const { teamList } = CollaboratorApi()
  const { attributes } = AttributeApiCall()
  const [teams, setTeams] = React.useState([])
  const [toolbarState, setToolbarState] = React.useState({ team: '$$all' })
  const [disciplineList, setDisciplineList] = React.useState([])
  let teamsLoading = false
  const onToolbarStateChange = ({ name, value }) => {
    setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
  }
  const onResetToolbarState = () => setToolbarState({})
  /**********Filters*********/
  React.useEffect(() => {
    if (teamList.length > 0) {
      setTeams(teamList.team)
    }
  }, [teamList])

  React.useEffect(() => {
    let demo
    let disciplineList = []
    if (attributes.length > 0) {
      attributes.projectattribute.map((elm) => {
        if (elm.attribute_name === 'Discipline' && elm.status !== 0) {
          demo = {
            id: elm._id,
            name: elm.tag_name,
            color: elm.color,
          }
          disciplineList.push(demo)
        }
        return disciplineList
      })
      setDisciplineList(disciplineList)
    }
  }, [attributes])
  return (
    <>
      <div id="layout-wrapper">
        <Header title='Resource Histogram Report | P2IC'></Header>
        <Sidebar title='' show='AnalyticsReports'></Sidebar>
        <div className="main-content">
          <div className="page-content dashboard_content">
            <div className="container-fluid" style={ContainerStyle}>
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <h6 className="page-title">Analytics & Reports</h6>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">Analytics & Reports</li>
                      <li className="breadcrumb-item active">Resource Histogram Report</li>
                    </ol>
                  </div>
                </div>
              </div>
              <Row className='pr_tagsrow mb-3' >
                <Col className='col-lg-12'>
                  <Row  >
                    <Col className="col-lg-9">
                      <AnalyticsToolbar
                        state={toolbarState}
                        teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                        onChange={onToolbarStateChange}
                        onReset={onResetToolbarState}
                      />
                    </Col>
                    <Col className="col-lg-3">
                      <div className='container-fluid'>
                        <Row className=" align-self-end justify-content-end">
                          <Col className="col-lg-6 d-flex justify-content-end ">
                            <Pdf targetRef={ref} filename={"Resource-Histogram-Report-" + new Date().toDateString() + ".pdf"} options={options} x={.6} y={.6} scale={0.7}  >
                              {({ toPdf }) => <Button className='btn btn-success' onClick={toPdf}>Generate Pdf</Button>}
                            </Pdf>
                          </Col>
                          <Col className="col-lg-6 " style={{ display: 'contents' }}>
                            <Button
                              color="danger"
                              className="btn-primary btn waves-effect waves-light"
                              onClick={onResetToolbarState}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div ref={ref}>
                <Row className='pr_tagsrow'>
                  <Col className='col-lg-12'>
                    <Row style={GraphsRowStyle}>
                      <Col className="col-lg-12">
                        <Card >

                          <Card.Body >
                            <Card.Title className='text-center fs-4 fw-normal' style={{ backgroundColor: '#ffffff', marginTop: '-50px' }}> <label>Resource Histogram Report</label></Card.Title>
                            <Disciplinegraph
                              fromDate={toolbarState.from_date}
                              toDate={toolbarState.to_date}
                              teamId={toolbarState.team}
                              teams={teams}
                              disciplineList={disciplineList}
                              loading={teamsLoading} />
                          </Card.Body>

                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="row">
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DisciplineReports