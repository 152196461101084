import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import { Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import keyBy from 'lodash/keyBy';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import Apis from '../../../../../api';
import IssueTable from './IssueTable';
import ActionTable from './ActionTable';
import IssueCalanderTable from './IssueCalanderTable';
import config from '../../../../../config';


const IssueGraph = ({ title, toDate, teamId, slug, teams, graphData = [], setGraphrefresh, graphrefresh }) => {
    const [files, setFiles] = React.useState([]);
    const [markIssce, setMarkIssue] = React.useState();
    const [apiResponse, setapiResonse] = React.useState([]);
    const [apiissueResponse, setapiIssueResonse] = React.useState([]);
    const [issueList, setIssueList] = React.useState([]);
    const [actionList, setActionList] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [showAction, setShowAction] = React.useState(false);
    const [showIssueAction, setShowIssueAction] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [formData, setFormData] = React.useState({
        title: '',
        team: '',
        reported_date: new Date(),
        assigned_to: '',
        report: '',
        assigned_to: '',
        issue_list: '',
        due_date: new Date()
    })
    const [actionIssueData, setActionIssuceData] = React.useState({
        id: '',
        title: '',
        created_by: '',
        due_date: '',
        timeStamp: '',
        status: '',
        report: ''
    })

    const [subAdminList, setSubAdmin] = React.useState([])
    const [memberList, setMember] = React.useState([])
    const [notesData, setNotesData] = React.useState([])
    const [notes, setNotes] = React.useState({
        notes: '',
        notes_id: '',
        note_name: 'Add'
    })

    const [notesRefresh, setNoteRefresh] = React.useState(false)

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    React.useEffect(() => {
        Apis.getSubAdmin().then((res) => {
            var response = res.data.data;
            setSubAdmin(response.subAdmin)
        }).catch((error) => {
            console.log(error.response)
        })
        Apis.getAllMember().then((res) => {
            setMember(res.data.data.member)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    const memberByID = keyBy(memberList, '_id')
    const teamByID = keyBy(teams, '_id')
    const IssueById = keyBy(apiResponse, '_id')

    React.useEffect(() => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        let filters = [
            { type: slug },
            { reported_date: { $gte: new Date(fromDate).getTime() } },
            { reported_date: { $lte: new Date(toDates).getTime() } }
        ]
        if (teamId) {

            filters.push({ "team_id": teamId.value })
        }
        const raw = {
            "filter": filters
        }
        Apis.getDashboardIssue(raw).then((res) => {
            setapiResonse(res.data.data.dashboard_data)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh, toDate, teamId, slug])



    React.useEffect(() => {
        var list = []
        apiResponse && apiResponse.map((elm) => {
            var startDate = elm.reported_date
            var demo = {
                id: elm._id,
                issue: markIssce && markIssce === elm._id ? <><Link onClick={() => handleClick(elm._id)} >
                    <mark>{elm.issue}</mark>
                </Link></> : <>
                    <Link onClick={() => handleClick(elm._id)} >
                        {elm.issue}
                    </Link>
                </>,

                issue_date: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear(),
                team: teamByID[elm.team_id]?.name,
                assigned_to: memberByID[elm.assigned_to]?.first_name + ' ' + memberByID[elm.assigned_to]?.last_name,
                reported_by: memberByID[elm.reported_by]?.first_name + ' ' + memberByID[elm.reported_by]?.last_name,
                report: elm.report,
                title: elm.title,
                status: elm.status ? 'Open' : 'Close'

            }
            return list.push(demo)
        })
        setIssueList(list)
    }, [apiResponse, memberList, teams, markIssce])

    React.useEffect(() => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        let filters = [
            { type: slug },
            { timeStamp: { $gte: new Date(fromDate).getTime() } },
            { timeStamp: { $lte: new Date(toDates).getTime() } },
            { issue: { $in: apiResponse.map((m) => m._id) } }
        ]

        const raw = {
            "filter": filters
        }
        Apis.getDashboardIssueAction(raw).then((res) => {
            setapiIssueResonse(res.data.data.issue_actions)
        }).catch((error) => {
            console.log(error)
        })
    }, [apiResponse, toDate, slug])

    React.useEffect(() => {
        var list = []
        apiissueResponse && apiissueResponse.map((elm, key) => {
            var due_date = elm.due_date
            var created_date = elm.timeStamp
            var demo = {
                id: parseInt(key) + 1,
                issue: IssueById[elm.issue]?.issue,
                due_date: new Date(due_date).getDate() + '/' + parseInt(new Date(due_date).getMonth() + 1) + '/' + new Date(due_date).getFullYear(),
                reported_by: memberByID[elm.created_by]?.first_name + ' ' + memberByID[elm.created_by]?.last_name,
                report: elm.report,
                created_date: new Date(created_date).getDate() + '/' + parseInt(new Date(created_date).getMonth() + 1) + '/' + new Date(created_date).getFullYear(),
                title: <>
                    <Link onClick={() => handleAction(elm)}  >
                        {elm.title}
                    </Link>
                </>,
                status: elm.status ? 'Close' : 'Open'
            }
            return list.push(demo)
        })
        setActionList(list)
    }, [apiissueResponse, memberList, teams])

    React.useEffect(() => {
        if (actionIssueData.id !== '') {
            var raw = [{}]
            Apis.getNotes({
                filter: [{ task_id: actionIssueData.id },
                { type: 'issue' }
                ]
            }).then((res) => {
                const data = res.data.data.notes
                let list = []
                let demo = null

                data && data.map((elm) => {
                    demo = {
                        id: elm._id,
                        note: elm.note,
                        created_by: memberByID[elm.created_by_id]?.first_name + ' ' + memberByID[elm.created_by_id]?.last_name,
                        date: new Date(elm.timeStamp).toDateString()
                    }
                    list.push(demo)
                    return list
                })
                setNotesData(list)
            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [notesRefresh, actionIssueData.id])


    const handleClick = (issue) => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        let filters = [
            { type: slug },
            { timeStamp: { $gte: new Date(fromDate).getTime() } },
            { timeStamp: { $lte: new Date(toDates).getTime() } },
            { issue: issue }
        ]
        setMarkIssue(issue)
        const raw = {
            "filter": filters
        }
        Apis.getDashboardIssueAction(raw).then((res) => {
            setapiIssueResonse(res.data.data.issue_actions)
        }).catch((error) => {
            console.log(error)
        })

    }

    const handleReset = (refresh) => {
        setRefresh(refresh)
        setMarkIssue()
    }


    const handleSave = (formData) => {

        if (formData.title === '') {
            setError({
                ...error,
                error: 'Title is required',
                color: 'error',
                open: true
            })
        } else if (formData.team === '') {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })
        } else if (formData.assigned_to === '') {
            setError({
                ...error,
                error: 'Assigned to is required',
                color: 'error',
                open: true
            })
        } else if (formData.report === '') {
            setError({
                ...error,
                error: 'Detailed Report is required',
                color: 'error',
                open: true
            })
        } else if (formData.reported_date === '') {
            setError({
                ...error,
                error: 'Issue Date is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                title: formData.title,
                report: formData.report,
                reported_by: user.id,
                assigned_to: formData.assigned_to.value,
                team_id: formData.team.value,
                reported_date: new Date(formData.reported_date).getTime(),
                type: slug
            }
            Apis.storeDashboardIssue(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setRefresh(!refresh)
                setGraphrefresh(!graphrefresh)
                setShow(false)
                setFormData({
                    title: '',
                    team: '',
                    reported_date: new Date(),
                    assigned_to: '',
                    report: '',
                    assigned_to: '',
                    issue_list: '',
                    due_date: new Date()
                })
            }).catch((error) => {
                console.log(error)
            })

        }

    }

    const handleIssueSave = (formData) => {

        if (formData.title === '') {
            setError({
                ...error,
                error: 'Title is required',
                color: 'error',
                open: true
            })
        } else if (formData.issue_list === '') {
            setError({
                ...error,
                error: 'Issue is required',
                color: 'error',
                open: true
            })
        } else if (formData.report === '') {
            setError({
                ...error,
                error: 'Detailed Report is required',
                color: 'error',
                open: true
            })
        } else if (formData.due_date === '') {
            setError({
                ...error,
                error: 'Due Date is required',
                color: 'error',
                open: true
            })
        } else if (files.length == 0) {
            setError({
                ...error,
                error: 'Attachments is required',
                color: 'error',
                open: true
            })
        } else if (files.length > 6) {
            setError({
                ...error,
                error: 'Please select less then 6 attachments',
                color: 'error',
                open: true
            })
        } else {

            const data = new FormData();
            data.append('title', formData.title);
            data.append('report', formData.report);
            data.append('created_by', user.id);
            data.append('issue', formData.issue_list.value);
            data.append('due_date', new Date(formData.due_date).getTime());
            data.append('type', slug);
            data.append('timeStamp', new Date().getTime());

            for (const file of files) {
                data.append('photos', file);
            }

            Apis.storeDashboardIssueAction(data).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setRefresh(!refresh)
                setGraphrefresh(!graphrefresh)
                setShowAction(!showAction)
                setFormData({
                    title: '',
                    team: '',
                    reported_date: new Date(),
                    assigned_to: '',
                    report: '',
                    assigned_to: '',
                    issue_list: '',
                    due_date: new Date()
                })
                setFiles([])
            }).catch((error) => {
                console.log(error)
            })


        }
    }

    const handleUpdate = (formData) => {
        const raw = {
            id: formData.id,
            status: formData.status,
        }
        Apis.updateDashboardIssueAction(raw).then((res) => {
            setError({
                ...error,
                error: res.data.message,
                color: 'success',
                open: true
            })
            setRefresh(!refresh)
            setShowIssueAction(!showIssueAction)

        }).catch((error) => {
            console.log(error)
        })

    }

    const handleAction = (action) => {

        setShowIssueAction(!showIssueAction)
        setActionIssuceData({
            id: action._id,
            title: action.title,
            report: action.report,
            created_by: memberByID[action.created_by]?.first_name + ' ' + memberByID[action.created_by]?.last_name,
            due_date: new Date(action.due_date).getDate() + '/' + parseInt(new Date(action.due_date).getMonth() + 1) + '/' + new Date(action.due_date).getFullYear(),
            timeStamp: new Date(action.timeStamp).getDate() + '/' + parseInt(new Date(action.timeStamp).getMonth() + 1) + '/' + new Date(action.timeStamp).getFullYear(),
            status: action.status,
            attachments: action.attachments,
            show_update: action.status ? false : true
        })
        setNotes({
            notes: '',
            notes_id: '',
            note_name: 'Add'
        })

    }

    const handleNotesEdit = (record) => {

        setNotes({
            notes: record.note,
            notes_id: record.id,
            note_name: 'Update'
        })
    }

    const handleNotes = (notes, issue) => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (notes.notes === '') {
            setError({
                ...error,
                error: 'Please Enter Notes',
                color: 'error',
                open: true
            })
        } else {
            if (notes.notes_id === '') {
                if (issue.id) {
                    const notesData = {
                        user_id: user.id,
                        date: new Date().getTime(),
                        note: notes.notes,
                        task_id: issue.id,
                        type: 'issue'
                    }

                    Apis.addNotes(notesData).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setNotes({
                            notes: '',
                            notes_id: '',
                            note_name: 'Add'
                        })
                        setNoteRefresh(!notesRefresh)
                        setTimeout(() => {
                            setError({
                                ...error,
                                open: false
                            })
                        }, 3000)
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }

            } else {
                const notesData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    notes_id: notes.notes_id,
                    note: notes.notes,
                }
                Apis.updateNotes(notesData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setNotes({
                        notes: '',
                        notes_id: '',
                        note_name: 'Add'
                    })
                    setNoteRefresh(!notesRefresh)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false,
                })}
            >
                <Alert
                    onClose={() => setError({
                        ...error,
                        open: false,
                    })}
                    severity={error.color}
                    sx={{ width: '20%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
            <div className="row">


                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body customecard">

                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder "> {title + ' Issue List'}
                                    <button className='btn btn-danger' style={{ right: '78px' }} onClick={() => handleReset(!refresh)}>Reset</button>
                                    <button className='btn btn-primary' onClick={() => setShow(!show)}>Add<i className="fas fa-plus"></i></button>

                                </h4>
                            </div>
                            <IssueTable
                                issueList={issueList}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body customecard">

                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder ">  Action List
                                    <button className='btn btn-primary' onClick={() => setShowAction(!showAction)}>Add Action<i className="fas fa-plus"></i></button>
                                </h4>
                            </div>
                            <ActionTable
                                actionList={actionList}
                            />


                        </div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <IssueCalanderTable
                        graphData={graphData}
                        title={title}
                        toDate={toDate}
                        issueList={apiResponse}
                        actionList={apiissueResponse}

                    />
                </div>


            </div>

            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <Label >
                                Title
                            </Label>
                            <input
                                type="text"
                                id="title"
                                name='title'
                                value={formData.title}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}
                                className="form-control"
                                placeholder='Enter Title'
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label >
                                Team
                            </Label>
                            <ReactSelect
                                placeholder="Select Team..."
                                options={teams && teams.map((i) => ({ value: i._id, label: i.name }))}
                                name='team'
                                value={formData.team}
                                onChange={(option, params) => setFormData({
                                    ...formData,
                                    [params.name]: option || '',
                                })}
                                isClearable
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Reported By
                            </Label>
                            <input
                                type="text"
                                id="reported_by"
                                name='reported_by'
                                className="form-control"
                                placeholder='Enter  Reported By'
                                value={user.name}
                                readOnly
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Assigned to
                            </Label>
                            <ReactSelect
                                placeholder="Select Assigned To"
                                options={subAdminList && subAdminList.map((i) => ({ value: i._id, label: i.first_name + ' ' + i.last_name }))}
                                name='assigned_to'
                                value={formData.assigned_to}
                                onChange={(option, params) => setFormData({
                                    ...formData,
                                    [params.name]: option || '',
                                })}
                                isClearable
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Issue Date
                            </Label>
                            <DatePicker
                                className="form-control"
                                name='reported_date'
                                maxDate={new Date()}
                                selected={formData.reported_date}
                                onChange={(date) => setFormData({
                                    ...formData,
                                    reported_date: date,
                                })}
                                dateFormat="dd/MMM/yyyy"

                            />
                        </div>
                        <div className="col-lg-12 mb-2">
                            <Label>
                                Detailed Report
                            </Label>
                            <textarea
                                className="form-control"
                                name="report"
                                placeholder="Leave Detailed Report"
                                value={formData.report}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}></textarea>
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSave(formData)}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)} >Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAction} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShowAction(!showAction)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Issue Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <Label >
                                Title
                            </Label>
                            <input
                                type="text"
                                id="title"
                                name='title'
                                value={formData.title}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}
                                className="form-control"
                                placeholder='Enter Title'
                            />
                        </div>

                        <div className="col-lg-6 mb-2">
                            <Label>
                                Issue List
                            </Label>
                            <ReactSelect
                                placeholder="Select Issue"
                                options={apiResponse && apiResponse.map((i) => ({ value: i._id, label: i.issue }))}
                                name='issue_list'
                                value={formData.issue_list}
                                onChange={(option, params) => setFormData({
                                    ...formData,
                                    [params.name]: option || '',
                                })}
                                isClearable
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Due Date
                            </Label>
                            <DatePicker
                                className="form-control"
                                name='due_date'
                                minDate={new Date()}
                                selected={formData.due_date}
                                onChange={(date) => setFormData({
                                    ...formData,
                                    due_date: date,
                                })}
                                dateFormat="dd/MMM/yyyy"

                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>Attachments</Label>
                            <input
                                type="file"
                                className="form-control"
                                name="files"
                                id="fileInput"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(event) => setFiles(event.target.files)}
                            />
                        </div>
                        <div className="col-lg-12 mb-2">
                            <Label>
                                Detailed Report
                            </Label>
                            <textarea
                                className="form-control"
                                name="report"
                                placeholder="Leave Detailed Report"
                                value={formData.report}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}></textarea>
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleIssueSave(formData)}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowAction(!showAction)} >Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showIssueAction} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShowIssueAction(!showIssueAction)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Issue Action Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row lign-items-center">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="action-tab" data-bs-toggle="tab" data-bs-target="#action" type="button" role="tab" aria-controls="action" aria-selected="false">Notes</button>
                                </li>
                            </ul>
                            <div className="tab-content mt-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="row">
                                        {actionIssueData.show_update ? <>
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        checked={actionIssueData.status}
                                                        onChange={() => setActionIssuceData({
                                                            ...actionIssueData,
                                                            status: !actionIssueData.status
                                                        })} />
                                                    <label className="form-check-label" for="flexSwitchCheckDefault">Close Action</label>
                                                </div>

                                            </div>
                                            <div className="col-2 m-auto">
                                                <button
                                                    className='btn btn-prbtn btn-primary waves-effect waves-light'
                                                    disabled={!actionIssueData.status}
                                                    onClick={() => handleUpdate(actionIssueData)}
                                                >Update</button>
                                            </div>
                                        </> : <></>}

                                        <div className="col-12 mt-3">
                                            <div className="table-responsive">
                                                <table className='table table-striped table-bordered'>
                                                    <tbody className='table-group-divider'>
                                                        <tr>
                                                            <th>Title</th>
                                                            <td>{actionIssueData.title}</td>
                                                            <th>Due Date</th>
                                                            <td>{actionIssueData.due_date}</td>
                                                        </tr>

                                                        <tr>
                                                            <th>Action Created By</th>
                                                            <td>{actionIssueData.created_by}</td>
                                                            <th>Action Created Date</th>
                                                            <td>{actionIssueData.timeStamp}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Detailed Report</th>
                                                            <td colSpan={3}>{actionIssueData.report}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Attachments</th>
                                                            <td colSpan={3}>
                                                                <div className="row">
                                                                    {actionIssueData.attachments && actionIssueData.attachments.map((image, index) => (
                                                                        <div className="col-lg-4">
                                                                            <img
                                                                                class="img-thumbnail rounded "
                                                                                key={index}
                                                                                src={`${config.api_url}/${image.filePath}`}
                                                                                alt={image.originalName}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="action" role="tabpanel" aria-labelledby="action-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <div className="mb-3">
                                                <label htmlFor="notes" className="col-form-label">Notes</label>
                                                <input type="text" id="notes" name='notes' className="form-control" placeholder='Enter Notes' value={notes.notes} onChange={(event) => {
                                                    setNotes({
                                                        ...notes,
                                                        [event.target.name]: event.target.value
                                                    })
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 text-center">
                                            <div className="mt-3">
                                                <button type="button" className="btn btn-primary waves-effect waves-light px-4" onClick={() => handleNotes(notes, actionIssueData)} >{notes.note_name}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Note</th>
                                                    <th scope="col">Who Posted</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {notesData && notesData.map((elm, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{elm.note}</td>
                                                                <td>{elm.created_by}</td>
                                                                <td>{elm.date}</td>
                                                                <td><button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit" onClick={() => handleNotesEdit(elm)} ><i className="fas fa-pencil-alt"></i></button></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>


        </>
    );
};

export default IssueGraph;