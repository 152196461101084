import React from 'react'
import Apis from '../../../api'
import swal from 'sweetalert'
import keyBy from 'lodash/keyBy'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
const headers = [
    { title: '#', prop: 'id' },
    { title: 'Company', prop: 'company_name', isFilterable: true, isSortable: true },
    { title: 'Address', prop: 'address', isFilterable: true, isSortable: true },
    { title: 'Phone', prop: 'phone', isFilterable: true, isSortable: true },
    { title: 'Direct Contact First Name', prop: 'first_name', isFilterable: true, isSortable: true },
    { title: 'Direct Contact Last Name', prop: 'last_name', isFilterable: true, isSortable: true },
    { title: 'Direct Contact Email', prop: 'email', isFilterable: true, isSortable: true },
    { title: 'Notes', prop: 'notes', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' }
];

const Suppliers = ({ refresh, handleEdit }) => {
    const [supplierList, setSupplierList] = React.useState([])
    const [memberList, setMemberList] = React.useState([])
    const [supplier, setSupplier] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(true)
    React.useEffect(() => {
        Apis.getSuppliers().then((res) => {
            setMemberList(res.data.data.member)
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh, refreshList])
    const memberById = keyBy(memberList, 'email')

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 1;
        supplierList && supplierList.map((elm) => {
            if (elm.status) {
                if (memberById[elm.email]?.is_registered === false) {
                    demo = {
                        id: i,
                        company_name: elm.company_name,
                        address: elm.address,
                        phone: elm.phone,
                        first_name: elm.first_name,
                        last_name: elm.last_name,
                        email: elm.email,
                        notes: elm.notes,
                        is_registered: '#ffa500',
                        status: false,
                        action: <>
                            <div className="d-flex addremove_btn">
                                <a className="btn btn-primary btn-sm" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></a>
                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 0)}><i className="fas fa-trash-alt"></i></a>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Resend" onClick={() => handleForgetPassword(elm.email)}>Resend</button>
                            </div></>

                    }
                } else {
                    demo = {
                        id: i,
                        company_name: elm.company_name,
                        address: elm.address,
                        phone: elm.phone,
                        first_name: elm.first_name,
                        last_name: elm.last_name,
                        email: elm.email,
                        notes: elm.notes,
                        is_registered: false,
                        status: false,
                        action: <>
                            <div className="d-flex addremove_btn">
                                <a className="btn btn-primary btn-sm" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></a>
                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 0)}><i className="fas fa-trash-alt"></i></a>

                            </div></>

                    }
                }
            } else {
                demo = {
                    id: i,
                    company_name: elm.company_name,
                    address: elm.address,
                    phone: elm.phone,
                    first_name: elm.first_name,
                    last_name: elm.last_name,
                    email: elm.email,
                    notes: elm.notes,
                    is_registered: false,
                    status: 'line-through',
                    action: <>
                        <div className="d-flex addremove_btn">
                            <a className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 1)}><i className="fas fa-trash-restore"></i></a>
                        </div>
                    </>

                }
            }
            list.push(demo)
            i++
        })

        setSupplier(list)

    }, [supplierList])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                console.log(raw)
                Apis.deleteSupplier(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }
    const handleForgetPassword = (email) => {
        const raw = {
            email: email
        }
        Apis.forgetUser(raw).then((res) => {
            swal({
                title: res.data.message,
                icon: "success"
            })
        }).catch((error) => {
            swal({
                title: error.response.data.error,
                icon: "error"
            })
        })
    }



    return (
        <>
            <div className="table-responsive">

                <DatatableWrapper
                    body={supplier}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [15, 25, 50, 100, 200]
                        }
                    }}>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter placeholder="Type here to search" />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody rowProps={(row) => ({
                            style: { background: row.is_registered, textDecorationLine: row.status }
                        })} />
                    </Table>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >

                        </Col>
                    </Row>
                </DatatableWrapper>

            </div>
        </>
    )
}

export default Suppliers