import React from 'react'
import swal from 'sweetalert'
import Apis from '../../../../api'
import keyBy from 'lodash/keyBy'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";

const headers = [
    { title: '#', prop: 'id' },
    { title: 'Company/Supplier', prop: 'company_name', isFilterable: true, isSortable: true },
    { title: 'Material Name', prop: 'material_name', isFilterable: true, isSortable: true },
    { title: 'Lead Time (Days)', prop: 'lead_time', isFilterable: true, isSortable: true },
    { title: 'UoM (Unit of Measure)', prop: 'tag_name', isFilterable: true, isSortable: true },
    { title: 'Description', prop: 'description', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' }
];

const MaterrialList = ({ refresh, handleEdit, suppliers, attribute }) => {
    const [materialList, setMaterialsList] = React.useState([])
    const [materials, setMaterials] = React.useState([])
    const [material, setMaterial] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(true)
    const supplierById = keyBy(suppliers, '_id')
    const attributeById = keyBy(attribute, '_id')
    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })

    }, [refresh, refreshList])
    React.useEffect(() => {
        let list = []
        const user = JSON.parse(localStorage.getItem('user-info'))
        materialList.map((elm) => {
            if (user.role === 'supplier') {
                var email = supplierById[elm.supplier]?.email
                if (email === user.email) {
                    list.push(elm)
                }
            } else {
                list.push(elm)
            }
            return list
        })
        setMaterials(list)
    }, [materialList, suppliers])

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 0;
        materials && materials.map((elm) => {
            i++;
            if (elm.status) {
                demo = {
                    id: i,
                    company_name: supplierById[elm.supplier]?.company_name,
                    material_name: elm.material_name,
                    lead_time: elm.lead_time,
                    tag_name: attributeById[elm.uom]?.tag_name,
                    description: elm.description,
                    status: false,
                    action: <><div class="d-flex addremove_btn">
                        <button className="btn btn-primary btn-sm mx-1" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                    </div></>
                }
            } else {
                demo = {
                    id: i,
                    company_name: supplierById[elm.supplier]?.company_name,
                    material_name: elm.material_name,
                    lead_time: elm.lead_time,
                    tag_name: attributeById[elm.uom]?.tag_name,
                    description: elm.description,
                    status: 'line-through',
                    action: <> <div class="d-flex addremove_btn">
                        <button className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 1)}><i className="fas fa-trash-restore"></i></button>
                    </div></>
                }
            }
            list.push(demo)

        })
        setMaterial(list)

    }, [materials])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                Apis.deleteMaterial(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }


    return (
        <>
            <div className="table-responsive">
                <DatatableWrapper
                    body={material}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [15, 25, 50, 100, 200]
                        }
                    }}>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter placeholder="Type here to search" />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody rowProps={(row) => ({
                            style: { textDecorationLine: row.status }
                        })} />
                    </Table>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >

                        </Col>
                    </Row>
                </DatatableWrapper>
            </div>
        </>
    )
}

export default MaterrialList