import React from 'react';
import { useParams } from 'react-router-dom';
import { Label } from 'reactstrap'
import { default as ReactSelect } from "react-select"
import DatePicker from "react-datepicker";
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Footer from '../../Layout/Footer';
import CollaboratorApi from '../../ProjectCollaborator/CollaboratorApi';
import DetailGraph from './helper/DetailGraph';

const ucfirst = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const DashboardDetail = () => {
    const { slug } = useParams();
    const { teamList } = CollaboratorApi()
    const [teams, setTeams] = React.useState([])
    const [filters, setFilters] = React.useState({ team: '', date: new Date() })
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    const onTeamChange = (option, params) => {
        setFilters({
            ...filters,
            [params.name]: option || '',
        });
    };
    return (
        <>
            <div id="layout-wrapper">
                <Header title={'Dashboard ' + ucfirst(slug) + ' | P2IC'}></Header>
                <Sidebar title='dashboard' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">{ucfirst(slug)}</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Dashboard</li>
                                            <li className="breadcrumb-item active">{ucfirst(slug)}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12 mb-4">
                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <Label >
                                                    Team
                                                </Label>
                                                <ReactSelect
                                                    placeholder="Filter Team..."
                                                    options={teams && teams.map((i) => ({ value: i._id, label: i.name }))}
                                                    name='team'
                                                    value={filters.team}
                                                    onChange={onTeamChange}
                                                    isClearable
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <Label >
                                                    Choose Year
                                                </Label>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={filters.date}
                                                    maxDate={new Date()}
                                                    onChange={(date) => setFilters({
                                                        ...filters,
                                                        date: date,
                                                    })}
                                                    dateFormat="MMMM/yyyy"
                                                    showMonthYearPicker
                                                />
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className='col-lg-12 text-end '>
                                                            <button className='btn btn-danger' onClick={() => setFilters({ team: '', date: new Date() })}>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <DetailGraph
                                                slug={slug}
                                                toDate={filters.date}
                                                teamId={filters.team}
                                                title={ucfirst(slug)}
                                                teams={teams}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
};

export default DashboardDetail;