import React from 'react'

function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            &copy; {(new Date().getFullYear())} P2IC- Built and Engineered by Blue Ocean HPA.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
