import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import { Link } from 'react-router-dom'

function Reports() {
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Reports | P2IC'></Header>
                <Sidebar title='' show='AnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="container-fluid">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h6 className="page-title">Analytics & Reports</h6>
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item active">Analytics & Reports</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white" >
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/01.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">PPC (Production Reliability/ Reasons/ Root Cause)</h5>
                                                    <h4 className="fw-medium font-size-16">View more detailed analytics <i
                                                        className="mdi text-success ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/analytics" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white" >
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/02.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">COMMITMENT PLAN</h5>
                                                    <h4 className="fw-medium font-size-16">Download and view reports <i
                                                        className="mdi text-danger ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/commitment-plan-reports" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white">
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/03.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">PROGRAM VARIANCE</h5>
                                                    <h4 className="fw-medium font-size-16">Analyse several variance <i
                                                        className="mdi text-success ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/reports/program_variance_reports" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white">
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/01.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">Resource Histogram Report</h5>
                                                    <h4 className="fw-medium font-size-16">View more detailed analytics <i
                                                        className="mdi text-success ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/reports/resource_histogram_reports" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white">
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/01.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">Material Forecast Report</h5>
                                                    <h4 className="fw-medium font-size-16">View more detailed analytics <i
                                                        className="mdi text-success ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/reports/material_forecast_reports" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="card mini-stat bg-primary text-white">
                                            <div className="card-body">
                                                <div className="mb-4" style={{ height: '65px' }}>
                                                    <div className="float-start mini-stat-img me-4">
                                                        <img src="./assets/images/01.png" alt="" />
                                                    </div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">Equipment Forecast Report</h5>
                                                    <h4 className="fw-medium font-size-16">View more detailed analytics <i
                                                        className="mdi text-success ms-2"></i></h4>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <Link to="/reports/equipment_forecast_reports" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Footer></Footer>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Reports