import React from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';
import { Row, Col, Button } from 'reactstrap'
import Card from 'react-bootstrap/Card';
import AnalyticsToolbar from './helpers/AnalyticsToolbar';
import RootCausesReportTable from './helpers/RootCausesReportTable';

function MemberLog() {
    const [toolbarState, setToolbarState] = React.useState({})
    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    const onResetToolbarState = () => setToolbarState({})
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Members Log | P2IC'></Header>
                <Sidebar title='' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Members Log</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Profile</li>
                                            <li className="breadcrumb-item active">Members Log</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <Row className="pr_tagsrow colaborate_page">
                                <Col className="col-lg-12">
                                    <Row>
                                        <Col className="col-lg-9 mb-3">
                                            <AnalyticsToolbar
                                                state={toolbarState}
                                                onChange={onToolbarStateChange}
                                            />
                                        </Col>
                                        <Col className="col-lg-3">
                                            <div className='container-fluid'>
                                                <Row className=" align-self-end justify-content-end">
                                                    <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                                        <Button
                                                            color="danger"
                                                            className="waves-effect waves-light"
                                                            onClick={onResetToolbarState}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card >
                                        <Card.Body >
                                            <RootCausesReportTable
                                                fromDate={toolbarState.from_date}
                                                toDate={toolbarState.to_date}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="row">
                                <Footer></Footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default MemberLog;