import React from 'react'
import Apis from '../../../api'
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'
import CostCodesApi from '../CostCodes/CostCodesApi'
import CostAttributeApi from '../CostAttribute/CostAttributeApi'
import CostComponentsApi from '../CostComponent/CostComponentsApi'
import Moment from 'moment';


const CostTrackMpApi = (callback) => {
    const user =JSON.parse(localStorage.getItem('user-info'))
    const { teamList } = CollaboratorApi()
    const { attributes } = AttributeApiCall()
    const { costCodes } = CostCodesApi()
    const { costAttributes } = CostAttributeApi()
    const { costComponent } = CostComponentsApi()
    const [costTrackMp, setTrack] = React.useState([])
    const [values, setValues] = React.useState({
        owner: user.id,
        team_id: '',
        location_id: [],
        discipline_id: '',
        cost_component_id: '',
        cost_value: '',
        startDate: new Date(),
        date: Moment(new Date()).format("DD MMM YYYY"),
        id: '',
        team: ''
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    React.useEffect(() => { getCostTrack() }, [])

    const getCostTrack = () => {
        Apis.getCostTrackMp().then((res) => {
            setTrack(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
    }


    /* Function Enter values */
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setValues({
            ...values,
            [name]: value,
        })
        setError({
            ...error,
            open: false
        })
    }
    const handleSelectChange = (selected) => {
        setValues({
            ...values,
            location_id: selected
        })
    }

    const handleDateChange = (date) => {

        setValues({
            ...values,
            startDate: date,
            date: Moment(date).format("DD MMM YYYY")
        })
    }

    /* Function For Submit  */

    const handleSubmit = (formdata, event) => {
        if (formdata.team_id === '') {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })
        } else if (formdata.location_id.length === 0) {
            setError({
                ...error,
                error: 'Location is required',
                color: 'error',
                open: true
            })
        } else if (formdata.discipline_id === '') {
            setError({
                ...error,
                error: 'Discipline is required',
                color: 'error',
                open: true
            })
        } else if (formdata.cost_component_id === '') {
            setError({
                ...error,
                error: 'Cost Code is required',
                color: 'error',
                open: true
            })
        } else if (formdata.cost_value === '') {
            setError({
                ...error,
                error: 'Cost Value is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                'team_id': formdata.team_id,
                'location_id': formdata.location_id,
                'discipline_id': formdata.discipline_id,
                'cost_component_id': formdata.cost_component_id,
                'owner': user.id,
                'value': formdata.cost_value,
                'date': formdata.date
            }
            Apis.addCostTrackMp(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setValues({
                    ...values,
                    team_id: '',
                    location_id: '',
                    discipline_id: '',
                    cost_component_id: '',
                    cost_value: '',
                    id: '',
                    team: '',
                    startDate: new Date(),
                    date: Moment(new Date()).format("DD MMM YYYY"),
                })
                getCostTrack()
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })
        }
    }

    const handleEdit = (data, elm) => {

        setValues({
            ...values,
            cost_value: data.value,
            id: data._id,
            date: data.dates,
            team_id: elm.team_id,
            location_id: elm.location_id,
            discipline_id: elm.discipline_id,
            cost_component_id: elm.cost_component_id,
        })
        setError({
            ...error,
            open: false
        })

    }

    const handleClose = () => {
        setValues({
            ...values,
            team_id: '',
            location_id: [],
            discipline_id: '',
            cost_component_id: '',
            cost_value: '',
            id: '',
            startDate: new Date(),
            date: Moment(new Date()).format("DD MMM YYYY"),
            team: ''
        })
    }

    const handleSaveEdit = (formdata) => {
        if (formdata.cost_value === '') {
            setError({
                ...error,
                error: 'Cost Value is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                'id': formdata.id,
                'owner': user.id,
                'value': formdata.cost_value
            }
            Apis.editCostTrackPrice(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setValues({
                    ...values,
                    team_id: '',
                    location_id: [],
                    discipline_id: '',
                    cost_component_id: '',
                    cost_value: '',
                    id: '',
                    startDate: new Date(),
                    date: Moment(new Date()).format("DD MMM YYYY"),
                    team: ''
                })
                getCostTrack()
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })

        }
    }

    return {
        teamList, attributes, costCodes, costAttributes, values, error, costTrackMp, costComponent,
        handleChange, handleSubmit, handleDateChange, handleEdit, handleSaveEdit, handleClose, handleSelectChange

    }
}

export default CostTrackMpApi