import React from 'react'
import Apis from '../../../api'
import swal from 'sweetalert'

const CostCodesApi = (callback) => {
    const [costCodes, setCodes] = React.useState([])
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [values, setValues] = React.useState({
        cost_code: '',
        description: '',
        total_budget: '',
        id: '',
        owner: user.id
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    /* Function Enter values */
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleEdit = (code, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        setValues({
            ...values,
            cost_code: code.cost_code,
            description: code.description,
            total_budget: code.total_budget,
            id: code._id
        })

    }

    /* Function For Get Cost Codes List */

    React.useEffect(() => {
        getCodes()
    }, [])

    const getCodes = () => {
        Apis.getCostCodes().then((res) => {
            setCodes(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
    }

    /* Function For Add And Update Cost Codes */
    const handleSubmitCodes = (formdatas, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        if (formdatas.cost_code === '') {
            setError({
                ...error,
                error: 'Cost Code is required',
                color: 'error',
                open: true
            })
        } else if (formdatas.total_budget === '') {
            setError({
                ...error,
                error: 'Cost Code Total Budget is required',
                color: 'error',
                open: true
            })
        } else if (formdatas.description === '') {
            setError({
                ...error,
                error: 'Cost Code description is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdatas.id !== '') {
                const raw = {
                    'id': formdatas.id,
                    'cost_code': formdatas.cost_code,
                    'description': formdatas.description,
                    'total_budget': formdatas.total_budget
                }
                Apis.updateCostCode(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        cost_code: '',
                        description: '',
                        total_budget: '',
                        id: ''
                    })
                    getCodes()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    'cost_code': formdatas.cost_code,
                    'description': formdatas.description,
                    'total_budget': formdatas.total_budget,
                    'owner': user.id
                }
                Apis.addCostCode(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        cost_code: '',
                        description: '',
                        total_budget: '',
                        id: ''
                    })
                    getCodes()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    /* Function For Delete Cost Attribute */

    const deleteCode = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Attribute Id is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        'status': status
                    }
                    Apis.deleteCostCode(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        getCodes()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.message,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        });
    }

    return {
        costCodes,
        values,
        error,
        handleChange,
        handleSubmitCodes,
        handleEdit,
        deleteCode
    }
}

export default CostCodesApi