import React, { useEffect } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Board from 'react-trello'
import { MovableCardWrapper } from 'react-trello/dist/styles/Base'
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import Alert from '../../../helpers/Alert'
import Apis from '../../../api'
import keyBy from 'lodash/keyBy'
import DatePicker from "react-datepicker"
import Moment from 'moment';

const ConstraintCard = ({
    onClick,
    board,
    email,
    color,
    constraint,
    team,
    comments,
    actions,
    actions_check
}) => {

    return (
        <MovableCardWrapper
            style={{
                backgroundColor: color,
            }}>
            <header
                style={{
                    borderBottom: '1px solid #eee',
                    paddingBottom: 6,
                    marginBottom: 10,
                    display: 'flex',
                    color: '#111',
                    flexDirection: 'row',
                    justifyContent: 'space-between',


                }}
            >
                <div style={{ fontSize: 14, fontWeight: 'bold' }}>{constraint}
                    <div style={{ fontWeight: 200, marginTop: '5px', display: 'none' }}>{board}</div>
                </div>

                <i className={'fas fa-edit'} onClick={onClick} />
            </header>
            <div style={{ padding: '5px 0px', color: '#111' }}>{email}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Board: {board}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Team: {team}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Total Action(s): {actions.length}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Total Completed Action(s): {actions_check.length}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Total Comment(s): {comments.length}</div>
        </MovableCardWrapper>
    )
}


function ConstraintsLog() {
    const [constraintData, setData] = React.useState({ boardData: { lanes: [{ id: 'loading', title: 'loading..', cards: [] }] } })
    const [state, setState] = React.useState({
        eventBus: undefined,
        selected_card: undefined,
        comment_request: false,
        actionlist_request: false,
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [add_show, setAddShow] = React.useState(false)
    const [add_board_show, setAddBorderShow] = React.useState(false)
    const [filter_popup, setFilterPopUp] = React.useState(false)
    const [applyFilter, setApplyFilter] = React.useState(false)
    const [editConstraintModal, seteditConstraintModal] = React.useState(false)
    const [teams, setTeams] = React.useState([])
    const [boards, setBoards] = React.useState([])
    const [members, setMembers] = React.useState([])
    const [associations, setAssociation] = React.useState([])
    const { teamList, associationList } = CollaboratorApi()
    const [all_constraints, setAllConstraints] = React.useState([])
    const [all_constraints_comments, setAllConstraintsComments] = React.useState([])
    const [all_constraints_actions, setAllConstraintsActions] = React.useState([])
    const [all_constraints_logs, setAllConstraintsLogs] = React.useState([])
    const [refresh, setRefresh] = React.useState(false)
    const [refreshBoard, setBoardRefresh] = React.useState(false)
    const [board_filter_list, setBoardFilterList] = React.useState([])
    const [formData, setFormData] = React.useState({
        intitiated_by: user.id,
        constraint: '',
        board_name: '',
        team: [],
        board: [],
        board_filter: [],
        team_filter: [],
        add_action_member: [],
        checklist: '',
        user_email: user.email,
        user_name: user.name,
        action_date: new Date(),
        add_action_text: ''
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const teamsById = keyBy(teams, '_id')
    const boardsById = keyBy(boards, '_id')
    const memberById = keyBy(members, '_id')
    useEffect(() => {
        setFormData({
            ...formData,
            constraint: '',
            team: [],
            board: [],
            add_comment_text: '',
            id: ''
        })
        setError({
            ...error,
            open: false
        })
    }, [add_show])
    useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    React.useEffect(() => {
        if (associationList.length > 0) {
            setAssociation(associationList.association)
        }
    }, [associationList])

    React.useEffect(() => {
        if (formData.team_filter.value) {
            var boardList = [];
            boards.map((action) => {
                if (action.team === formData.team_filter.value) {
                    boardList.push({ value: action._id, label: action.name })
                }
            })
            setBoardFilterList(boardList)
        } else {
            var demo = boards.map((i) => ({ value: i._id, label: i.name }))
            setBoardFilterList(demo)
        }
    }, [formData.team_filter, boards])
    React.useEffect(() => {
        Apis.getAllMember().then((res) => {
            setMembers(res.data.data.member)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    React.useEffect(() => {
        Apis.getBoards().then((res) => {
            setBoards(res.data.data.boards)
        }).catch((error) => {
            console.log(error)
        })
    }, [refreshBoard])
    React.useEffect(() => {
        if (!editConstraintModal) {
            setError({
                ...error,
                open: false
            })
        }

    }, [editConstraintModal])

    useEffect(() => {
        setError({
            ...error,
            open: false
        })
        var raw = {
            filter: {
                board_filter: formData.board_filter,
                team_filter: formData.team_filter,
            }
        }
        Apis.getConstraint(raw).then((res) => {
            setAllConstraints(res.data.data.constraint)
            setAllConstraintsComments(res.data.data.comments)
            setAllConstraintsActions(res.data.data.actions)
            setAllConstraintsLogs(res.data.data.constraintLog)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh])

    useEffect(() => {
        let lane1 = []
        let lane2 = []
        let lane3 = []
        let lane4 = []
        if (all_constraints.length !== 0 && boards.length !== 0 && teams.length !== 0 && associations.length !== 0 && members.length !== 0) {
            all_constraints.forEach((item) => {
                if (boardsById[item.board]) {
                    if (teamsById[boardsById[item.board]?.team]) {
                        let comments = all_constraints_comments.filter((comment) => {
                            if (comment.constraint_id === item._id) {
                                return comment
                            }

                        })
                        let actions = all_constraints_actions.filter((action) => {
                            if (action.constraint_id === item._id) {
                                return action
                            }
                        })
                        let actions_check = all_constraints_actions.filter((action) => {
                            if (action.constraint_id === item._id && action.status) {
                                return action
                            }
                        })
                        let constraints_logs = all_constraints_logs.filter((constraints_log) => {
                            if (constraints_log.constraint_id === item._id) {
                                return constraints_log
                            }
                        })
                        let member_list = []
                        associations.map((association) => {
                            if (association.team_id === boardsById[item.board]?.team) {
                                if (memberById[association.member_id].status === 1) {
                                    var demo = {
                                        value: memberById[association.member_id]?._id,
                                        label: memberById[association.member_id]?.first_name + ' ' + memberById[association.member_id]?.last_name
                                    }
                                    member_list.push(demo)
                                }
                            }
                        })
                        member_list.sort((a, b) => {
                            const nameA = a.label.toUpperCase();
                            const nameB = b.label.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0;
                        });

                        let data = {
                            id: item._id,
                            constraint: item.constraint,
                            email: item.user_email,
                            color: teamsById[boardsById[item.board]?.team]?.color,
                            board: boardsById[item.board]?.name,
                            team: teamsById[boardsById[item.board]?.team]?.name,
                            team_id: boardsById[item.board]?.team,
                            comments: comments,
                            actions: actions,
                            actions_check: actions_check,
                            status: item.status,
                            metadata: { id: item._id },
                            member_list: member_list,
                            constraints_logs: constraints_logs
                        }
                        switch (item.status) {
                            case 1:
                                lane1.push(data)
                                break
                            case 2:
                                lane2.push(data)
                                break
                            case 3:
                                lane3.push(data)
                                break
                            case 4:
                                lane4.push(data)
                                break
                            default:
                                lane1.push(data)
                                break
                        }
                    }
                }
            })
        }

        let boardData = {
            lanes: [
                {
                    id: 'constraint',
                    title: 'Constraint',
                    label: lane1.length.toString(),
                    currentPage: 1,
                    cards: lane1,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'work_in_progress',
                    title: 'Work In Progress',
                    label: lane2.length.toString(),
                    currentPage: 1,
                    cards: lane2,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'blocked',
                    title: 'Blocked',
                    label: lane3.length.toString(),
                    currentPage: 1,
                    cards: lane3,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'completed',
                    title: 'Completed',
                    label: lane4.length.toString(),
                    currentPage: 1,
                    cards: lane4,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
            ],
        }
        setData({ boardData })
    }, [all_constraints, all_constraints_comments, all_constraints_actions, all_constraints_logs, teams, boards, associations, members])



    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setFormData({
            ...formData,
            [name]: value,
        })
        setError({
            ...error,
            open: false
        })

    }
    const onTeamChange = (option, params) => {
        setFormData({
            ...formData,
            [params.name]: params.action === 'clear' ? [] : option,
        })
        setError({
            ...error,
            open: false
        })

    }

    const cardEditHandler = (laneId, cardId) => {
        let targetLane = constraintData.boardData.lanes.find((item) => item.id === laneId)
        if (targetLane === undefined) return
        let targetCard = targetLane.cards.find((item) => item.id === cardId)
        setState({
            ...state,
            selected_card: targetCard,
        })
        setFormData({
            ...formData,
            constraint: targetCard.constraint,
            id: targetCard.id
        })
        seteditConstraintModal(!editConstraintModal)



    }
    const checkboxUpdateHandler = (action, card_index) => {
        let raw = {
            id: action._id,
            intitiated_by: user.id,
            closing_date: new Date().getTime()
        }
        let actions = state.selected_card.actions
        actions[card_index].status = !actions[card_index].status
        actions[card_index].closing_date = new Date().getTime()
        let log = {
            constraint_id: state.selected_card.id,
            user_id: formData.intitiated_by,
            content: actions[card_index].content + ' change status',
            createdAt: new Date().getTime()
        }
        let constraints_logs = state.selected_card.constraints_logs
        constraints_logs.reverse();
        constraints_logs.push(log)
        constraints_logs.reverse();
        Apis.updateConstraintCheckedList(raw).then((res) => {
            let actions_check = actions.filter((action) => {
                if (action.status) {
                    return action
                }
            })
            const selected_card = state.selected_card
            selected_card.actions = actions
            selected_card.actions_check = actions_check
            selected_card.constraints_logs = constraints_logs
            setState({
                ...state,
                actionlist_request: false,
                selected_card,
            })
        }).catch((error) => {
            console.log(error)
        })
    }
    const getStatusValue = (value) => {
        switch (value) {
            case 'constraint':
                return 1
            case 'work_in_progress':
                return 2
            case 'blocked':
                return 3
            case 'completed':
                return 4
            default:
                return 1
        }
    }

    const onCardMoveAcrossLanes = async (fromLaneId, toLaneId, cardId, addedIndex) => {
        let targetID = getStatusValue(toLaneId)
        let raw = {
            id: cardId,
            intitiated_by: user.id,
            status: targetID,
            updatedAt: new Date().getTime()
        }
        Apis.updateConstraintsPosition(raw).then((res) => {
            setRefresh(!refresh)
        }).catch((error) => {
            console.log(error)
        })
    }

    const setEventBus = (eventBus) => {
        setState({
            ...state,
            eventBus
        })
    }

    const handleBoardSave = (data) => {

        if (data.board_name === "") {
            setError({
                ...error,
                error: 'Board name is required',
                color: 'error',
                open: true
            })
        } else if (data.team.length === 0) {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })

        } else {
            setError({
                ...error,
                open: false
            })

            let raw = {
                name: data.board_name,
                intitiated_by: data.intitiated_by,
                team: data.team.value,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }
            Apis.addBoards(raw).then((res) => {
                /* setAddBorderShow(!add_board_show) */
                setBoardRefresh(!refreshBoard)
                setFormData({
                    ...formData,
                    board_name: '',
                    team: [],
                    add_comment_text: '',
                    id: ''
                })

            }).catch((error) => {
                setError({
                    ...error,
                    error: 'Board must be unique',
                    color: 'error',
                    open: true
                })
            })
        }
    }

    const handleSave = (data) => {

        if (data.constraint === "") {
            setError({
                ...error,
                error: 'Constraint is required',
                color: 'error',
                open: true
            })
        } else if (data.board.length === 0) {
            setError({
                ...error,
                error: 'Board is required',
                color: 'error',
                open: true
            })

        } else {
            setError({
                ...error,
                open: false
            })

            let raw = {
                constraint: data.constraint,
                intitiated_by: data.intitiated_by,
                board: data.board.value,
                user_email: data.user_email,
                team: boardsById[data.board.value]?.team,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }
            Apis.addConstraint(raw).then((res) => {
                setAddShow(!add_show)
                setRefresh(!refresh)
                setFormData({
                    ...formData,
                    constraint: '',
                    board: [],
                    add_comment_text: '',
                    id: ''
                })

            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const handleUpdate = (data, card) => {
        if (data.constraint === "") {
            setError({
                ...error,
                error: 'Constraint is required',
                color: 'error',
                open: true
            })

        } else {
            setError({
                ...error,
                open: false
            })
            let raw = {
                constraint: data.constraint,
                intitiated_by: data.intitiated_by,
                id: data.id,
                team: card.team_id,
                updatedAt: new Date().getTime()
            }
            Apis.updateConstraint(raw).then((res) => {
                seteditConstraintModal(!editConstraintModal)
                setRefresh(!refresh)
                setFormData({
                    ...formData,
                    constraint: '',
                    id: '',
                    add_comment_text: ''
                })

            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const addCheckList = () => {
        if (state.selected_card === undefined) return
        if (state.actionlist_request) return
        let action = formData.add_action_text
        let action_member = formData.add_action_member
        let action_date = formData.action_date


        if (action.length && action_member.value.length && action_date) {
            setError({
                ...error,
                open: false
            })
            setState({
                ...state,
                actionlist_request: true,
            })
            let raw = {
                user_id: formData.intitiated_by,
                constraint_id: state.selected_card.id,
                content: formData.add_action_text,
                member: formData.add_action_member.value,
                action_date: new Date(formData.action_date).getTime(),
                user_name: formData.user_name,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }
            let actionList = state.selected_card.actions

            let log = {
                constraint_id: state.selected_card.id,
                user_id: formData.intitiated_by,
                content: 'added a new action ' + formData.add_action_text + '.',
                createdAt: raw.createdAt
            }
            let constraints_logs = state.selected_card.constraints_logs
            constraints_logs.reverse();
            constraints_logs.push(log)
            Apis.addConstraintActions(raw).then((res) => {
                actionList.push(res.data.newAction)
                setFormData({
                    ...formData,
                    add_action_text: '',
                    action_date: new Date(),
                    add_action_member: []
                })
                setRefresh(!refresh)
                const selected_card = state.selected_card
                selected_card.actions = actionList
                constraints_logs.reverse();
                selected_card.constraints_logs = constraints_logs
                setState({
                    ...state,
                    actionlist_request: false,
                    selected_card,
                })
            }).catch((error) => {
                console.log(error)
            })
        } else {
            if (action.length === 0) {
                setError({
                    ...error,
                    error: 'Action name is required',
                    color: 'error',
                    open: true
                })
            } else if (action_member.value.length === 0) {
                setError({
                    ...error,
                    error: 'Member is required',
                    color: 'error',
                    open: true
                })
            } else if (!action_date) {
                setError({
                    ...error,
                    error: 'Completion Date is required',
                    color: 'error',
                    open: true
                })
            }
        }
    }

    const addComment = () => {
        if (state.selected_card === undefined) return
        if (state.comment_request) return
        let comment = formData.add_comment_text
        if (comment.length) {
            setError({
                ...error,
                open: false
            })
            setState({
                ...state,
                comment_request: true,
            })
            let raw = {
                user_id: formData.intitiated_by,
                constraint_id: state.selected_card.id,
                content: formData.add_comment_text,
                user_name: formData.user_name,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }

            let commentsList = state.selected_card.comments
            commentsList.push(raw)
            let log = {
                constraint_id: state.selected_card.id,
                user_id: formData.intitiated_by,
                content: 'added a new comment. ' + formData.add_comment_text,
                createdAt: new Date().getTime()
            }
            let constraints_logs = state.selected_card.constraints_logs
            constraints_logs.reverse();
            constraints_logs.push(log)
            Apis.addConstraintComment(raw).then((res) => {
                setFormData({
                    ...formData,
                    add_comment_text: '',
                })
                const selected_card = state.selected_card
                selected_card.comments = commentsList
                constraints_logs.reverse();
                selected_card.constraints_logs = constraints_logs
                setState({
                    ...state,
                    comment_request: false,
                    selected_card,
                })
            }).catch((error) => {
                console.log(error)
            })

        } else {
            setError({
                ...error,
                error: 'Comment is required',
                color: 'error',
                open: true
            })
        }
    }

    const handleApplyFilter = (filters) => {
        if (filters.board_filter.length) {
            setApplyFilter(true)
        } else if (filters.team_filter.value) {
            setApplyFilter(true)
        } else {
            setApplyFilter(false)
        }
        setRefresh(!refresh)
        setFilterPopUp(!filter_popup)
        setError({
            ...error,
            open: false
        })

    }

    const handleClearFilter = () => {
        setFormData({
            ...formData,
            board_filter: [],
            team_filter: []
        })
        setRefresh(!refresh)
        setApplyFilter(false)
        setError({
            ...error,
            open: false
        })
    }

    return (
        <>
            <Header title='Constraints Log | P2IC'></Header>
            <Sidebar title='Product_control' show='constraintslog'></Sidebar>
            <Alert error={error}></Alert>
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h6 className="page-title">Constraints Log</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item ">Production Control</li>
                                            <li className="breadcrumb-item active">Constraints Log</li>
                                        </ol>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-end d-none d-md-block">
                                            <button className="btn btn-primary waves-effect waves-light btn btn-primary mx-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Filter" onClick={() => setFilterPopUp(!filter_popup)}><i className='mdi mdi-filter'></i></button>
                                            <button className={applyFilter ? "btn btn-danger waves-effect waves-light btn btn-primary mx-1" : "btn btn-danger waves-effect waves-light btn btn-primary mx-2 d-none"} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clear Filter " onClick={handleClearFilter}><i className='fa fa-times'></i></button>
                                            <button className="btn btn-primary waves-effect waves-light btn btn-primary mx-2" onClick={() => setAddBorderShow(!add_board_show)}>Add Board</button>
                                            <button className="btn btn-primary waves-effect waves-light btn btn-primary" onClick={() => setAddShow(!add_show)}>Add Constraint</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-projext-sec ps-2" >
                                <Board
                                    data={constraintData.boardData}
                                    draggable
                                    cardDragClass="draggingCard"
                                    laneDraggable={false}
                                    className="boardContainer"
                                    components={{ Card: ConstraintCard }}
                                    onCardClick={(laneId, metadata, cardId) => cardEditHandler(cardId, laneId)}
                                    onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                                    eventBusHandle={setEventBus}
                                    style={{ backgroundColor: '#404040', justifyContent: 'center' }}
                                />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={add_board_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setAddBorderShow(!add_board_show)} centered>
                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>Add Board</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="mb-3">
                                <label htmlFor="board_name" className="col-form-label">Board</label>
                                <input type="text" name='board_name' id="board_name" className="form-control" value={formData.board_name} onChange={handleChange} placeholder='Enter Board Name' />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="mb-3">
                                <label htmlFor="team" className="col-form-label">Team</label>
                                <ReactSelect
                                    options={teams.map((i) => ({ value: i._id, label: i.name }))}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    name="team"
                                    isClearable
                                    onChange={onTeamChange}
                                    value={formData.team}


                                />
                            </div>
                        </div>
                        <div className="col-lg-2 text-center">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary w-100" onClick={() => handleBoardSave(formData)}>Add</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive table-scrollspy">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Board Name</th>
                                    <th scope='col'>Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                {boards && boards.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{teamsById[item.team]?.name}</td>
                                            </tr>
                                        </React.Fragment>
                                    )

                                })}

                            </tbody>
                        </table>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal show={add_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setAddShow(!add_show)} centered>
                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>Add Constraint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="constraint" className="col-form-label">Constraint</label>
                                <input type="text" name='constraint' id="constraint" className="form-control" value={formData.constraint} onChange={handleChange} placeholder='Enter Constraint' />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="team" className="col-form-label">Board</label>
                                <ReactSelect
                                    options={boards.map((i) => ({ value: i._id, label: i.name }))}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    name="board"
                                    isClearable
                                    onChange={onTeamChange}
                                    value={formData.board}
                                />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSave(formData)}>Add</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setAddShow(!add_show)} >Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={editConstraintModal} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => seteditConstraintModal(!editConstraintModal)} centered>

                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>Edit Constraint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.selected_card !== undefined ? (<>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Constraint</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="action-tab" data-bs-toggle="tab" data-bs-target="#action" type="button" role="tab" aria-controls="action" aria-selected="false">Action</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="comments-tab" data-bs-toggle="tab" data-bs-target="#comments" type="button" role="tab" aria-controls="comments" aria-selected="false">Comments</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="constraints-logs-tab" data-bs-toggle="tab" data-bs-target="#constraints_logs" type="button" role="tab" aria-controls="constraints_logs" aria-selected="false">Logs</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="constraint" className="col-form-label">Board Name</label>
                                                <input type="text" name='constraint' id="constraint" className="form-control" value={state.selected_card.board} readOnly disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="constraint" className="col-form-label">Team Name</label>
                                                <input type="text" name='constraint' id="constraint" className="form-control" value={state.selected_card.team} readOnly disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="constraint" className="col-form-label">Originator</label>
                                                <input type="text" name='constraint' id="constraint" className="form-control" value={state.selected_card.email} readOnly disabled />
                                            </div>
                                        </div>


                                        <div className="col-lg-10">
                                            <div className="mb-3">
                                                <label htmlFor="constraint" className="col-form-label">Constraint</label>
                                                <input type="text" name='constraint' id="constraint" className="form-control" value={formData.constraint} onChange={handleChange} placeholder='Enter Constraint' />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-end">
                                            <div className="mt-3">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => handleUpdate(formData, state.selected_card)} >Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="action" role="tabpanel" aria-labelledby="action-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="add_action_text" className="col-form-label">Add Action</label>
                                                <input type="text" name='add_action_text' id="add_action_text" className="form-control" value={formData.add_action_text} onChange={handleChange} placeholder='Enter Action' />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="add_action_text" className="col-form-label">Choose Member</label>

                                                <ReactSelect
                                                    options={state.selected_card.member_list ? state.selected_card.member_list : []}
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    name="add_action_member"
                                                    isClearable
                                                    onChange={onTeamChange}
                                                    value={formData.add_action_member}


                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="action_date" className="col-form-label">Required Completion Date</label>
                                                <DatePicker
                                                    selected={formData.action_date}
                                                    onChange={(date) => setFormData({
                                                        ...formData,
                                                        action_date: date
                                                    })}
                                                    name="action_date"
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="dd/mm/yyyy"
                                                    icon="mdi mdi-calendar"
                                                    id='action_date'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-center">
                                            <div className="mt-3">
                                                <button className="btn-block inner btn btn-primary w-100" onClick={addCheckList} >Add</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="col-form-label">Actions:</label>
                                            <div className="scrollspy">
                                                {state.selected_card.actions.map((item, index) => (
                                                    <div key={index} className="mb-2">
                                                        <div className="d-flex">
                                                            <div className="mx-2">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" onChange={() => checkboxUpdateHandler(item, index)} checked={item.status} />
                                                                </div>
                                                            </div>
                                                            <div className="mx-2">
                                                                <h4 className="font-size-15 m-0">{memberById[item.member]?.first_name + ' ' + memberById[item.member]?.last_name} </h4>
                                                                <span className="text-muted">{item.content} </span>
                                                                <span className="text-muted mx-2">({Moment(new Date(item.action_date)).format("DD/MM/YY")})</span>

                                                                <span className="text-muted">{item.status ? <>Completed on {Moment(new Date(item.closing_date)).format("DD/MM/YY")}</> : <></>}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-10">
                                            <div className="mb-3">
                                                <label htmlFor="add_comment_text" className="col-form-label">Add Comment:</label>
                                                <input type="text" name='add_comment_text' id="add_comment_text" className="form-control" value={formData.add_comment_text} onChange={handleChange} placeholder='Enter Comment' />
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="mt-3">
                                                <button className="btn-block inner btn btn-primary w-100" onClick={addComment} >Add</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="col-form-label">Comments:</label>
                                            <div className="scrollspy">
                                                {state.selected_card.comments.map((item, index) => (
                                                    <div key={index} className="mb-2">
                                                        <div className="d-flex">
                                                            <div className="mx-2">
                                                                <i className="mdi mdi-account-circle font-size-30 align-middle mr-1" />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h4 className="font-size-15 m-0">{item.user_name}</h4>
                                                                <small className="text-muted">{item.content}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="constraints_logs" role="tabpanel" aria-labelledby="constraints-logs-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">

                                            <div className="table-responsive table-scrollspy">
                                                <table className="table table-hover table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Board Name</th>
                                                            <th scope="col">Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.selected_card.constraints_logs && state.selected_card.constraints_logs.map((item, key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <tr>
                                                                        <td>{key + 1}</td>
                                                                        <td>{memberById[item.user_id]?.first_name + ' ' + memberById[item.user_id]?.last_name + ' ' + item.content}</td>
                                                                        <td>{Moment(new Date(item.createdAt)).format("DD/MM/YY")}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )

                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : (<><div className="mt-4 text-center">Loading</div></>)}

                </Modal.Body >

            </Modal >
            <Modal show={filter_popup} aria-labelledby="contained-modal-title-vcenter" onHide={() => setFilterPopUp(!filter_popup)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Filter </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className="col-sm-6">
                                    <div className='mb-2'>
                                        <label htmlFor="team" className="col-form-label">Team</label>
                                        <ReactSelect
                                            options={teams.map((i) => ({ value: i._id, label: i.name }))}
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            name="team_filter"
                                            isClearable
                                            onChange={onTeamChange}
                                            value={formData.team_filter}


                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='mb-2'>
                                        <label className="col-form-label">Board</label>
                                        <ReactSelect
                                            options={board_filter_list}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            name="board_filter"
                                            onChange={onTeamChange}
                                            value={formData.board_filter}
                                            allowSelectAll={true}

                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleApplyFilter(formData)} >Apply Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setFilterPopUp(!filter_popup)} >Cancel</button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConstraintsLog