import React from 'react'
import Apis from '../../../../api'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker"
import { default as ReactSelect } from "react-select"
import { components } from "react-select"
import Alert from '../../../../helpers/Alert'
import GanttComponets from './GanttComponets'
import { Label } from 'reactstrap'
import styles from '../../Analytics/helpers/styles.module.scss'
import { Button } from 'reactstrap'
import io from 'socket.io-client';
import keyBy from 'lodash/keyBy'
import swal from 'sweetalert'

const config = require('../../../../config');

const socket = io(config.api_url + '/');


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}



const findInArray = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el._id === id) {
            return i
        }
    }
    return -1
}

const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = startDate;
    while (curDate < endDate) {
        const dayOfWeek = new Date(curDate).getDay();
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
        if (isWeekend) {
            count++;
        }
        curDate = curDate + 24 * 60 * 60 * 1000
    }

    return count;
}

const GanttChart = ({ team, member, subproject, phase_plane, work_packages, location_id, status_code, attributes, members, associations, teams, sub_project, resources, resourcesShow, calanderData }) => {

    const user = JSON.parse(localStorage.getItem('user-info'))
    const [taskLog, setTaskLog] = React.useState([])
    const [reasonCodes, setCode] = React.useState([])
    const [logs, setLog] = React.useState([])
    const [taskData, setTaskData] = React.useState([])
    const [resourcesData, setResourcesData] = React.useState([])
    const [teams_lists, setTeamsLists] = React.useState([])
    const [member_List, seMemberLists] = React.useState([])
    const [location_List, setLocationLists] = React.useState([])
    const [disciplineList, setDisciplineList] = React.useState([])
    const [add_show, setAddShow] = React.useState(false);
    const [notes_show, setAddNotes] = React.useState(false);
    const [log_show, setLogShow] = React.useState(false);
    const [completed_not_planned, setCompletedNotPlanned] = React.useState(false);
    const [material_show, setMaterialShow] = React.useState(false);
    const [equipment_show, setEquipmentShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [resrefresh, setResRefresh] = React.useState(false);
    const [project_list, setProjectList] = React.useState([])
    const [planList, setPlanList] = React.useState([])
    const [workList, setWorkList] = React.useState([])
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const [notes, setNotes] = React.useState({
        notes: '',
        notes_id: '',
        note_name: 'Add'
    })

    const [materials, setMaterial] = React.useState({
        material: '',
        material_id: '',
        material_name: 'Add',
        uom: '',
        quantity: '',
        supplier: '',
        task_notes: '',
        disabled: true,
    })

    const [equipment, setEquipment] = React.useState({
        equipment: '',
        equipment_id: '',
        equipment_name: 'Add',
        quantity: '',
        supplier: '',
        task_notes: '',
        disabled: true
    })



    const [notesData, setNotesData] = React.useState([])

    const [notesRefresh, setNoteRefresh] = React.useState(false)

    const [state, setState] = React.useState({
        from_date: '',
        to_date: '',
        completed_date: new Date()
    })
    const [formData, setFormData] = React.useState({
        user_id: user.id,
        work_packages: [],
        sub_package: '',
        plan_package: '',
        location: [],
        team_package: [],
        startDate: '',
        endDate: '',
        orginalendDate: '',
        description: '',
        duration: 1,
        status_code: [{
            value: 1,
            label: 'Released'
        }],
        member: [],
        wbs_code: '',
        id: '',
        event_status: '',
        discipline: '',
        crew_size: 1,
        work_done: 0,
        split_task: false,
        handle_split: 0,
        type: '',
        parentindex: 0
    })
    const [equipmentList, setEquipmentList] = React.useState([])
    const [materialList, setMaterialsList] = React.useState([])
    const [materialoptionList, setMaterialOptionList] = React.useState([])
    const [supplieroptionList, setSupplierOptionList] = React.useState([])
    const [eqipmentoptionList, setEqipmentOptionList] = React.useState([]);
    const [materialAttributeList, setMaterialAttributeList] = React.useState([])
    const [suppliersList, setSupplierList] = React.useState([])
    const materialById = keyBy(materialList, '_id')
    const supplierById = keyBy(suppliersList, '_id')
    const equipmentByID = keyBy(equipmentList, '_id')
    const uomattributeById = keyBy(materialAttributeList, '_id')
    const [materialRefresh, setMaterialRefresh] = React.useState(false)
    const [taskmaterialList, setTaskMaterialList] = React.useState([])
    const [taskEquipmentList, setTaskEquipmentList] = React.useState([])
    const [umoattributeoptionList, setUoMAttributeOptionList] = React.useState([])
    const [equipmentRefresh, setEquipmentRefresh] = React.useState(false)


    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getMaterialEquipment().then((res) => {
            setEquipmentList(res.data.data.equipment)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getmaterialAttribute().then((res) => {
            setMaterialAttributeList(res.data.data.attribute)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getSuppliers().then((res) => {
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    React.useEffect(() => {

        if (materialAttributeList.length !== 0) {
            let aList = []
            materialAttributeList.map((elm) => {
                if (elm.status) {
                    var demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    aList.push(demo)
                }
                return aList
            })
            setUoMAttributeOptionList(aList)
        }
        if (suppliersList.length !== 0) {
            let sList = []
            suppliersList && suppliersList.map((elm) => {
                if (elm.status) {
                    var demo = {
                        value: elm._id,
                        label: elm.company_name
                    }
                    sList.push(demo)
                }
                return sList
            })
            setSupplierOptionList(sList)
        }

    }, [equipmentList, materialAttributeList, suppliersList])

    React.useEffect(() => {
        let mlist = []
        var supplier = materials.supplier
        if (supplier) {
            materialList && materialList.map((list) => {
                if (list.status && supplier.value === list.supplier) {
                    var demo = {
                        value: list._id,
                        label: list.material_name
                    }
                    mlist.push(demo)
                }
                return mlist
            })
        }
        setMaterialOptionList(mlist)
    }, [materialList, materials.supplier])
    React.useEffect(() => {
        let elist = []
        var supplier = equipment.supplier
        if (supplier) {
            equipmentList && equipmentList.map((list) => {
                if (list.status && supplier.value === list.supplier) {
                    var demo = {
                        value: list._id,
                        label: list.equipment
                    }
                    elist.push(demo)
                }
                return elist
            })
        }
        setEqipmentOptionList(elist)
    }, [equipmentList, equipment.supplier])

    React.useEffect(() => {
        if (formData.id !== '') {
            Apis.getTaskMaterial({
                task_id: formData.id
            }).then((res) => {
                const data = res.data.data.taskmaterials
                let list = []
                let demo = null

                data && data.map((elm) => {
                    const findIndex = findInArray(members, elm.created_by_id)
                    demo = {
                        id: elm._id,
                        material: elm.material,
                        status: elm.status,
                        quantity: elm.quantity,
                        created_by: findIndex === -1 ? 'Admin' : <>{members[findIndex].first_name + ' ' + members[findIndex].last_name}</>,
                        date: new Date(elm.timeStamp).getDate() + '/' + parseInt(new Date(elm.timeStamp).getMonth() + 1) + '/' + new Date(elm.timeStamp).getFullYear(),
                        order_status: elm.order_status,
                        task_notes: elm.task_notes
                    }
                    list.push(demo)
                    return list
                })
                setTaskMaterialList(list)
            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [materialRefresh, members, formData.id])

    React.useEffect(() => {
        if (formData.id !== '') {
            Apis.getNotes({
                filter: [{ task_id: formData.id },
                { type: 'task' }
                ]
            }).then((res) => {
                const data = res.data.data.notes
                let list = []
                let demo = null

                data && data.map((elm) => {
                    const findIndex = findInArray(members, elm.created_by_id)
                    demo = {
                        id: elm._id,
                        note: elm.note,
                        created_by: findIndex === -1 ? 'Admin' : <>{members[findIndex].first_name + ' ' + members[findIndex].last_name}</>,
                        date: new Date(elm.timeStamp).toDateString()
                    }
                    list.push(demo)
                    return list
                })
                setNotesData(list)
            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [notesRefresh, members, formData.id])


    React.useEffect(() => {
        let message = "hello";

        let filters = [
            { type: 'Task' },
            { delete_status: false }]
        let demo
        if (team && team !== '$$all') {
            demo = { "team.value": team.value }
            filters.push(demo)
        }
        if (subproject && subproject !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (location_id && location_id.length > 0) {
            var sample = []
            location_id.map((location) => {
                demo = { "location_id.value": location.value }
                sample.push(demo)
                return sample
            })
            filters.push({ $or: sample })
        }
        if (member) {
            demo = { "member.value": member.value }
            filters.push(demo)
        }
        const raw = {
            filters: filters,
            status_code: status_code
        }
        console.log(raw)

        socket.emit("task-filters", { message, raw });

        setTimeout(() => {
            setError({
                ...error,
                open: false
            })
        }, 3000)

    }, [team, subproject, phase_plane, work_packages, status_code, location_id, member, refresh])

    React.useEffect(() => {
        let message = "hello";
        let filters = [
            { type: 'Task' },
            { delete_status: false }]
        let demo
        if (team && team !== '$$all') {
            demo = { team: team }
            filters.push(demo)
        }
        if (subproject && subproject !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (member) {
            demo = { "member.value": member.value }
            filters.push(demo)
        }
        if (location_id && location_id.length > 0) {
            var sample = []
            location_id.map((location) => {
                demo = { "location_id.value": location.value }
                sample.push(demo)
            })
            filters.push({ $or: sample })
        }
        const raw = {
            filters: filters,
            status_code: status_code
        }
        socket.emit("resources-filters", { message, raw });

        setTimeout(() => {
            setError({
                ...error,
                open: false
            })
        }, 3000)

    }, [team, subproject, phase_plane, work_packages, status_code, location_id, member, refresh, resrefresh])

    React.useEffect(() => {
        socket.on("task-filters-data", (data) => {
            setTaskData(data.data)
            console.log(data.data)
        });
        socket.on("resource-filters-data", (data) => {
            setResourcesData(data.data)
        });
    }, [])
    React.useEffect(() => {
        Apis.reasonCodes().then((res) => {
            var response = res.data.data;
            setCode(response.reason_codes)
        }).catch((error) => {
            console.log(error.response)
        })
    }, [])


    React.useEffect(() => {
        if (formData.id !== '') {
            const filter = [{ tAskID: formData.id }]
            let demo
            if (state.to_date !== '') {
                demo = { timeStamp: { $lte: new Date(state.to_date).getTime() } }
                filter.push(demo)
            }
            if (state.from_date !== '') {
                demo = { timeStamp: { $gte: new Date(state.from_date).getTime() } }
                filter.push(demo)
            }
            const raw = {
                "filter": filter
            }

            Apis.getTaskLog(raw).then((res) => {
                setTaskLog(res.data.data.log)
                setLog([])

            }).catch((error) => {
                console.log(error.response.data)
            })
        }

    }, [formData.id, state])
    React.useEffect(() => {
        if (taskLog.length !== 0) {
            let list = []
            let demo = null
            var oldname = ''
            taskLog.map((log, index) => {
                const oldData = log.oldData[0]

                if (log.logFrom == 'CAP') {
                    var name = oldData ? oldData.name : oldname
                    var newlog = log.newDataString
                    oldname = oldData ? oldData.name : oldname
                    var reason_code_id = oldData ? oldData.reason_code_id : ''
                    if (reason_code_id) {
                        var reason = reasonCodes.find(elm => elm._id == reason_code_id)
                        newlog = newlog + '(' + reason.reason + ')';
                    }
                    demo = {
                        date: new Date(log.timeStamp).toDateString(),
                        oldlog: 'Task: ' + name,
                        newlog: newlog

                    }
                    list.push(demo)
                    return list

                } else {
                    const newData = log.newData[0]
                    if (oldData.name !== newData.name || oldData.startDate !== newData.startDate || oldData.complition_date !== newData.complition_date || oldData.endDate !== newData.endDate || oldData.crewsize !== newData.crewsize || oldData.duration !== newData.duration || oldData.member.value !== newData.member.value || oldData.discipline.value !== newData.discipline.value) {
                        demo = {
                            date: new Date(log.timeStamp).toDateString(),
                            oldlog: 'Task: ' + oldData.name + ', StartDate: ' + new Date(oldData.startDate).toDateString() + ', Complition Date: ' + new Date(oldData.complition_date).toDateString() + ', Crew Size :' + oldData.crewsize + ',Duration: ' + oldData.duration + ',Member: ' + oldData.member.label + 'Discipline: ' + oldData.discipline.label,
                            newlog: 'Task: ' + newData.name + ', StartDate: ' + new Date(newData.startDate).toDateString() + ', Complition Date: ' + new Date(newData.complition_date).toDateString() + ', Crew Size :' + newData.crewsize + ',Duration: ' + newData.duration + ',Member: ' + newData.member.label + 'Discipline: ' + newData.discipline.label

                        }
                        list.push(demo)
                        return list
                    }
                    return list
                }
            })
            setLog(list)
        }

    }, [taskLog, reasonCodes])


    React.useEffect(() => {
        let demo
        let list = []
        if (teams.length > 0) {
            teams.map((elm) => {
                if (elm.status === 1) {
                    demo = {
                        value: elm._id,
                        label: elm.name
                    }
                    list.push(demo)
                    return list
                }
                return list
            })
            setTeamsLists(list)
        }
    }, [teams])

    React.useEffect(() => {
        let demo
        let list = []
        if (formData.team_package !== '') {
            if (associations.length > 0) {
                associations.map((elm) => {
                    if (elm.team_id === formData.team_package.value) {
                        if (members.length > 0) {
                            members.map((elms) => {
                                if (elms._id === elm.member_id && elm.status === 1) {
                                    demo = {
                                        value: elm.member_id,
                                        label: elms.first_name + ' ' + elms.last_name
                                    }
                                    list.push(demo)
                                }
                                return list
                            })
                        }
                    }
                    return list
                })
            }
        }

        list.sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        seMemberLists(list)

    }, [formData.team_package, members, associations])



    React.useEffect(() => {
        let demo
        let list = []
        let disciplineList = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === 'Location' && elm.status !== 0) {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                if (elm.attribute_name === 'Discipline' && elm.status !== 0) {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    disciplineList.push(demo)
                }
                return list
            })

            setLocationLists(list)
            setDisciplineList(disciplineList)
        }
    }, [attributes])

    React.useEffect(() => {
        let list = [];
        sub_project.map((i) => {
            if (!i.is_locked) {
                const demo = {
                    value: i._id,
                    label: i.name
                }
                list.push(demo)
                return list
            }
            return list
        })
        setProjectList(list)

    }, [sub_project])
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (formData.sub_package) {
            const raw = {
                "project_id": formData.sub_package.value,
                "user_id": user.id
            }
            Apis.getPlan(raw).then((res) => {
                setPlanList(res.data.data.plans)
                setWorkList([])

            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [formData.sub_package])

    React.useEffect(() => {
        if (formData.plan_package) {
            planList.map((elm) => {
                if (elm._id === formData.plan_package.value) {
                    elm.packages.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                    setWorkList(elm.packages)
                }
                return true
            })
        }
    }, [planList, formData.plan_package])


    const handleEditClick = ({ record }) => {
        setError({
            ...error,
            open: false
        })
        let event_status = 'Edit';
        if (record.type === "Task") {
            setAddShow(true)
            event_status = 'Edit Task'
        } else {
            setShow(true)
        }
        console.log(record)
        setFormData({
            ...formData,
            user_id: user.id,
            work_packages: work_packages && work_packages !== '$$all' ? work_packages : record.work_packages,
            sub_package: subproject && subproject !== '$$all' ? subproject : record.subproject,
            plan_package: phase_plane && phase_plane !== '$$all' ? phase_plane : record.phase_plane,
            location: record.location_id,
            startDate: new Date(record.startDate),
            endDate: new Date(record.endDate),
            orginalendDate: new Date(record.taskrefdate),
            status_code: record.status_code,
            team_package: record.team,
            member: record.member,
            wbs_code: '',
            id: record.id,
            duration: record.duration,
            description: record.name,
            event_status: event_status,
            discipline: record.discipline,
            crew_size: record.crewsize,
            work_done: record.percentDone,
            handle_split: record.duration,
            type: record.type,
            parentIndex: record.parentindex

        })
    }
    const handleAddTask = ({ record }) => {
        let day = new Date(new Date(record.endDate).getTime() - 24 * 60 * 60 * 1000).getDay()
        let setdate = 1
        if (day === 0) {
            setdate = parseInt(1) + 2
        } else if (day === 6) {
            setdate = parseInt(1) + 1
        }
        setFormData({
            duration: 1,
            user_id: user.id,
            work_packages: record.work_packages,
            sub_package: record.subproject,
            plan_package: record.phase_plane,
            location: record.location_id,
            startDate: new Date(new Date(record.endDate).getTime() - setdate * 24 * 60 * 60 * 1000),
            endDate: new Date(record.endDate),
            orginalendDate: new Date(record.taskrefdate),
            status_code: [{
                value: 1,
                label: 'Released'
            }],
            team_package: record.team,
            member: record.member,
            wbs_code: '',
            id: record.id,
            event_status: 'Add Task',
            type: record.type,
            parentindex: record.nextindex,
            discipline: '',
            crew_size: 1,
            description: '',
            split_task: false,
            handle_split: 0,
        })
        setAddShow(!add_show)
    }
    const handleText = (event) => {
        const name = event.target.name
        const value = event.target.value
        const checked = event.target.checked


        if (name === 'duration') {
            if (value.indexOf('.') !== -1) {
                setError({
                    ...error,
                    error: 'No decimal value allow in duration',
                    color: 'error',
                    open: true
                })
            } else {
                setError({
                    ...error,
                    error: 'No decimal value allow in duration',
                    color: 'error',
                    open: false
                })
                let startDate = new Date(new Date(formData.endDate).getTime() - value * 24 * 60 * 60 * 1000).getTime();
                let enddate = new Date(formData.endDate).getTime()
                let day = getBusinessDatesCount(startDate, enddate)
                if (day % 2 !== 0) {
                    day = parseInt(day) + parseInt(1)
                }
                let setdate = parseInt(value) + parseInt(day)
                startDate = new Date(new Date(formData.endDate).getTime() - setdate * 24 * 60 * 60 * 1000)
                const dayOfWeek = new Date(startDate).getDay();
                if (dayOfWeek === 6) {
                    setdate = parseInt(setdate) + 1
                }
                if (dayOfWeek === 0) {
                    setdate = parseInt(setdate) + 2
                }
                startDate = new Date(new Date(formData.endDate).getTime() - setdate * 24 * 60 * 60 * 1000)

                setFormData({
                    ...formData,
                    [name]: value,
                    startDate: new Date(new Date(formData.endDate).getTime() - setdate * 24 * 60 * 60 * 1000)
                })
            }

        } else if (name === 'split_task') {
            setFormData({
                ...formData,
                split_task: checked,
            })
        } else if (name === 'notes') {
            setNotes({
                ...notes,
                [name]: value
            })

        } else if (name === 'quantity') {
            setMaterial({
                ...materials,
                [name]: value
            })
        } else if (name === 'task_notes') {
            setMaterial({
                ...materials,
                [name]: value
            })
        } else if (name === 'equipmentquantity') {
            setEquipment({
                ...equipment,
                ['quantity']: value
            })
        } else {
            console.log(name)
            setFormData({
                ...formData,
                [name]: value,
            })
        }
        setError({
            ...error,
            open: false
        })
    }

    const handleMilestoneDateChange = (date) => {
        setError({
            ...error,
            open: false
        })
        setFormData({
            ...formData,
            endDate: date
        })
    }

    const handleChanges = (selected, tag) => {

        if (selected === null) {
            if (tag === 'sub_package') {
                setFormData({
                    ...formData,
                    sub_package: '',
                    plan_package: '',
                    work_packages: '',
                })
            } else if (tag === 'plan_package') {
                setFormData({
                    ...formData,
                    plan_package: '',
                    work_packages: '',
                })
            } else if (tag === 'work_package') {
                setFormData({
                    ...formData,
                    work_packages: '',
                })
            } else if (tag === 'team_code') {
                setFormData({
                    ...formData,
                    team_package: ''
                })
            } else if (tag === 'status_codes') {
                setFormData({
                    ...formData,
                    status_code: ''
                })
            } else if (tag === 'member_select') {
                setFormData({
                    ...formData,
                    member: ''
                })
            } else if (tag === 'discipline_list') {
                setFormData({
                    ...formData,
                    discipline: ''
                })
            }
        } else {
            if (tag === 'sub_package') {
                setFormData({
                    ...formData,
                    sub_package: selected,
                    plan_package: '',
                    work_packages: '',
                })

            } else if (tag === 'plan_package') {
                setFormData({
                    ...formData,
                    plan_package: selected,
                    work_packages: '',
                })
            } else if (tag === 'work_package') {
                setFormData({
                    ...formData,
                    work_packages: selected,
                })
            } else if (tag === 'team_code') {
                setFormData({
                    ...formData,
                    team_package: selected
                })
            } else if (tag === 'location_list') {
                setFormData({
                    ...formData,
                    location: selected
                })
            } else if (tag === 'status_codes') {
                setFormData({
                    ...formData,
                    status_code: selected
                })
            } else if (tag === 'member_select') {
                setFormData({
                    ...formData,
                    member: selected
                })
            } else if (tag === 'discipline_list') {
                setFormData({
                    ...formData,
                    discipline: selected
                })
            }

        }
    }

    /* Add Task And Milestone */
    const handleSave = (formdata) => {
        if (formdata.description === '') {
            setError({
                ...error,
                error: 'Description is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.endDate === '') {
            setError({
                ...error,
                error: 'Completion Date is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }

        } else if (formdata.sub_package === '') {
            setError({
                ...error,
                error: 'Sub project is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.plan_package === '') {
            setError({
                ...error,
                error: 'Phase Plan is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.work_packages.length === 0) {
            setError({
                ...error,
                error: 'Work package is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.location.length === 0) {
            setError({
                ...error,
                error: 'Location is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.team_package.length === 0) {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.status_code.length === 0) {
            setError({
                ...error,
                error: 'status_code is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else if (formdata.member.length === 0) {
            setError({
                ...error,
                error: 'Member is required',
                color: 'error',
                open: true
            })
            if (formdata.event_status !== 'Add Task') {
                setShow(true)
            }
        } else {
            let raw = {}
            if (formdata.id === '') {
                setShow(false)
                let startDate = new Date(new Date(formdata.startDate).setHours(0, 0, 0, 0)).getTime()
                let endDate = new Date(new Date(formdata.endDate).setHours(0, 0, 0, 0)).getTime()
                let complition_date = new Date(new Date(formdata.endDate).setHours(0, 0, 0, 0)).getTime()
                raw = {
                    type: 'Milestone',
                    name: formdata.description,
                    startdate: startDate,
                    enddate: endDate,
                    complition_date: complition_date,
                    subproject: formdata.sub_package,
                    phase_plane: formdata.plan_package,
                    work_packages: formdata.work_packages,
                    location_id: formdata.location,
                    status_code: formdata.status_code,
                    split_task: formdata.split_task,
                    status_code_group: {
                        value: 2,
                        label: 'Work in Process'
                    },
                    wbs_code: formdata.wbs_code,
                    team: formdata.team_package,
                    member: formdata.member,
                    duration: 0,
                    id: formdata.id,
                    discipline: [],
                    crew_size: 1
                }
                Apis.addTask(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setFormData({
                        user_id: user.id,
                        work_packages: [],
                        sub_package: '',
                        plan_package: '',
                        location: [],
                        team_package: [],
                        startDate: '',
                        endDate: '',
                        description: '',
                        status_code: [{
                            value: 1,
                            label: 'Released'
                        }],
                        member: [],
                        wbs_code: '',
                        id: '',
                        duration: 1,
                        discipline: '',
                        crew_size: 1,
                        split_task: false
                    })
                    setRefresh(!refresh)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                if (formdata.discipline === '') {
                    setError({
                        ...error,
                        error: 'Discipline is required',
                        color: 'error',
                        open: true
                    })
                } else if (formdata.duration.length === 0 || formdata.duration <= 0) {
                    setError({
                        ...error,
                        error: 'Duration is required and not equal to zero',
                        color: 'error',
                        open: true
                    })
                    if (formdata.event_status !== 'Add Task') {
                        setShow(true)
                    }
                } else {
                    setAddShow(!add_show)
                    let startDate = new Date(new Date(formdata.startDate).setHours(0, 0, 0, 0)).getTime()
                    let endDate = new Date(new Date(formdata.endDate).setHours(0, 0, 0, 0)).getTime()
                    let complition_date = 0

                    raw = {
                        type: 'Task',
                        name: formdata.description,
                        startdate: startDate,
                        enddate: endDate,
                        complition_date: complition_date,
                        subproject: formdata.sub_package,
                        phase_plane: formdata.plan_package,
                        work_packages: formdata.work_packages,
                        location_id: formdata.location,
                        status_code: formdata.status_code,
                        split_task: formdata.split_task,
                        status_code_group: {
                            value: 2,
                            label: 'Work in Process'
                        },
                        wbs_code: formdata.wbs_code,
                        team: formdata.team_package,
                        member: formdata.member,
                        duration: formdata.duration,
                        id: formdata.id,
                        discipline: formdata.discipline,
                        crew_size: formdata.crew_size,
                        parentindex: formdata.parentindex
                    }

                    Apis.addTask(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setFormData({
                            user_id: user.id,
                            work_packages: [],
                            sub_package: '',
                            plan_package: '',
                            location: [],
                            team_package: [],
                            startDate: '',
                            endDate: '',
                            description: '',
                            status_code: [{
                                value: 1,
                                label: 'Released'
                            }],
                            member: [],
                            wbs_code: '',
                            id: '',
                            duration: 1,
                            discipline: '',
                            crew_size: 1,
                            split_task: false
                        })
                        setRefresh(!refresh)
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        }
    }

    /* Update Task And Milestone */
    const handleUpdate = (formdata) => {

        if (formdata.description === '') {
            setError({
                ...error,
                error: 'Description is required',
                color: 'error',
                open: true
            })
            if (formdata.type === "Task") {
                setAddShow(true)
            } else {
                setShow(true)
            }
        } else if (formdata.work_done > 100) {
            setError({
                ...error,
                error: 'Work Done Value must less then and equal to 100',
                color: 'error',
                open: true
            })
        } else if (formdata.type === "Task" && (formdata.duration.length === 0 || formdata.duration <= 0)) {
            setError({
                ...error,
                error: 'Duration is required and greater than zero',
                color: 'error',
                open: true
            })
            setAddShow(true)
        } else {
            if (formdata.type === "Task") {
                setAddShow(false)
            } else {
                setShow(false)
            }

            let startDate = new Date(formdata.startDate).getTime()
            var tzDifference = new Date(formdata.startDate).getTimezoneOffset();
            let endDate = new Date(formdata.endDate).getTime()
            const raw = {
                type: formdata.type,
                name: formdata.description,
                startdate: startDate,
                enddate: endDate,
                subproject: formdata.sub_package,
                phase_plane: formdata.plan_package,
                work_packages: formdata.work_packages,
                location_id: formdata.location,
                status_code: formdata.status_code,
                split_task: formdata.split_task,
                status_code_group: {
                    value: 2,
                    label: 'Work in Process'
                },
                wbs_code: formdata.wbs_code,
                team: formdata.team_package,
                member: formdata.member,
                duration: formdata.type === "Task" ? (formdata.duration.length === 0 || formdata.duration <= 0) ? 1 : formdata.duration : 0,
                id: formdata.id,
                discipline: formdata.discipline,
                crew_size: formdata.crew_size,
                parentIndex: formdata.parentIndex,
                tzDifference: tzDifference,
                user_id: user.id,
                timeStamp: new Date().getTime()
            }
            Apis.updateTask(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setFormData({
                    user_id: user.id,
                    work_packages: [],
                    sub_package: '',
                    plan_package: '',
                    location: [],
                    team_package: [],
                    startDate: '',
                    endDate: '',
                    description: '',
                    status_code: [{
                        value: 1,
                        label: 'Released'
                    }],
                    member: [],
                    wbs_code: '',
                    id: '',
                    duration: 1,
                    work_done: 0,
                    split_task: false
                })
                setRefresh(!refresh)


            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })

        }
    }

    /* CloseMilestone */

    const closeMilestone = (id) => {
        Apis.closeMilestons(id).then((res) => {
            setError({
                ...error,
                error: res.data.message,
                color: 'success',
                open: true
            })
            setRefresh(!refresh)
            setShow(!show)
            handleClose()
        }).catch((error) => {
            setError({
                ...error,
                error: error.response.data.message,
                color: 'error',
                open: true
            })
        })

    }


    /* Complete Not Completed Task */

    const handleCompleteNotPlannedTask = (formdata, id) => {
        const raw = {
            id: id,
            closedate: new Date(new Date(formdata.completed_date).setHours(0, 0, 0, 0)).getTime()
        }
        Apis.completeNotPlannedTask(raw).then((res) => {
            setError({
                ...error,
                error: res.data.message,
                color: 'success',
                open: true
            })
            setRefresh(!refresh)

            setState({
                from_date: '',
                to_date: '',
                completed_date: new Date()
            })
            setCompletedNotPlanned(false);
        }).catch((error) => {
            setError({
                ...error,
                error: error.response.data.error,
                color: 'error',
                open: true
            })
        })

    }
    /* Delete Task */
    const handleDeleteTask = (formdata) => {
        const raw = {
            id: formdata.id,
            user_id: user.id
        }
        Apis.deleteTask(raw).then((res) => {
            setError({
                ...error,
                error: res.data.message,
                color: 'success',
                open: true
            })
            setRefresh(!refresh)
            setFormData({
                user_id: user.id,
                work_packages: [],
                sub_package: '',
                plan_package: '',
                location: [],
                team_package: [],
                startDate: '',
                endDate: '',
                description: '',
                status_code: [{
                    value: 1,
                    label: 'Released'
                }],
                member: [],
                wbs_code: '',
                id: '',
                duration: 1,
                discipline: '',
                crew_size: 1,
                split_task: false
            })
            setAddShow(false)
        }).catch((error) => {
            setError({
                ...error,
                error: error.response.data.error,
                color: 'error',
                open: true
            })
        })
    }

    /* Add Notes  */

    const handleNotes = (notes) => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (notes.notes === '') {
            setError({
                ...error,
                error: 'Please Enter Notes',
                color: 'error',
                open: true
            })
        } else {
            if (notes.notes_id === '') {
                const notesData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    note: notes.notes,
                    task_id: formData.id,
                    type: 'task'

                }

                Apis.addNotes(notesData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setNotes({
                        notes: '',
                        notes_id: '',
                        note_name: 'Add'
                    })
                    setNoteRefresh(!notesRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const notesData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    notes_id: notes.notes_id,
                    note: notes.notes,
                }
                Apis.updateNotes(notesData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setNotes({
                        notes: '',
                        notes_id: '',
                        note_name: 'Add'
                    })
                    setNoteRefresh(!notesRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    /* Notes Edit */
    const handleNotesEdit = (record) => {
        setError({
            ...error,
            open: false
        })
        setNotes({
            notes: record.note,
            notes_id: record.id,
            note_name: 'Update'
        })
    }

    /* Logs Filter */
    const onFromDateChange = (date) => {

        setState({
            ...state,
            from_date: date
        })
    }
    const onToDateChange = (date) => {

        setState({
            ...state,
            to_date: date
        })
    }
    const onResetToolbarState = () => {
        setState({
            from_date: '',
            to_date: '',
            completed_date: new Date()
        })
    }
    /* Material Changes */

    const handleMaterialChange = (option, params) => {
        if (params.name === 'supplier') {
            var disabled = option ? false : true
            setMaterial({
                ...materials,
                ['disabled']: disabled,
                ['material']: null,
                ['uom']: null,
                [params.name]: option
            })
        } else if (option) {
            setMaterial({
                ...materials,
                ['uom']: {
                    value: materialById[option.value]?.uom,
                    label: uomattributeById[materialById[option.value]?.uom]?.tag_name
                },
                [params.name]: option
            })
        } else {
            setMaterial({
                ...materials,
                ['uom']: option,
                [params.name]: option,
            })
        }
        setError({
            ...error,
            open: false
        })
    }

    const handleMaterialEdit = (records) => {
        setMaterial({
            supplier: {
                value: materialById[records.material]?.supplier,
                label: supplierById[materialById[records.material]?.supplier]?.company_name
            },
            material: {
                value: records.material,
                label: materialById[records.material]?.material_name
            },
            material_id: records.id,
            material_name: 'Update',
            quantity: records.quantity,
            task_notes: records.task_notes,
            uom: {
                value: materialById[records.material]?.uom,
                label: uomattributeById[materialById[records.material]?.uom]?.tag_name
            }
        })
    }

    const handleMaterialDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    material_id: id,
                    date: new Date().getTime()
                }
                Apis.deleteTaskMaterial(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setMaterialRefresh(!materialRefresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }

    const handleMaterial = (material) => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (material.supplier === '' || material.supplier === null) {
            setError({
                ...error,
                error: 'Please Select Supplier',
                color: 'error',
                open: true
            })
        } else if (material.material === '' || material.material === null) {
            setError({
                ...error,
                error: 'Please Select Material',
                color: 'error',
                open: true
            })
        } else if (material.quantity === '' || material.quantity <= 0) {
            setError({
                ...error,
                error: 'Please Enter Valid Material Quantity',
                color: 'error',
                open: true
            })
        } else {
            if (material.material_id === '') {
                const materialData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    material: material.material.value,
                    quantity: material.quantity,
                    task_id: formData.id,
                    task_notes: material.task_notes
                }

                Apis.addTaskMaterial(materialData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setMaterial({
                        material: '',
                        material_id: '',
                        material_name: 'Add',
                        uom: '',
                        quantity: '',
                        supplier: '',
                        task_notes: '',
                        disabled: true,
                    })
                    setMaterialRefresh(!materialRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const materialData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    material: material.material.value,
                    quantity: material.quantity,
                    material_id: material.material_id,
                    task_notes: material.task_notes
                }

                Apis.updateTaskMaterial(materialData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setMaterial({
                        material: '',
                        material_id: '',
                        material_name: 'Add',
                        uom: '',
                        quantity: '',
                        supplier: '',
                        task_notes: '',
                        disabled: true,
                    })
                    setMaterialRefresh(!materialRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    React.useEffect(() => {
        if (formData.id !== '') {
            Apis.getTaskEquipment({
                task_id: formData.id
            }).then((res) => {
                const data = res.data.data.taskequipments
                let list = []
                let demo = null

                data && data.map((elm) => {
                    const findIndex = findInArray(members, elm.created_by_id)
                    demo = {
                        id: elm._id,
                        equipment: elm.equipment,
                        status: elm.status,
                        created_by: findIndex === -1 ? 'Admin' : <>{members[findIndex].first_name + ' ' + members[findIndex].last_name}</>,
                        date: new Date(elm.timeStamp).getDate() + '/' + parseInt(new Date(elm.timeStamp).getMonth() + 1) + '/' + new Date(elm.timeStamp).getFullYear(),
                        order_status: elm.order_status,
                        quantity: elm.quantity,
                        task_notes: elm.task_notes
                    }
                    list.push(demo)
                    return list
                })
                setTaskEquipmentList(list)
            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [equipmentRefresh, members, formData.id])




    const handleEquipmentChange = (option, params) => {

        if (params.name === 'supplier') {
            var disabled = option ? false : true
            setEquipment({
                ...equipment,
                ['disabled']: disabled,
                ['equipment']: null,
                [params.name]: option
            })
        } else {
            setEquipment({
                ...equipment,
                [params.name]: option
            })
        }

        setError({
            ...error,
            open: false
        })
    }

    const handleEqipmentEdit = (record) => {
        setEquipment({
            supplier: {
                value: materialById[record.equipment]?.supplier,
                label: supplierById[equipmentByID[record.equipment]?.supplier]?.company_name
            },
            equipment: {
                value: record.equipment,
                label: equipmentByID[record.equipment]?.equipment
            },
            equipment_id: record.id,
            equipment_name: 'Update',
            quantity: record.quantity,
            task_notes: record.task_notes,
        })
    }


    const handleEqipmentDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    equipment_id: id,
                    date: new Date().getTime()
                }
                Apis.deleteTaskEquipment(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setEquipmentRefresh(!equipmentRefresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }

    const handleEquipmentSave = (equipment) => {

        const user = JSON.parse(localStorage.getItem('user-info'))
        if (equipment.supplier === '' || equipment.supplier === null) {
            setError({
                ...error,
                error: 'Please Select Supplier',
                color: 'error',
                open: true
            })
        } else if (equipment.equipment === '' || equipment.equipment === null) {
            setError({
                ...error,
                error: 'Please Select Equipment',
                color: 'error',
                open: true
            })
        } else {
            if (equipment.equipment_id === '') {
                console.log(equipment)
                const equipmentData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    equipment: equipment.equipment.value,
                    task_id: formData.id,
                    quantity: equipment.quantity,
                    task_notes: equipment.task_notes
                }


                Apis.addTaskEquipment(equipmentData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setEquipment({
                        equipment: '',
                        equipment_id: '',
                        equipment_name: 'Add',
                        quantity: '',
                        supplier: '',
                        task_notes: '',
                        disabled: true
                    })
                    setEquipmentRefresh(!equipmentRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const materialData = {
                    user_id: user.id,
                    date: new Date().getTime(),
                    equipment: equipment.equipment.value,
                    equipment_id: equipment.equipment_id,
                    quantity: equipment.quantity,
                    task_notes: equipment.task_notes
                }

                Apis.updateTaskEquipment(materialData).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setEquipment({
                        equipment: '',
                        equipment_id: '',
                        equipment_name: 'Add',
                        quantity: '',
                        supplier: '',
                        task_notes: '',
                        disabled: true
                    })
                    setEquipmentRefresh(!equipmentRefresh)
                    setTimeout(() => {
                        setError({
                            ...error,
                            open: false
                        })
                    }, 3000)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    /* Equipment handle */
    const notification = () => {
        return (<>
            <span className="position-absolute start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span className="visually-hidden"></span>
            </span>
        </>)
    }

    const handleClose = () => {
        setFormData({
            user_id: user.id,
            work_packages: [],
            sub_package: '',
            plan_package: '',
            location: [],
            team_package: [],
            startDate: '',
            endDate: '',
            orginalendDate: '',
            description: '',
            duration: 1,
            status_code: [{
                value: 1,
                label: 'Released'
            }],
            member: [],
            wbs_code: '',
            id: '',
            event_status: '',
            discipline: '',
            crew_size: 1,
            work_done: 0,
            split_task: false,
            handle_split: 0,
            type: '',
            parentindex: 0
        })
    }

    const handleReset = () => {
        setEquipment({
            equipment: '',
            equipment_id: '',
            equipment_name: 'Add',
            quantity: '',
            supplier: '',
            task_notes: '',
            disabled: true
        })
        setMaterial({
            material: '',
            material_id: '',
            material_name: 'Add',
            uom: '',
            quantity: '',
            supplier: '',
            task_notes: '',
            disabled: true,
        })
        setError({
            ...error,
            open: false
        })
    }





    return (
        <>
            <Alert error={error}></Alert>

            <GanttComponets
                status_code={status_code}
                taskData={taskData}
                attributes={attributes}
                resources={resources}
                resourcesShow={resourcesShow}
                resourcesData={resourcesData}
                handleEditClick={handleEditClick}
                handleAddTask={handleAddTask}
                handleRefresh={setResRefresh}
                resrefresh={resrefresh}
                calanderData={calanderData}
            />


            {/* Add Material */}
            <Modal show={material_show} size="xl" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setMaterialShow(!material_show); handleClose() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Material</Modal.Title>
                    <div style={{ position: 'absolute', right: '110px' }}>
                        <Link to="#" className="custom-icon-div" onClick={() => { { formData.event_status === 'Edit Task' ? setAddShow(!add_show) : setShow(!show) }; setMaterialShow(!material_show); }}>
                            <span className="custom-icon" style={{ padding: '9px 16px' }} >
                                {'I'}
                                <i className="tooltiptext">Information</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setMaterialShow(!material_show) }}>
                            <span className="custom-icon">
                                {'L'}{
                                    logs.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Log</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'C'}
                                <i className="tooltiptext">Cost</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Production Rates</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon" style={{ backgroundColor: '#333547' }}>
                                {'M'}{
                                    taskmaterialList.length !== 0 ? notification() : <></>
                                }

                                <i className="tooltiptext">Materials</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setEquipmentShow(!equipment_show) }}>
                            <span className="custom-icon">
                                {'E'}{
                                    taskEquipmentList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Equipment</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Permits</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setAddNotes(!notes_show) }}>
                            <span className="custom-icon" >
                                {'N'}{
                                    notesData.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Notes</i>
                            </span>
                        </Link>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="supplier" className="col-form-label">Company/Supplier</label>
                                <ReactSelect
                                    options={supplieroptionList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={materials.supplier}
                                    name="supplier"
                                    id="supplier"
                                    isClearable
                                    onChange={handleMaterialChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="material" className="col-form-label">Materials</label>
                                <ReactSelect
                                    options={materialoptionList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={materials.material}
                                    name="material"
                                    id="material"
                                    isClearable
                                    isDisabled={materials.disabled}
                                    onChange={handleMaterialChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="quantity" className="col-form-label">Quantity</label>
                                {materials.material === '' || materials.material === null ? <>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name='quantity'
                                        className="form-control"
                                        value={materials.quantity}
                                        onChange={handleText}
                                        placeholder='Enter Quantity'
                                        disabled />
                                </> : <>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name='quantity'
                                        className="form-control"
                                        value={materials.quantity}
                                        onChange={handleText}
                                        placeholder='Enter Quantity'
                                    /></>}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="uom" className="col-form-label">UoM (Unit of Measure)</label>
                                <ReactSelect
                                    options={umoattributeoptionList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={materials.uom}
                                    name="UoM"
                                    id="uom"
                                    isDisabled={true}
                                    isClearable

                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="notes" className='col-form-label'>Notes</label>
                                <input
                                    type="text"
                                    id="notes"
                                    name='task_notes'
                                    className="form-control"
                                    value={materials.task_notes}
                                    onChange={handleText}
                                    placeholder='Enter Notes'
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 text-center">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary waves-effect waves-light mx-1" onClick={() => handleMaterial(materials)}>{materials.material_name}</button>
                                <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => handleReset(equipment)}>Reset</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company/Supplier</th>
                                    <th scope="col">Material</th>
                                    <th scope='col'>Quantity</th>
                                    <th scope='col'>UoM (Unit of Measure)</th>
                                    <th scope="col">Who Posted</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {taskmaterialList && taskmaterialList.map((elm, key) => {
                                    if (elm.status) {
                                        var order_status = 'Ordered'
                                        if (elm.order_status !== 'received') {
                                            order_status = elm.order_status
                                        }
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td>{supplierById[materialById[elm.material]?.supplier]?.company_name}</td>
                                                    <td>{materialById[elm.material]?.material_name}</td>
                                                    <td>{elm.quantity}</td>
                                                    <td>{uomattributeById[materialById[elm.material]?.uom]?.tag_name}</td>
                                                    <td>{elm.created_by}</td>
                                                    <td>{elm.date}</td>
                                                    <td>
                                                        <div class="d-flex addremove_btn text-capitalize">
                                                            {elm.order_status !== 'pending' ? order_status : <>
                                                                <button className="btn btn-primary btn-sm mx-1" onClick={() => handleMaterialEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                                                                <button className="btn btn-primary btn-sm" onClick={() => handleMaterialDelete(elm.id, 0)}><i className="fas fa-trash-alt"></i></button>
                                                            </>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td><strike>{supplierById[materialById[elm.material]?.supplier]?.company_name}</strike></td>
                                                    <td><strike>{materialById[elm.material]?.material_name}</strike></td>
                                                    <td><strike>{elm.quantity}</strike></td>
                                                    <td><strike>{uomattributeById[materialById[elm.material]?.uom]?.tag_name}</strike></td>
                                                    <td><strike>{elm.created_by}</strike></td>
                                                    <td><strike>{elm.date}</strike></td>
                                                    <td>
                                                        <div class="d-flex addremove_btn text-capitalize">
                                                            {elm.order_status !== 'pending' ? order_status : <>
                                                                <button className="btn btn-primary btn-sm" onClick={() => handleMaterialDelete(elm.id, 1)}><i className="fas fa-trash-restore"></i></button>
                                                            </>}

                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }

                                })}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>

            </Modal >
            {/* Add Equipment */}
            < Modal show={equipment_show} size="xl" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setEquipmentShow(!equipment_show); handleClose() }} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Add Equipment</Modal.Title>
                    <div style={{ position: 'absolute', right: '110px' }}>
                        <Link to="#" className="custom-icon-div" onClick={() => { { formData.event_status === 'Edit Task' ? setAddShow(!add_show) : setShow(!show) }; setEquipmentShow(!equipment_show) }}>
                            <span className="custom-icon" style={{ padding: '9px 16px' }} >
                                {'I'}
                                <i className="tooltiptext">Information</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setEquipmentShow(!equipment_show) }}>
                            <span className="custom-icon">
                                {'L'}{
                                    logs.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Log</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'C'}
                                <i className="tooltiptext">Cost</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Production Rates</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setEquipmentShow(!equipment_show) }}>
                            <span className="custom-icon">
                                {'M'}{
                                    taskmaterialList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Materials</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon" style={{ backgroundColor: '#333547' }}>
                                {'E'}{
                                    taskEquipmentList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext" >Equipment</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Permits</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setAddNotes(!notes_show); setEquipmentShow(!equipment_show) }}>
                            <span className="custom-icon" >
                                {'N'}
                                {
                                    notesData.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Notes</i>
                            </span>
                        </Link>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="supplier" className="col-form-label">Company/Supplier</label>
                                <ReactSelect
                                    options={supplieroptionList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={equipment.supplier}
                                    name="supplier"
                                    id="supplier"
                                    isClearable
                                    onChange={handleEquipmentChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="notes" className="col-form-label">Equipment</label>
                                <ReactSelect
                                    options={eqipmentoptionList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={equipment.equipment}
                                    name="equipment"
                                    isClearable
                                    onChange={handleEquipmentChange}
                                    isDisabled={equipment.disabled}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="quantity" className="col-form-label">Quantity</label>
                                {equipment.equipment === '' || equipment.equipment === null ? <>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name='equipmentquantity'
                                        className="form-control"
                                        value={equipment.quantity}
                                        onChange={(e) => setEquipment({
                                            ...equipment,
                                            ['quantity']: e.target.value
                                        })}
                                        placeholder='Enter Quantity'
                                        disabled />
                                </> : <>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name='equipmentquantity'
                                        className="form-control"
                                        value={equipment.quantity}
                                        onChange={(e) => setEquipment({
                                            ...equipment,
                                            ['quantity']: e.target.value
                                        })}
                                        placeholder='Enter Quantity'
                                    /></>}

                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="mb-3">
                                <label htmlFor="notes" className='col-form-label'>Notes</label>
                                <input
                                    type="text"
                                    id="notes"
                                    name='task_notes'
                                    className="form-control"
                                    value={equipment.task_notes}
                                    onChange={(e) => setEquipment({
                                        ...equipment,
                                        ['task_notes']: e.target.value
                                    })}
                                    placeholder='Enter Notes'
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 text-center">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary waves-effect waves-light mx-1" onClick={() => handleEquipmentSave(equipment)}>{equipment.equipment_name}</button>
                                <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => handleReset(equipment)}>Reset</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company/Supplier</th>
                                    <th scope="col">Equipment</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Who Posted</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {taskEquipmentList && taskEquipmentList.map((elm, key) => {
                                    if (elm.status) {
                                        var order_status = 'Ordered'
                                        if (elm.order_status !== 'received') {
                                            order_status = elm.order_status
                                        }
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td>{key + 1 + 1}</td>
                                                    <td>{supplierById[equipmentByID[elm.equipment]?.supplier]?.company_name}</td>
                                                    <td>{equipmentByID[elm.equipment]?.equipment}</td>
                                                    <td>{elm.quantity}</td>
                                                    <td>{elm.created_by}</td>
                                                    <td>{elm.date}</td>
                                                    <td>
                                                        <div class="d-flex addremove_btn text-capitalize">
                                                            {elm.order_status !== 'pending' ? order_status : <>
                                                                <button className="btn btn-primary btn-sm mx-1" onClick={() => handleEqipmentEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                                                                <button className="btn btn-primary btn-sm" onClick={() => handleEqipmentDelete(elm.id, 0)}><i className="fas fa-trash-alt"></i></button>
                                                            </>}

                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td><strike>{supplierById[equipmentByID[elm.equipment]?.supplier]?.company_name}</strike></td>
                                                    <td><strike>{equipmentByID[elm.equipment]?.equipment}</strike></td>
                                                    <td><strike>{elm.created_by}</strike></td>
                                                    <td><strike>{elm.date}</strike></td>
                                                    <td>
                                                        <div class="d-flex addremove_btn text-capitalize">
                                                            {elm.order_status !== 'pending' ? order_status : <>
                                                                <button className="btn btn-primary btn-sm" onClick={() => handleEqipmentDelete(elm.id, 1)}><i className="fas fa-trash-restore"></i></button>
                                                            </>}

                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                })}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>

            </Modal >

            {/* Add Notes */}
            < Modal show={notes_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setAddNotes(!notes_show); handleClose() }} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Add Notes</Modal.Title>
                    <div style={{ position: 'absolute', right: '110px' }}>
                        <Link to="#" className="custom-icon-div" onClick={() => { { formData.event_status === 'Edit Task' ? setAddShow(!add_show) : setShow(!show) }; setAddNotes(!notes_show); }}>
                            <span className="custom-icon" style={{ padding: '9px 16px' }} >
                                {'I'}
                                <i className="tooltiptext">Information</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setAddNotes(!notes_show) }}>
                            <span className="custom-icon">
                                {'L'}
                                {
                                    logs.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Log</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'C'}
                                <i className="tooltiptext">Cost</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Production Rates</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setAddNotes(!notes_show) }}>
                            <span className="custom-icon">
                                {'M'}{
                                    taskmaterialList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Materials</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setEquipmentShow(!equipment_show); setAddNotes(!notes_show) }}>
                            <span className="custom-icon">
                                {'E'}
                                {
                                    taskEquipmentList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Equipment</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Permits</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon" style={{ backgroundColor: '#333547' }}>
                                {'N'}{
                                    notesData.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Notes</i>
                            </span>
                        </Link>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="mb-3">
                                <label htmlFor="notes" className="col-form-label">Notes</label>
                                <input type="text" id="notes" name='notes' className="form-control" value={notes.notes} onChange={handleText} placeholder='Enter Notes' />
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary waves-effect waves-light px-4" onClick={() => handleNotes(notes)}>{notes.note_name}</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Who Posted</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notesData && notesData.map((elm, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>{elm.note}</td>
                                                <td>{elm.created_by}</td>
                                                <td>{elm.date}</td>
                                                <td><button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit" onClick={() => handleNotesEdit(elm)} ><i className="fas fa-pencil-alt"></i></button></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>

            </Modal >

            {/* Completed Not Planned */}
            < Modal show={completed_not_planned} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setCompletedNotPlanned(!completed_not_planned); handleClose() }} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Completed Not Planned</Modal.Title>
                    <div style={{ position: 'absolute', right: '110px' }}>
                        <Link to="#" className="custom-icon-div" onClick={() => { { formData.event_status === 'Edit Task' ? setAddShow(!add_show) : setShow(!show) }; setLogShow(!log_show); }}>
                            <span className="custom-icon" style={{ backgroundColor: '#333547' }} >
                                {'I'}
                                <i className="tooltiptext">Information</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setAddShow(!add_show) }}>
                            <span className="custom-icon">
                                {'L'}
                                {
                                    logs.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Log</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'C'}
                                <i className="tooltiptext">Cost</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Production Rates</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'M'}{
                                    taskmaterialList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Materials</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setEquipmentShow(!equipment_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'E'}
                                {
                                    taskEquipmentList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Equipment</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Permits</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setAddNotes(!notes_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'N'}{
                                    notesData.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Notes</i>
                            </span>
                        </Link>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Label for="analytics-filter-panel-from-date" className={styles.ganttToolbarSelectLabel}>
                                Completed Date
                            </Label>
                            <DatePicker
                                selected={state.completed_date}
                                onChange={(date) => setState({
                                    ...state,
                                    completed_date: date
                                })}
                                name="fromcompleted_date_date"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                            />

                        </div>
                        <div className="col-lg-6 text-end mt-4">
                            <Button
                                color="primary"
                                className="btn-primary btn waves-effect waves-light"
                                onClick={() => handleCompleteNotPlannedTask(state, formData.id)}
                            >
                                Complete
                            </Button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >
            {/* Log List*/}
            < Modal show={log_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setLogShow(!log_show); handleClose() }} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Task Logs</Modal.Title>
                    <div style={{ position: 'absolute', right: '110px' }}>
                        <Link to="#" className="custom-icon-div" onClick={() => { { formData.event_status === 'Edit Task' ? setAddShow(!add_show) : setShow(!show) }; setLogShow(!log_show); }}>
                            <span className="custom-icon" style={{ padding: '9px 16px' }} >
                                {'I'}
                                <i className="tooltiptext">Information</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon" style={{ backgroundColor: '#333547' }}>
                                {'L'}
                                {
                                    logs.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Log</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'C'}
                                <i className="tooltiptext">Cost</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Production Rates</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'M'}{
                                    taskmaterialList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Materials</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setEquipmentShow(!equipment_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'E'}
                                {
                                    taskEquipmentList.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Equipment</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div">
                            <span className="custom-icon">
                                {'P'}
                                <i className="tooltiptext">Permits</i>
                            </span>
                        </Link>
                        <Link to="#" className="custom-icon-div" onClick={() => { setAddNotes(!notes_show); setLogShow(!log_show) }}>
                            <span className="custom-icon">
                                {'N'}{
                                    notesData.length !== 0 ? notification() : <></>
                                }
                                <i className="tooltiptext">Notes</i>
                            </span>
                        </Link>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${styles.ganttToolbarWrapper} container-fluid`}>
                        <div className="row justify-content-between">
                            <div className="col-lg-4">
                                <Label for="analytics-filter-panel-from-date" className={styles.ganttToolbarSelectLabel}>
                                    From Date
                                </Label>
                                <DatePicker
                                    selected={state.from_date}
                                    onChange={onFromDateChange}
                                    name="from_date"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                />

                            </div>
                            <div className="col-lg-4">
                                <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                                    To Date
                                </Label>
                                <DatePicker
                                    selected={state.to_date}
                                    onChange={onToDateChange}
                                    name="to_date"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                />
                            </div>
                            <div className="col-lg-4 text-end mt-4">
                                <Button
                                    color="danger"
                                    className="btn-primary btn waves-effect waves-light"
                                    onClick={onResetToolbarState}
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>

                    </div>



                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Old Data</th>
                                    <th scope="col">New Data</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs && logs.map((elm, index) => {
                                        return (
                                            <>
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{elm.oldlog}</td>
                                                        <td>{elm.newlog}</td>
                                                        <td>{elm.date}</td>

                                                    </tr>
                                                </React.Fragment>
                                            </>
                                        )

                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>

            </Modal >


            {/* Add Task  */}
            < Modal show={add_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setAddShow(!add_show); handleClose() }} centered >
                <Modal.Header style={{ gap: '15px' }} closeButton>

                    {formData.event_status === 'Add Task' ? <Modal.Title>New Task</Modal.Title> :
                        <>
                            <Modal.Title>Edit Task
                                <p className='modal-title fs-6'>Object Id{' ( ' + formData.id + ')'}</p>
                            </Modal.Title>

                        </>
                    }
                    {formData.event_status !== 'Add Task' ?
                        <div style={{ position: 'absolute', right: '110px' }}>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon" style={{ padding: '9px 16px', backgroundColor: '#333547' }}>
                                    {'I'}
                                    <i className="tooltiptext">Information</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setAddShow(!add_show) }}>
                                <span className="custom-icon">
                                    {'L'}{
                                        logs.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Log</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'C'}
                                    <i className="tooltiptext">Cost</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'P'}
                                    <i className="tooltiptext">Production Rates</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setAddShow(!add_show) }}>
                                <span className="custom-icon">
                                    {'M'}{
                                        taskmaterialList.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Materials</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setEquipmentShow(!equipment_show); setAddShow(!add_show) }}>
                                <span className="custom-icon">
                                    {'E'}{
                                        taskEquipmentList.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Equipment</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'P'}
                                    <i className="tooltiptext">Permits</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setAddNotes(!notes_show); setAddShow(!add_show) }}>
                                <span className="custom-icon">
                                    {'N'} {
                                        notesData.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Notes</i>
                                </span>
                            </Link>
                        </div> : <></>}


                </Modal.Header>
                <form>
                    <Modal.Body>

                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Description</label>
                                    <input type="text" name='description' className="form-control" value={formData.description} onChange={handleText} placeholder='Enter Description' />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Completion Date</label>
                                    <DatePicker
                                        selected={formData.endDate}
                                        name="startDate"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Duration (Days)</label>
                                    <input type="number" name='duration' min="0" value={formData.duration} onChange={(e) => handleText(e)} className="form-control" placeholder='Enter Duration (Days)' />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Sub Project</label>
                                    <ReactSelect
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        value={formData.sub_package}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Phase Plan</label>
                                    <ReactSelect
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        value={formData.plan_package}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    value={formData.work_packages}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="">
                                    <label htmlFor="plan-description" className="col-form-label">Location</label>
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        value={formData.location}
                                        allowSelectAll={true}
                                        isDisabled={true}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <label htmlFor="plan-description" className="col-form-label">Discipline</label>
                                <ReactSelect
                                    options={disciplineList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={(selected) => handleChanges(selected, 'discipline_list')}
                                    value={formData.discipline}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="">
                                    <label htmlFor="plan-description" className="col-form-label">Crew Size</label>
                                    <input type="number" name='crew_size' min="0" value={formData.crew_size} onChange={handleText} className="form-control" placeholder='Enter crew size' />

                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="plan-description" className="col-form-label">Team</label>
                                        <ReactSelect
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            isDisabled={true}
                                            value={formData.team_package}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Status Codes</label>
                                    <ReactSelect
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        isDisabled={true}
                                        value={formData.status_code}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Member</label>
                                    <ReactSelect
                                        options={member_List}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        value={formData.member}
                                        onChange={(selected) => handleChanges(selected, 'member_select')}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">WBS Code</label>
                                    <input type="text" name='wbs_code' value={formData.wbs_code} onChange={handleText} className="form-control" placeholder='Enter WBS Code' />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center" style={formData.handle_split === 1 ? { display: "none" } : formData.event_status === 'Add Task' ? { display: 'none' } : { display: 'block' }}>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" name='split_task' checked={formData.split_task} value={formData.split_task} onChange={handleText} id="splitTask" />
                                        <label className="form-check-label" htmlFor="splitTask">Split Task</label>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div style={formData.event_status === 'Add Task' ? {} : { position: 'absolute', left: '12px' }}>
                            <button type="button" className="btn btn-primary waves-effect waves-light" data-bs-dismiss="modal" onClick={formData.event_status === 'Add Task' ? () => handleSave(formData) : () => handleUpdate(formData)}>Save</button>
                            <button type="button" className="btn btn-secondary waves-effect waves-light ml-2" data-bs-dismiss="modal" onClick={() => setAddShow(!add_show)}>Cancel</button>
                        </div>
                        {user.role === 'admin' && formData.status_code[0].value === 1 ?
                            <> <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { setCompletedNotPlanned(!completed_not_planned); setAddShow(!add_show) }} style={formData.event_status === 'Add Task' ? { display: 'none' } : { display: 'block' }}  >Completed Not Planned</button></> : <></>}

                        <button type="button" className="btn btn-danger waves-effect waves-light" data-bs-dismiss="modal" style={formData.event_status === 'Add Task' ? { display: 'none' } : { display: 'block' }} onClick={() => handleDeleteTask(formData)} >Delete</button>
                    </Modal.Footer>
                </form>
            </ Modal>
            {/* Add Mile Stone */}
            <Modal Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => { setShow(!show); handleClose() }} centered >
                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>{formData.event_status === 'Edit' ? <>Edit Milestone<p className='modal-title fs-6'>Object Id{' ( ' + formData.id + ')'}</p></> : 'New Milestone'} </Modal.Title>
                    {formData.event_status === 'Edit' ? <>
                        <div style={{ position: 'absolute', right: '110px' }}>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon" style={{ padding: '9px 16px', backgroundColor: '#333547' }}>
                                    {'I'}
                                    <i className="tooltiptext">Information</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setLogShow(!log_show); setShow(!show) }}>
                                <span className="custom-icon">
                                    {'L'}{
                                        logs.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Log</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'C'}
                                    <i className="tooltiptext">Cost</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'P'}
                                    <i className="tooltiptext">Production Rates</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setMaterialShow(!material_show); setShow(!show) }}>
                                <span className="custom-icon">
                                    {'M'}{
                                        taskmaterialList.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Materials</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setEquipmentShow(!equipment_show); setShow(!show) }}>
                                <span className="custom-icon">
                                    {'E'}{
                                        taskEquipmentList.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Equipment</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div">
                                <span className="custom-icon">
                                    {'P'}
                                    <i className="tooltiptext">Permits</i>
                                </span>
                            </Link>
                            <Link to="#" className="custom-icon-div" onClick={() => { setAddNotes(!notes_show); setShow(!show) }}>
                                <span className="custom-icon">
                                    {'N'} {
                                        notesData.length !== 0 ? notification() : <></>
                                    }
                                    <i className="tooltiptext">Notes</i>
                                </span>
                            </Link>
                        </div></> : <></>}
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Description</label>
                                    <input type="text" name='description' className="form-control" value={formData.description} onChange={handleText} placeholder='Enter Description' />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Completion Date</label>
                                    <DatePicker
                                        selected={formData.endDate}
                                        onChange={handleMilestoneDateChange}
                                        name="endDate"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                    />
                                </div>
                            </div>
                            {formData.event_status === 'Edit' ?
                                <>  <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="recipient-name" className="col-form-label">Orginal Completion Date</label>
                                        <DatePicker
                                            selected={formData.orginalendDate}
                                            name="orginalendDate"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            disabled
                                        />
                                    </div>
                                </div></> : <></>}

                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Sub Project</label>
                                    <ReactSelect
                                        options={project_list}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        onChange={(selected) => handleChanges(selected, 'sub_package')}
                                        value={formData.sub_package}
                                        isDisabled={formData.event_status === 'Edit' ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Phase Plan</label>
                                    <ReactSelect
                                        options={planList.map((i) => ({ value: i._id, label: i.name }))}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        onChange={(selected) => handleChanges(selected, 'plan_package')}
                                        value={formData.plan_package}
                                        isDisabled={formData.event_status === 'Edit' ? true : formData.sub_package === "" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                <ReactSelect
                                    options={workList.map((i) => ({ value: i.value, label: i.label }))}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={(selected) => handleChanges(selected, 'work_package')}
                                    value={formData.work_packages}
                                    isDisabled={formData.event_status === 'Edit' ? true : formData.plan_package === "" ? true : false}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="">
                                    <label htmlFor="plan-description" className="col-form-label">Location</label>
                                    <ReactSelect
                                        options={location_List}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => handleChanges(selected, 'location_list')}
                                        value={formData.location}
                                        allowSelectAll={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="plan-description" className="col-form-label">Team</label>
                                        <ReactSelect
                                            options={teams_lists}
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            onChange={(selected) => handleChanges(selected, 'team_code')}
                                            value={formData.team_package}
                                            isDisabled={formData.event_status === 'Edit' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Status Codes</label>
                                    <ReactSelect
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        isDisabled={true}
                                        value={formData.status_code}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">Member</label>
                                    <ReactSelect
                                        options={member_List}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        onChange={(selected) => handleChanges(selected, 'member_select')}
                                        value={formData.member}
                                        isDisabled={formData.event_status === 'Edit' ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="plan-description" className="col-form-label">WBS Code</label>
                                    <input type="text" name='wbs_code' value={formData.wbs_code} onChange={handleText} className="form-control" placeholder='Enter WBS Code' />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ position: 'absolute', left: '12px' }}>
                        <button type="button" className="btn btn-success" onClick={() => closeMilestone(formData.id)}  >Close Milestone </button>
                    </div>

                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={formData.event_status === 'Edit' ? () => handleUpdate(formData) : () => handleSave(formData)}>Confirm</button>
                </Modal.Footer>
            </Modal >

            {/* Add Project */}
            <div div id="confirmPassword" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" style={{ gap: '15px' }}>
                            <h5 className="modal-title" id="exampleModalLabel">New Milestone</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body px-4">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Description</label>
                                            <input type="text" name='description' className="form-control" value={formData.description} onChange={handleText} placeholder='Enter Description' />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Completion Date</label>
                                            <DatePicker
                                                selected={formData.endDate}
                                                onChange={handleMilestoneDateChange}
                                                name="endDate"
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="dd/mm/yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Sub Project</label>
                                            <ReactSelect
                                                options={project_list}
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                onChange={(selected) => handleChanges(selected, 'sub_package')}
                                                value={formData.sub_package}
                                                isDisabled={formData.event_status === 'Edit' ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Phase Plan</label>
                                            <ReactSelect
                                                options={planList.map((i) => ({ value: i._id, label: i.name }))}
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                onChange={(selected) => handleChanges(selected, 'plan_package')}
                                                value={formData.plan_package}
                                                isDisabled={formData.event_status === 'Edit' ? true : formData.sub_package === "" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                        <ReactSelect
                                            options={workList.map((i) => ({ value: i.value, label: i.label }))}
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            onChange={(selected) => handleChanges(selected, 'work_package')}
                                            value={formData.work_packages}
                                            isDisabled={formData.event_status === 'Edit' ? true : formData.plan_package === "" ? true : false}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="">
                                            <label htmlFor="plan-description" className="col-form-label">Location</label>
                                            <ReactSelect
                                                options={location_List}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={(selected) => handleChanges(selected, 'location_list')}
                                                value={formData.location}
                                                allowSelectAll={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <div className="mb-3">
                                                <label htmlFor="plan-description" className="col-form-label">Team</label>
                                                <ReactSelect
                                                    options={teams_lists}
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={(selected) => handleChanges(selected, 'team_code')}
                                                    value={formData.team_package}
                                                    isDisabled={formData.event_status === 'Edit' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Status Codes</label>
                                            <ReactSelect
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                isDisabled={true}
                                                value={formData.status_code}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Member</label>
                                            <ReactSelect
                                                options={member_List}
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                onChange={(selected) => handleChanges(selected, 'member_select')}
                                                value={formData.member}
                                                isDisabled={formData.event_status === 'Edit' ? true : false}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">WBS Code</label>
                                            <input type="text" name='wbs_code' value={formData.wbs_code} onChange={handleText} className="form-control" placeholder='Enter WBS Code' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleSave(formData)}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default GanttChart          