import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import Alert from '../../../helpers/Alert'
import CostComponentsApi from './CostComponentsApi'
import CostCodesApi from '../CostCodes/CostCodesApi'
import CostAttributeApi from '../CostAttribute/CostAttributeApi'

function CostComponent() {

    const { costCodes } = CostCodesApi()
    const { costAttributes } = CostAttributeApi()
    const { costComponent,
        values,
        error,
        handleChange,
        handleSubmit,
        handleEdit,
        deleteComponent
    } = CostComponentsApi()

    const CostCodesList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costcodes.map((elm) => {
                if (elm.status === 1) {
                    return (<><option value={elm._id}>{elm.cost_code}</option></>)
                } else {
                    return null
                }

            }))
        } else {
            return list
        }
    }
    const CostCodesname = (lists, id) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costcodes.map((elm) => {
                if (elm.status === 1) {
                    if (elm._id === id) {
                        return (<>{elm.cost_code}</>)
                    }
                }
                return list
            }))
        }
        return list
    }

    const CostUOM = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costattribute.map((elm) => {
                if (elm.status === 1 && elm.attribute_name === "Unit of Measurement") {
                    return (<><option value={elm._id}>{elm.tag_name}</option></>)
                }
                return list
            }))
        }
        return list
    }

    const CostType = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costattribute.map((elm) => {
                if (elm.status === 1 && elm.attribute_name === "Cost Type") {
                    return (<><option value={elm._id}>{elm.tag_name}</option></>)
                }
                return list
            }))
        }
        return list
    }

    const CostTypename = (lists, id) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costattribute.map((elm) => {
                if (elm.status === 1) {
                    if (elm._id === id) {
                        return (<>{elm.tag_name}</>)
                    }
                }
                return list
            }))
        }
        return list
    }


    const CostComponentsList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costcomponents.map((elm, index) => {
                if (elm.status === 1) {
                    return (<><tr>
                        <th scope="row">{index + 1}</th>
                        <td>
                            <div>{CostCodesname(costCodes, elm.cost_code)}</div>
                        </td>
                        <td>
                            <div>{CostTypename(costAttributes, elm.cost_type)}</div>
                        </td>
                        <td>
                            <div>{CostTypename(costAttributes, elm.cost_uom)}</div>

                        </td>
                        <td>
                            <div>{"$ " + parseInt(elm.rate_per_uom).toLocaleString('en-US')}</div>
                        </td>
                        <td><div>{elm.comments}</div></td>
                        <td>
                            <div className="d-flex addremove_btn">
                                <button type="button" data-bs-target="#costComponents" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                <button onClick={() => deleteComponent(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" ><i className="fas fa-trash-alt"></i></button>
                            </div>
                        </td>
                    </tr></>)
                } else {
                    return (
                        <>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <strike>  <div>{CostCodesname(costCodes, elm.cost_code)}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{CostTypename(costAttributes, elm.cost_type)}</div></strike>
                                </td>
                                <td>
                                    <strike><div>{CostTypename(costAttributes, elm.cost_uom)}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{"$ " + parseInt(elm.rate_per_uom).toLocaleString()}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{elm.comments}</div></strike>
                                </td>

                                <td>
                                    <div className="d-flex addremove_btn">

                                        <button onClick={() => deleteComponent(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="REstore" ><i class="fas fa-trash-restore"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </>)

                }
            }))
        }
        return list
    }


    return (
        <>
            <div id="layout-wrapper">
                <Header title='Cost Components | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='CostTracker' show='CostComponents'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Cost Components</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Cost Tracker</li>
                                            <li className="breadcrumb-item active">Cost Components</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Cost Codes</h4>
                                                        <button type="button" data-bs-target="#costComponents" className="btn btn-primary" data-bs-toggle="modal" data-bs-whatever="@mdo" >Add<i className="fas fa-plus"></i></button>
                                                        <div id="costComponents" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Cost Component</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Cost Codes</label>
                                                                                        <select name='cost_code' onChange={handleChange} value={values.cost_code} className="form-control" >
                                                                                            <option>Select Cost Code</option>
                                                                                            {CostCodesList(costCodes)}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Cost Type</label>
                                                                                        <select className="form-control" name='cost_type' value={values.cost_type} onChange={handleChange} >
                                                                                            <option>Select Cost Type</option>
                                                                                            {CostType(costAttributes)}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">UoM</label>
                                                                                        <select className="form-control" name='cost_uom' value={values.cost_uom} onChange={handleChange}  >
                                                                                            <option>Select Cost UOM</option>
                                                                                            {CostUOM(costAttributes)}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Rates Per UoM</label>
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-text">$</div>
                                                                                            <input type="number" className="form-control" name='rate_per_uom' value={values.rate_per_uom} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Comments</label>
                                                                                        <textarea className="form-control" name='comments' value={values.comments} onChange={handleChange} row="3" ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.work_tag !== '') ? "modal" : ""} onClick={() => handleSubmit(values)}> {(values.id !== '') ? 'Update Component' : 'Add Component'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Cost Codes</th>
                                                                    <th scope="col">Cost Type</th>
                                                                    <th scope="col">UoM</th>
                                                                    <th scope="col">Rates Per UoM</th>
                                                                    <th scope="col">Comments</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {CostComponentsList(costComponent)}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>

            </div>
        </>
    )
}

export default CostComponent