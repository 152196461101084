import React from 'react'
import Apis from '../../../api'

const Auth = (callback) => {
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [values, setValues] = React.useState({
        id: user.id,
        current_password: '',
        new_password: '',
        confirm_password: '',
        key: '',
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    /* Api Call For reset password */

    const getSystemInfo = (key, records) => {
        const raw = {
            id: records.id,
            key: key
        }

        Apis.checkSystemInfo(raw).then((res) => {
            setValues({
                ...values,
                [key]: res.data.data.value,
                key: res.data.data.key
            })
        }).catch((error) => {
            setError({
                ...error,
                error: error.response.data.error,
                color: 'error',
                open: true
            })
        })
    }


    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setError({
            ...error,
            open: false
        })

        setValues({
            ...values,
            [name]: value
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        const formdata = values
        if (formdata.current_password === '') {
            setError({
                ...error,
                error: 'Old password is required',
                color: 'error',
                open: true
            })
        } else if (formdata.new_password === '') {
            setError({
                ...error,
                error: 'New password is required',
                color: 'error',
                open: true
            })
        } else if (formdata.current_password === formdata.new_password) {
            setError({
                ...error,
                error: 'Old Password and new password should be different!',
                color: 'error',
                open: true
            })

        } else if (formdata.confirm_password === '' || formdata.new_password !== formdata.confirm_password) {
            setError({
                ...error,
                error: 'Mismatch password!',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                id: formdata.id,
                current_password: formdata.current_password,
                new_password: formdata.new_password
            }
            Apis.changePassword(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })
        }

    }


    const handleSystemSubmit = (event) => {
        event.preventDefault()
        const formdata = values

        if (formdata.current_password === '') {
            setError({
                ...error,
                error: 'Old password is required',
                color: 'error',
                open: true
            })
        } else if (formdata.current_password === formdata.new_password) {
            setError({
                ...error,
                error: 'Old Password and new password should be different!',
                color: 'error',
                open: true
            })

        } else if (formdata.new_password === '') {
            setError({
                ...error,
                error: 'New password is required',
                color: 'error',
                open: true
            })
        } else if (formdata.confirm_password === '' || formdata.new_password !== formdata.confirm_password) {
            setError({
                ...error,
                error: 'Mismatch password!',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                id: formdata.id,
                key: "system_password",
                value: formdata.new_password,
                current_password: formdata.current_password,
            }
            Apis.updateSystemPassword(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })
        }
    }

    const handleUpadteSystemInfo = (records) => {
        if (records[records.key] === '') {
            setError({
                ...error,
                error: records.key + ' is required!',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                id: records.id,
                key: records.key,
                value: records[records.key]
            }
            Apis.updateSystemPassword(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })

        }

    }
    return {
        values,
        error,
        handleChange,
        handleSubmit,
        handleSystemSubmit,
        getSystemInfo,
        handleUpadteSystemInfo,
        setError
    }
}

export default Auth
