import React from 'react'
import './errorstyle.css'

function PageNotFound() {
    return (
        <>
            <div class="mainbox">
                <div class="err">4</div>
                <i class="far fa-question-circle fa-spin"></i>
                <div class="err2">4</div>
                <div class="msg">Sorry Page link is expired or not vaild! <p>Let's go <a href="/">home</a> and try from there.</p></div>
            </div>
        </>
    )
}

export default PageNotFound
