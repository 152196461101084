import React from 'react';
import Header from '../../../Layout/Header';
import Sidebar from '../../../Layout/Sidebar';
import Footer from '../../../Layout/Footer';

function Reliability() {
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Dashboard Data - Reliability | P2IC'></Header>
                <Sidebar title='Dashboard_data' show='dashboardDataReliability'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Reliability ***COMING SOON***</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Dashboard Data</li>
                                            <li className="breadcrumb-item active">Reliability</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reliability;