import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'

function CostAnalyticsReports() {
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Analytics & Reports | P2IC'></Header>
                <Sidebar title='CostTracker' show='CostAnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Analytics & Reports ***COMING SOON***</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Cost Tracker</li>
                                            <li className="breadcrumb-item active">Analytics & Reports</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default CostAnalyticsReports