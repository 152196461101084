import React, { createRef } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Ppcgraph from './helpers/Ppcgraph'
import Reasongraph from './helpers/Reasongraph'
import AnalyticsToolbar from './helpers/AnalyticsToolbar'
import AnalyticsAdditionalToolbar from './helpers/AnalyticsAdditionalToolbar'
import { Row, Col, Button } from 'reactstrap'
import Card from 'react-bootstrap/Card';
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import RootCausesReportTable from './helpers/RootCausesReportTable'
import Pdf from "react-to-pdf";
import ProjectApi from '../Projects/ProjectApi'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'
import Apis from '../../../api'


const ContainerStyle = { overflow: 'auto', display: 'flex', flexDirection: 'column' }
const GraphsRowStyle = { minHeight: 500 }
const options = {
    unit: 'in',
    format: [10, 10]
};


function Analytics() {
    const ref = createRef();
    const { teamList } = CollaboratorApi()
    const [teams, setTeams] = React.useState([])
    const [projectLists, setProjectLists] = React.useState([])
    const [planList, setPlanList] = React.useState([])
    const [workList, setWorkList] = React.useState([])

    const [toolbarState, setToolbarState] = React.useState({ trend_line: true, min_max_line: true })

    const { projectList } = ProjectApi()
    const { attributes } = AttributeApiCall()

    let teamsLoading = false

    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }

    const onResetToolbarState = () => setToolbarState({
        trend_line: true,
        min_max_line: true,
        subproject: {
            value: '$$all'
        },
        phase_plane: {
            value: '$$all'
        },
        work_packages: {
            value: '$$all'
        }

    })

    /**********Filters*********/
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])

    React.useEffect(() => {
        if (projectList.length > 0) {
            setProjectLists(projectList.projects)
        }
    }, [projectList])

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (toolbarState.subproject) {
            const raw = {
                "project_id": toolbarState.subproject.value,
                "user_id": user.id
            }
            Apis.getPlan(raw).then((res) => {
                setTimeout(() => {
                    setPlanList(res.data.data.plans)
                    setWorkList([])
                }, 1000)


            }).catch((error) => {
                console.log(error.response.data)
            })
        } else {
            setPlanList([])
            setWorkList([])
        }
    }, [toolbarState.subproject])
    React.useEffect(() => {
        if (toolbarState.phase_plane !== '$$all') {
            planList.map((elm) => {
                if (elm._id === toolbarState.phase_plane.value) {
                    elm.packages.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                    setWorkList(elm.packages)
                    return true
                }
                return true
            })
        }
    }, [planList, toolbarState.phase_plane])
    return (
        <>

            <div id="layout-wrapper">
                <Header title='Analytics | P2IC'></Header>
                <Sidebar title='' show='AnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">

                        <div style={ContainerStyle} className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Analytics & Reports</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Analytics & Reports</li>
                                            <li className="breadcrumb-item active">PPC (Production Reliability/ Reasons/ Root Cause)</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <Row className='pr_tagsrow mb-3' >
                                <Col className='col-lg-12'>
                                    <Row  >
                                        <Col className="col-lg-12 mb-3">
                                            <AnalyticsAdditionalToolbar
                                                state={toolbarState}
                                                sub_project={projectLists.map((i) => ({ value: i._id, label: i.name }))}
                                                phase_plane={planList.map((i) => ({ value: i._id, label: i.name }))}
                                                work_packages={workList.map((i) => ({ value: i.value, label: i.label }))}
                                                onChange={onToolbarStateChange}
                                                attributes={attributes}
                                            />
                                        </Col>
                                        <Col className="col-lg-9">
                                            <AnalyticsToolbar
                                                state={toolbarState}
                                                teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                                                onChange={onToolbarStateChange}
                                                trendLine={true}
                                            />
                                        </Col>
                                        <Col className="col-lg-3">
                                            <div className='container-fluid'>
                                                <Row className=" align-self-end justify-content-end">
                                                    <Col className="col-lg-6 d-flex justify-content-end ">
                                                        <Pdf targetRef={ref} filename={"Analytics-Chart-" + new Date().toDateString() + ".pdf"} options={options} x={.4} y={.4} scale={.4}  >
                                                            {({ toPdf }) => <Button className='btn btn-success' onClick={toPdf}>Generate Pdf</Button>}
                                                        </Pdf>
                                                    </Col>
                                                    <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                                        <Button
                                                            color="danger"
                                                            className="waves-effect waves-light"
                                                            onClick={onResetToolbarState}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <div ref={ref}  >
                                <Row className='pr_tagsrow'>
                                    <Col className='col-lg-12'>
                                        <Row style={GraphsRowStyle} >
                                            <Col className="col-lg-12">
                                                <Card >
                                                    <Card.Body>
                                                        <Ppcgraph
                                                            fromDate={toolbarState.from_date}
                                                            toDate={toolbarState.to_date}
                                                            teamId={toolbarState.team}
                                                            subproject={toolbarState.subproject}
                                                            phase_plane={toolbarState.phase_plane}
                                                            work_packages={toolbarState.work_packages}
                                                            teams={teams}
                                                            loading={teamsLoading}
                                                            location_id={toolbarState.location_id}
                                                            showTrendLine={toolbarState.trend_line}
                                                            showMinMAxLine={toolbarState.min_max_line}
                                                        />
                                                    </Card.Body>
                                                    <Card.Footer className='text-center fs-4 fw-normal' style={{ backgroundColor: '#ffffff', marginTop: '-50px' }}>
                                                        <label>Reliability Report (PPC)</label>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='col-lg-12'>
                                        <Row >
                                            <Col className="col-lg-12">
                                                <Card >
                                                    <Card.Body>
                                                        <Reasongraph
                                                            fromDate={toolbarState.from_date}
                                                            toDate={toolbarState.to_date}
                                                            teamId={toolbarState.team}
                                                            subproject={toolbarState.subproject}
                                                            phase_plane={toolbarState.phase_plane}
                                                            work_packages={toolbarState.work_packages}
                                                            location_id={toolbarState.location_id}
                                                            loading={teamsLoading}
                                                        />
                                                    </Card.Body>
                                                    <Card.Footer className='text-center fs-4 fw-normal' style={{ backgroundColor: '#ffffff', marginTop: '-50px' }}>
                                                        <label>Reasons Report</label>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Row className='pr_tagsrow'>
                                <Col className='col-lg-12'>
                                    <Row  >
                                        <Col className="col-lg-12">
                                            <Card >
                                                <Card.Body >
                                                    <RootCausesReportTable
                                                        fromDate={toolbarState.from_date}
                                                        toDate={toolbarState.to_date}
                                                        teamId={toolbarState.team}
                                                        teams={teams}
                                                        subproject={toolbarState.subproject}
                                                        phase_plane={toolbarState.phase_plane}
                                                        location_id={toolbarState.location_id}
                                                        work_packages={toolbarState.work_packages}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="row">
                                <Footer></Footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Analytics