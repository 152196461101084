import React from 'react';
import keyBy from 'lodash/keyBy';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const LegendWrapperStyle = { lineHeight: 4 }

const IssueCalanderTable = ({ graphData, title, toDate, actionList, issueList }) => {
    const [graphActionData, setGraphActionData] = React.useState([])
    const [actionLogs, setActionLogs] = React.useState([])
    const [issueLogs, setIssueLogs] = React.useState([])
    React.useEffect(() => {
        var tempData = []

        actionList && actionList.map((elm) => {
            const date = new Date(elm.timeStamp)
            const month = date.toLocaleString('default', { month: 'short' })
            const index = tempData.findIndex(obj => obj.month === month);


            if (index === -1) {
                elm.month = month
                var demo = {
                    month: month,
                    action_log: 1,
                    action_close_out: elm.status ? 1 : 0,

                }
                tempData.push(demo)
            } else {

                tempData[index].action_log++
                if (elm.status) {
                    tempData[index].action_close_out++
                }
            }

        })

        setActionLogs(tempData)

    }, [actionList])
    React.useEffect(() => {
        var tempData = []
        issueList && issueList.map((elm) => {

            const date = new Date(elm.reported_date)
            const month = date.toLocaleString('default', { month: 'short' })
            const index = tempData.findIndex(obj => obj.month === month);

            if (index === -1) {
                elm.month = month
                var demo = {
                    month: month,
                    issue_close_out: !elm.status ? 1 : 0,
                }
                tempData.push(demo)
            } else {
                if (!elm.status) {
                    tempData[index].issue_close_out++
                }
            }

        })
        setIssueLogs(tempData)

    }, [issueList])

    const actionLogByMonth = keyBy(actionLogs, 'month')
    const issueLogByMonth = keyBy(issueLogs, 'month')
    React.useEffect(() => {
        const tempData = [];
        const year = toDate.getFullYear();
        var action_cum = 0
        var issue_cum = 0
        for (let i = 0; i <= 11; i++) {
            const date = new Date(year, i, i + 1)
            const month = date.toLocaleString('default', { month: 'short' })

            const action_log = actionLogByMonth[month]?.action_log ? actionLogByMonth[month]?.action_log : 0
            const action_close_out = actionLogByMonth[month]?.action_close_out ? actionLogByMonth[month]?.action_close_out : 0
            const issue_close_out = issueLogByMonth[month]?.issue_close_out ? issueLogByMonth[month]?.issue_close_out : 0

            const index_issue = graphData.findIndex(obj => obj.name === month);
            const issue_log = index_issue !== -1 ? graphData[index_issue].incident : 0;
            issue_cum = parseInt(issue_cum) + parseInt(issue_log - issue_close_out)
            action_cum = parseInt(action_cum) + parseInt(action_log - action_close_out)
            var demo = {
                name: date.toLocaleString('default', { month: 'short' }),
                long: date.toLocaleString('default', { month: 'long' }),
                action_log: action_log,
                issue_log: issue_log,
                issue_close_out: issue_close_out,
                action_close_out: action_close_out,
                issues_cumulative: issue_cum,
                action_cumulative: action_cum

            }

            tempData.push(demo)
        }

        setGraphActionData(tempData)

    }, [actionLogs, issueLogs])
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-footer text-center fs-3 fw-bolder border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            {title + ' Calender View'}
                        </div>
                        <div className="card-body">
                            <table className='table table-striped' cellPadding="5" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <th className='text-center'>{elm.long}</th>
                                                </>
                                            )
                                        })}

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Issue Log</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.issue_log}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Action Log</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.action_log}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Issue Close Out</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.issue_close_out}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Action Close Out</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.action_close_out}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Issues Cumulative</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.issues_cumulative}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Actions Cumulative</td>
                                        {graphActionData && graphActionData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.action_cumulative}</td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder "> {title + ' Issue Action Log'}
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-body" style={{ height: '400px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={graphActionData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip
                                                    formatter={(value, name) => [value ? `${Math.round(value)}` : `0`, name === 'issue_log' ? "Issue Log" : 'Action Log']}
                                                />
                                                <Legend
                                                    wrapperStyle={LegendWrapperStyle}
                                                    verticalAlign="top"
                                                    formatter={(value) => value === 'issue_log' ? "Issue Log" : 'Action Log'}
                                                />
                                                <Line type="monotone" dataKey="issue_log" stroke="#1C99E3" activeDot={{ r: 8 }} />
                                                <Line type="monotone" dataKey="action_log" stroke="#6B04F9" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder "> {title + ' Cumulative Issue V Actions Close Outs'}
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-body" style={{ height: '400px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={graphActionData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip
                                                    formatter={(value, name) => [value ? `${Math.round(value)}` : `0`, name === 'issues_cumulative' ? "Cumulative Issue" : 'Actions Close Outs']}
                                                />
                                                <Legend
                                                    wrapperStyle={LegendWrapperStyle}
                                                    verticalAlign="top"
                                                    formatter={(value) => value === 'issues_cumulative' ? "Cumulative Issue" : 'Actions Close Outs'}
                                                />
                                                <Line type="monotone" dataKey="issues_cumulative" stroke="#1C99E3" activeDot={{ r: 8 }} />
                                                <Line type="monotone" dataKey="action_cumulative" stroke="#F79D0C" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default IssueCalanderTable;