import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

function Alert(props) {
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setError({
            ...error,
            open: false,
        })
    }

    React.useEffect(() => {
        setError({
            error: props.error.error,
            color: props.error.color,
            open: props.error.open,
        })
    }, [props])



    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={error.color} sx={{ width: '100%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Alert
