import React, { forwardRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Label, Input } from 'reactstrap'
import styles from '../../../../../Analytics/helpers/styles.module.scss'
import { default as ReactSelect } from "react-select"
import DatePicker from 'react-datepicker'

const customPlaceholderStyles = (provided) => ({ ...provided, fontSize: '0.8em' })

const customOptionStyles = (provided, state) => provided

const customStyles = {
    placeholder: customPlaceholderStyles,
    option: customOptionStyles,
    control: (base) => ({
        ...base,
        height: 28,
        minHeight: 28,
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0 3px',
    }),
    clearIndicator: (styles) => ({
        ...styles,
        padding: '0 3px',
    }),
    valueContainer: (styles) => ({
        ...styles,
        height: 28,
    }),
}

const CustomDatePickerInput = forwardRef(({ value, ...props }, ref) => {
    const formattedValue = value
        ? new Date(value).toLocaleString(window.navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
        : ''
    return <Input bsSize="sm" type="text" {...props} value={formattedValue} ref={ref} />
})

const RequestByTeamToolbar = ({ teams = [], suppliers = [], materials = [], state = {}, showTeam = false, onChange }) => {
    var teamsValue = null
    var supplierValue = null
    var materialValue = null
    if (state['team'] !== '') {
        teamsValue = teams.find((i) => i.value === state['team'])
    }
    if (state['supplier'] !== '') {
        supplierValue = suppliers.find((i) => i.value === state['supplier'])
    }
    if (state['material'] !== '') {
        materialValue = materials.find((i) => i.value === state['material'])
    }
    const onTeamChange = (option, params) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: params.name,
            value: params.action === 'clear' ? '' : option.value,
        })
    }

    const onFromDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: 'from_date',
            value: date,
        })
    }

    const onToDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: 'to_date',
            value: date,
        })
    }

    return (
        <>
            <div className='container-fluid'>
                <Row className='justify-content-between'>
                    {showTeam ? <>
                        <Col >
                            <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                                Team
                            </Label>
                            <ReactSelect
                                id="analytics-filter-panel-teams"
                                placeholder="Filter Team..."
                                className={styles.ganttToolbarSelectField}
                                styles={customStyles}
                                options={[...teams]}
                                value={teamsValue}
                                name="team"
                                onChange={onTeamChange}
                                isClearable

                            />
                        </Col></> : <></>}

                    <Col >
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Supplier
                        </Label>
                        <ReactSelect
                            id="analytics-filter-panel-teams"
                            placeholder="Filter Supplier..."
                            className={styles.ganttToolbarSelectField}
                            options={suppliers}
                            name="supplier"
                            value={supplierValue}
                            onChange={onTeamChange}
                            isClearable
                            styles={customStyles}

                        />
                    </Col>
                    <Col >
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Material
                        </Label>
                        <ReactSelect
                            id="analytics-filter-panel-teams"
                            placeholder="Filter Material..."
                            className={styles.ganttToolbarSelectField}
                            options={materials}
                            name="material"
                            value={materialValue}
                            onChange={onTeamChange}
                            isClearable
                            styles={customStyles}

                        />
                    </Col>
                    <Col >
                        <Label for="analytics-filter-panel-from-date" className={styles.ganttToolbarSelectLabel}>
                            From Date
                        </Label>
                        <DatePicker
                            id="analytics-filter-panel-from-date"
                            selected={state['from_date']}
                            onChange={onFromDateChange}
                            selectsStart
                            startDate={state['from_date']}
                            endDate={state['to_date']}
                            portalId="analytics-filter-panel-from-date-portal"
                            name='from_date'
                            customInput={<CustomDatePickerInput />}
                            showPopperArrow={false}
                        />
                    </Col>
                    <Col>
                        <div className="col ">
                            <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                                To Date
                            </Label>
                            <DatePicker
                                id="analytics-filter-panel-to-date"
                                selected={state['to_date']}
                                onChange={onToDateChange}
                                selectsEnd
                                startDate={state['from_date']}
                                endDate={state['to_date']}
                                minDate={state['from_date']}
                                portalId="analytics-filter-panel-to-date-portal"
                                name='to_date'
                                customInput={<CustomDatePickerInput />}
                                showPopperArrow={false}
                            />
                        </div></Col>
                </Row>
            </div>
        </>
    )
}

export default RequestByTeamToolbar