import { useState, useRef, useEffect } from 'react';
import {
    BryntumGantt,
    BryntumProjectModel
} from '@bryntum/gantt-react'
import { ganttConfig, projectConfig, ganttResourcesConfig } from './GanttConfig'
import Apis from '../../../../../api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'



const findInArrayNew = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.id === id) {
            return i
        }
    }
    return -1
}
const findTaskInArray = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.discipline_id === id) {
            return i
        }
    }
    return -1
}


const findTaskSegmentIndex = (arr, date) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.task_start === date) {
            return i
        }
    }
    return -1
}

const GanttComponets = ({ taskData, attributes, handleEditClick, handleAddTask, resources, resourcesShow, status_code, resourcesData, handleRefresh, resrefresh, calanderData }) => {
    const gantt = useRef();
    const project = useRef();
    const resourceproject = useRef();
    const resourcegantt = useRef();
    const [show_loader, setLoaderShow] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [dependencies, setDependencies] = useState([]);
    const [resources_data, setResource] = useState([])
    const rdependencies = [];
    const rassignments = [];
    const [ganttConfigs, setConfig] = useState(ganttConfig)
    const [calendars, setCalanderData] = useState([]);

    useEffect(() => {
        const calendars = [{
            id: 'general',
            name: 'General',
            intervals: [
                {
                    recurrentStartDate: 'on Sat at 0:00',
                    recurrentEndDate: 'on Mon at 0:00',
                    isWorking: false
                }
            ],
            expanded: true,
        }];
        var projectCalendarValues = calanderData.projectCalendarValues
        if (projectCalendarValues.length !== 0) {
            projectCalendarValues.map((elm) => {
                let startDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime();
                let endDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000).getTime();
                var demo = {
                    endDate: new Date(endDate),
                    startDate: new Date(startDate),
                    isWorking: elm.isWorkingDay
                }
                calendars[0].intervals.push(demo)
                return true
            })
        }

        setCalanderData(calendars)

    }, [calanderData.projectCalendarValues])

    const [style, setStyle] = useState({
        display: 'block'
    })

    useEffect(() => {
        setConfig({
            ...ganttConfigs,
            listeners: {
                ...ganttConfigs.listeners,
                afterTaskDrop({ taskRecords }) {
                    const newStartDate = taskRecords[0]._data.startDate

                    const draggedTaskRecords = taskRecords
                    if (draggedTaskRecords[0].status_code[0].label === 'Constrained') {
                        let date1 = new Date(draggedTaskRecords[0].previousSibling.endDate)
                        let date2 = new Date(newStartDate)
                        const getBusinessDatesCount = (startDate, endDate) => {
                            let count = 0;
                            let curDate = +startDate;
                            while (curDate <= +endDate) {
                                const dayOfWeek = new Date(curDate).getDay();
                                const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
                                if (!isWeekend) {
                                    count++;
                                }
                                curDate = curDate + 24 * 60 * 60 * 1000
                            }
                            return count;
                        }
                        const diff = getBusinessDatesCount(date1, date2)
                        let date = new Date(newStartDate)
                        date = new Date(newStartDate).getTime()
                        let differ = 0
                        if (diff === 0) {
                            differ = 0
                        } else {
                            differ = diff - 1
                        }
                        const user = JSON.parse(localStorage.getItem('user-info'))
                        const raw = {
                            type: draggedTaskRecords[0].type,
                            startdate: date,
                            id: draggedTaskRecords[0].id,
                            name: draggedTaskRecords[0].name,
                            diff: differ,
                            link_update: true,
                            user_id: user.id,
                            timeStamp: new Date().getTime()
                        }
                        Apis.updateTaskDrag(raw).then((res) => {
                            let datas = taskRecords[0]._parent.children
                            let raw = {
                                id: datas.map((elm) => {
                                    return elm.id
                                }),
                                dates: datas.map((elm) => {
                                    let newStartDate = elm._data.startDate
                                    return new Date(newStartDate).getTime()

                                }),
                                endDate: datas.map((elm) => {
                                    let newEndDate = elm._data.endDate
                                    return new Date(newEndDate).getTime()
                                }),
                                parentIndex: datas.map((elm) => {
                                    return elm.parentIndex
                                }),
                                user_id: user.id,
                                timeStamp: new Date().getTime()
                            }
                            Apis.bulkTaskDrag(raw).then((res) => {
                                handleRefresh(!resrefresh)
                                return true
                            }).catch((error) => {
                                return false
                            })
                        }).catch((error) => {
                            return false
                        })
                    } else {
                        var targetTime = new Date(newStartDate);
                        //get the timezone offset from local time in minutes
                        var tzDifference = targetTime.getTimezoneOffset();
                        //convert the offset to milliseconds, add to targetTime, and make a new Date
                        var offsetTime = new Date(targetTime.getTime() - tzDifference * 60 * 1000);
                        let day = new Date(offsetTime).getDay()
                        let setdate = 0
                        if (day === 0) {
                            setdate = 1
                        } else if (day === 6) {
                            setdate = 2
                        }
                        const startdate = new Date(new Date(offsetTime).getTime() + setdate * 24 * 60 * 60 * 1000)
                        const user = JSON.parse(localStorage.getItem('user-info'))
                        const raw = {
                            type: draggedTaskRecords[0].type,
                            startdate: startdate.getTime(),
                            id: draggedTaskRecords[0].id,
                            name: draggedTaskRecords[0].name,
                            diff: 0,
                            link_update: false,
                            user_id: user.id,
                            timeStamp: new Date().getTime()
                        }
                        Apis.updateTaskDrag(raw).then((res) => {
                            let datas = taskRecords[0]._parent.children
                            let raw = {
                                id: datas.map((elm) => {
                                    return elm.id
                                }),
                                dates: datas.map((elm) => {
                                    let newStartDate = elm._data.startDate
                                    return new Date(newStartDate).getTime()

                                }),
                                endDate: datas.map((elm) => {
                                    let newEndDate = elm._data.endDate
                                    return new Date(newEndDate).getTime()
                                }),
                                parentIndex: datas.map((elm) => {
                                    return elm.parentIndex
                                }),
                                user_id: user.id,
                                timeStamp: new Date().getTime()
                            }
                            Apis.bulkTaskDrag(raw).then((res) => {
                                handleRefresh(!resrefresh)
                                return true
                            }).catch((error) => {
                                return false
                            })
                        }).catch((error) => {
                            return false
                        })
                    }
                }

            }

        })
    }, [resrefresh])

    useEffect(() => {
        if (resourcesShow) {
            setStyle({
                ...style,
                display: 'block'
            })
        } else {
            setTimeout(() => {
                setStyle({
                    ...style,
                    display: 'none'
                })
            }, 500)

        }
    }, [resourcesShow])
    useEffect(() => {
        resourcegantt.current.instance.addPartner(gantt.current.instance);
    }, [])


    useEffect(() => {
        setLoaderShow(true)
        /* setTasks([])  */
        setDependencies([])
        setAssignments([])
        const user = JSON.parse(localStorage.getItem('user-info'))

        if (taskData.length !== 0 && attributes.length !== 0) {

            setTimeout(() => {
                let list = []
                let demo = null
                let projects = taskData.project
                let milestones = taskData.milestones
                let tasks = taskData.tasks
                let keycount = []

                projects.map((project) => {
                    const location = project.location_id.map((loc) => {
                        return loc.label
                    })
                    demo = {
                        id: project._id,
                        type: project.type,
                        name: project.name,
                        startDate: new Date(new Date(project.startDate).setHours(0, 0, 0, 0)),
                        taskrefdate: new Date(project.complition_date).setHours(0, 0, 0, 0),
                        isCompletedSuccessfully: project.isCompletedSuccessfully,
                        subproject: project.subproject,
                        phase_plane: project.phase_plane,
                        work_packages: project.work_packages,
                        location_id: project.location_id,
                        location_name: location.toString(),
                        status_code: project.status_code,
                        wbs_code: project.wbs_code,
                        team: project.team,
                        member: project.member,
                        duration: 0,
                        nextindex: 0,
                        rollup: true,
                        expanded: true,
                        crewsize: project.crewsize,
                        discipline: project.discipline,
                        manuallyScheduled: false,
                        inactive: false,
                        user_role: user.role,
                        /* eventColor: "#8ee997", */
                        children: []
                    }
                    list.push(demo)
                    demo = { key: 0 }
                    keycount.push(demo)
                    return list
                })
                projects.map((project, key) => {
                    milestones.map((milestone) => {
                        if (milestone.parent === project._id) {
                            tasks.map((task) => {
                                if (task.parent === milestone._id) {
                                    let startDate = new Date(new Date(task.startDate).setHours(0, 0, 0, 0))
                                    let working = []
                                    for (let i = 0; i < task.duration; i++) {
                                        working.push(task.crewsize)
                                    }
                                    const discipline = task.discipline.value ? task.discipline : task.discipline[0]
                                    /*   const eventColor = attributes.projectattribute.filter((attrib) => {
                                          if (attrib._id === discipline.value) {
                                              if (attrib.color) {
                                                  return attrib.color
                                              } else {
                                                  return "#8ee997"
                                              }
                                          }
                                      }) */

                                    const location = project.location_id.map((loc) => {
                                        return loc.label
                                    })

                                    demo = {
                                        id: task._id,
                                        type: task.type,
                                        name: task.name,
                                        startDate: startDate,
                                        taskrefdate: task.complition_date,
                                        isCompletedSuccessfully: task.isCompletedSuccessfully,
                                        subproject: task.subproject,
                                        phase_plane: task.phase_plane,
                                        work_packages: task.work_packages,
                                        location_id: task.location_id,
                                        location_name: location.toString(),
                                        status_code: task.status_code,
                                        wbs_code: task.wbs_code,
                                        team: task.team,
                                        member: task.member,
                                        duration: task.duration,
                                        rollup: true,
                                        expanded: true,
                                        manuallyScheduled: task.status_code[0].label === 'Released' || task.status_code[0].label === 'Complete' || task.old_status_code[0].label === 'Released' ? true : status_code.value === 3 ? true : false,
                                        crewsize: task.crewsize,
                                        discipline: discipline,
                                        percentDone: task.work_done,
                                        inactive: task.status_code[0].value === 5 || task.status_code[0].value === 6 ? true : false,
                                        nextindex: 0,
                                        hoursWorked: working,
                                        user_role: user.role,
                                        /* eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997', */
                                        cls: 'important'
                                    }
                                    list[key].children.push(demo)
                                    let count = keycount[key].key + 1
                                    keycount[key].key = count
                                }
                                return list
                            })
                        }
                        return list
                    })
                    return list
                })
                if (status_code.value !== 3) {
                    projects.map((project, key) => {
                        milestones.map((milestone) => {
                            if (milestone.parent === project._id) {
                                const location = project.location_id.map((loc) => {
                                    return loc.label
                                })
                                demo = {
                                    id: milestone._id,
                                    type: milestone.type,
                                    name: milestone.name,
                                    startDate: new Date(new Date(milestone.startDate).setHours(23, 59, 59, 0)),
                                    taskrefdate: new Date(milestone.complition_date).setHours(0, 0, 0, 0),
                                    isCompletedSuccessfully: milestone.isCompletedSuccessfully,
                                    subproject: milestone.subproject,
                                    phase_plane: milestone.phase_plane,
                                    work_packages: milestone.work_packages,
                                    location_id: milestone.location_id,
                                    location_name: location.toString(),
                                    status_code: milestone.status_code,
                                    wbs_code: milestone.wbs_code,
                                    team: milestone.team,
                                    member: milestone.member,
                                    duration: 0,
                                    rollup: true,
                                    expanded: true,
                                    manuallyScheduled: true,
                                    crewsize: milestone.crewsize,
                                    discipline: milestone.discipline,
                                    percentDone: milestone.work_done,
                                    inactive: milestone.status_code[0].value === 5 || milestone.status_code[0].value === 6 ? true : false,
                                    nextindex: keycount[key].key,
                                    user_role: user.role,
                                    /*  eventColor: null, */
                                }
                                list[key].children.push(demo)
                            }
                            return list
                        })
                        return list
                    })
                }

                setTasks(list)

                let dependenciesList = []
                const dependencies = taskData.link
                dependencies.map((elm) => {
                    demo = {
                        id: elm._id,
                        fromTask: elm.fromTask,
                        toTask: elm.toTask,
                        lag: elm.lag
                    }
                    dependenciesList.push(demo)
                    return dependenciesList
                })
                setDependencies(dependenciesList)

                setLoaderShow(false)
            }, 1000);
        } else {
            setTasks([])
            setDependencies([])
            setAssignments([])
            setLoaderShow(false)
        }

    }, [taskData, attributes, status_code])

    useEffect(() => {
        setResource([])
        if (resourcesData.length !== 0 && resources.length !== 0 && attributes.length !== 0) {
            setLoaderShow(true)
            setTimeout(() => {
                let resourcelist = []
                let demo = null
                let teams = resources
                let taskResources = resourcesData.taskResources
                teams.map((team) => {
                    demo = {
                        id: team.id,
                        name: team.name,
                        startDate: new Date(),
                        duration: 100,
                        rollup: true,
                        manuallyScheduled: false,
                        expanded: true,
                        eventColor: "#ffffff00",
                        cls: 'resources-header',
                        children: []
                    }
                    resourcelist.push(demo)
                    return resourcelist
                })
                taskResources.map((task, index) => {
                    let startDate = new Date(new Date(task.startDate).setHours(0, 0, 0, 0))
                    const discipline = task.discipline.value ? task.discipline : task.discipline[0]
                    const projectIndex = findInArrayNew(resourcelist, task.team.value)
                    var taskResourceChilder = resourcelist[projectIndex] ? resourcelist[projectIndex].children : [];
                    const taskIndex = findTaskInArray(taskResourceChilder, discipline.value)

                    const eventColor = attributes.projectattribute.filter((attrib) => {
                        if (attrib._id === discipline.value) {
                            if (attrib.color) {
                                return attrib.color
                            } else {
                                return "#8ee997"
                            }
                        }
                    })

                    if (taskIndex === -1) {
                        let working = []
                        let array = []
                        var duration = task.duration
                        for (let i = 0; i < duration; i++) {
                            working.push(task.crewsize)
                            let array_demo = {
                                duration: duration,
                                task_start: new Date(new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
                            }
                            array.push(array_demo)
                        }
                        let task_demo = {
                            id: task._id,
                            name: discipline.label,
                            startDate: startDate,
                            manuallyScheduled: true,
                            expanded: false,
                            team_id: task.team.value,
                            discipline_id: discipline.value,
                            hoursWorked: working,
                            duration: duration,
                            eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997',
                            cls: 'resources-header',
                            task_array: array
                        }

                        if (resourcelist[projectIndex]) {
                            if (resourcelist[projectIndex].children.length === 0) {
                                resourcelist[projectIndex].children.push(task_demo)
                            } else {
                                for (let b = 0; b < resourcelist[projectIndex].children.length; b++) {
                                    resourcelist[projectIndex].children[b].duration = parseInt(resourcelist[projectIndex].children[b].duration) + parseInt(task.duration)
                                    for (let c = 0; c < task.duration; c++) {
                                        resourcelist[projectIndex].children[b].hoursWorked.push(0)
                                        let array_demo = {
                                            duration: task.duration,
                                            task_start: new Date(new Date(task.startDate).getTime() + c * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
                                        }
                                        resourcelist[projectIndex].children[b].task_array.push(array_demo)
                                    }
                                }
                                resourcelist[projectIndex].children.push(task_demo)
                            }
                        }

                        return resourcelist
                    } else {
                        const taskSegmentIndex = findTaskSegmentIndex(resourcelist[projectIndex].children[taskIndex].task_array, new Date(task.startDate).setHours(0, 0, 0, 0))

                        if (taskSegmentIndex === -1) {
                            resourcelist[projectIndex].children[taskIndex].duration = parseInt(resourcelist[projectIndex].children[taskIndex].duration) + parseInt(task.duration)
                            for (let i = 0; i < task.duration; i++) {
                                resourcelist[projectIndex].children[taskIndex].hoursWorked.push(task.crewsize)
                                demo = {
                                    duration: task.duration,
                                    task_start: new Date(new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
                                }
                                resourcelist[projectIndex].children[taskIndex].task_array.push(demo)
                            }
                            for (let b = 0; b < resourcelist[projectIndex].children.length; b++) {
                                if (b !== taskIndex) {
                                    resourcelist[projectIndex].children[b].duration = parseInt(resourcelist[projectIndex].children[b].duration) + parseInt(task.duration)
                                    for (let a = 0; a < task.duration; a++) {
                                        resourcelist[projectIndex].children[b].hoursWorked.push(0)
                                        demo = {
                                            duration: 1,
                                            task_start: new Date(new Date(task.startdurationDate).getTime() + a * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
                                        }
                                        resourcelist[projectIndex].children[b].task_array.push(demo)
                                    }
                                }
                            }
                        } else {

                            for (let d = 0; d < task.duration; d++) {
                                if (resourcelist[projectIndex].children[taskIndex].hoursWorked[parseInt(d) + parseInt(taskSegmentIndex)]) {
                                    resourcelist[projectIndex].children[taskIndex].hoursWorked[parseInt(d) + parseInt(taskSegmentIndex)] = parseInt(resourcelist[projectIndex].children[taskIndex].hoursWorked[parseInt(d) + parseInt(taskSegmentIndex)]) + parseInt(task.crewsize)
                                } else {
                                    resourcelist[projectIndex].children[taskIndex].hoursWorked.push(task.crewsize)
                                    resourcelist[projectIndex].children[taskIndex].duration = parseInt(resourcelist[projectIndex].children[taskIndex].duration) + parseInt(1)
                                    demo = {
                                        duration: 1,
                                        task_start: new Date(new Date(task.startDate).getTime() + d * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
                                    }
                                    resourcelist[projectIndex].children[taskIndex].task_array.push(demo)
                                }
                            }

                        }
                    }
                    return resourcelist
                })
                let resourcList = []
                resourcelist.map((elm) => {
                    if (elm.children.length !== 0) {
                        resourcList.push(elm)
                    }
                    return resourcList
                })
                setLoaderShow(false)
                setResource(resourcList)
            }, 2000)


        }
    }, [resourcesData, resources, attributes])




    return (
        <>
            <Backdrop
                sx={{ bgcolor: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={show_loader}
            />
            <Snackbar
                open={show_loader}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#308fe8',

                    }}
                    size={30}
                    thickness={4}
                />
            </Snackbar>



            <BryntumProjectModel
                ref={project}
                {...projectConfig}
                tasks={tasks}
                calendars={calendars}
                assignments={assignments}
                dependencies={dependencies}

            />
            <BryntumGantt
                ref={gantt}
                {...ganttConfigs}
                project={project}
                extraData={{ edit: handleEditClick, add: handleAddTask }}
            />

            <div class="gant_chart_resource" style={style} >
                <BryntumProjectModel
                    ref={resourceproject}
                    {...projectConfig}
                    tasks={resources_data}
                    calendars={calendars}
                    assignments={rassignments}
                    dependencies={rdependencies}

                />
                <BryntumGantt
                    ref={resourcegantt}
                    {...ganttResourcesConfig}
                    project={resourceproject}

                />
            </div>
        </>
    )
}

export default GanttComponets