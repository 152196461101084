import React from 'react';
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import Apis from '../../../api'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

function BulkUpload() {

    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const [response, setResponse] = React.useState({
        total_users: 0,
        uploaded_users: 0,
        duplicate_users: 0,
        incorrect_data: 0,
        status: false
    })

    const [values, setValues] = React.useState({
        csv_files: '',
    })
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setError({
            ...error,
            open: false
        })

        setValues({
            ...values,
            [name]: value
        })
    }

    /* Import CSV */

    const handleImportCSV = () => {

        const formData = new FormData();
        if (values.csv_files) {
            formData.append('files', values.csv_files);
            Apis.uploadMembereImportCSV(formData).then((res) => {
                var data = res.data.data
                setResponse({
                    ...response,
                    total_users: data.total_users,
                    uploaded_users: data.uploaded_users,
                    duplicate_users: data.duplicate_users,
                    incorrect_data: data.incorrect_data,
                    status: true
                })
                setError({
                    ...error,
                    error: 'CSV imported ',
                    color: 'success',
                    open: true
                })

            }).catch((error) => {
                setError({
                    ...error,
                    error: 'CSV size is less then 1 Mb',
                    color: 'error',
                    open: true
                })
            });
        } else {
            setError({
                ...error,
                error: 'Please Select The CSV File',
                color: 'error',
                open: true
            })
        }
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title={'Project Collaborators - Member Upload | P2IC'}></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='projectCollaborators'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Project Collaborators</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Collaborators</li>
                                            <li className="breadcrumb-item active"><Link to="/project-collaborators">Members</Link></li>
                                            <li className="breadcrumb-item active">Bulk Upload</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mx-auto">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title mb-4">Bulk Member Upload</h4>
                                            </div>
                                            <div className="p-1">
                                                <div className="form-group mb-3">
                                                    <label for="projectName" className="form-label">Import Member  CSV</label>
                                                    <a style={{ paddingLeft: '10px', marginTop: '20px' }} href="./assets/doc/member-sample.csv" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download member sample csv" className="waves-effect">
                                                        <span><i class="fa fa-download" aria-hidden="true"></i></span>
                                                    </a>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="files"
                                                        id="fileInput"
                                                        accept=".csv"
                                                        onChange={(e) => {
                                                            handleChange({
                                                                target: {
                                                                    name: 'csv_files',
                                                                    value: e.target.files[0],
                                                                    type: 'file',
                                                                },
                                                            });
                                                        }} />
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12 text-end">
                                                        <button className="btn btn-primary w-md waves-effect waves-light mx-1" onClick={() => handleImportCSV()} >Import</button>
                                                        <Link to="/project-collaborators" className='btn btn-danger waves-effect waves-light'>Cancel</Link>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className={response.status ? 'p-1' : 'p-1 d-none'}>
                                                <div className="row">
                                                    <ul class="list-group">
                                                        <li class="list-group-item"><b>Total User : </b>{response.total_users}</li>
                                                        <li class="list-group-item"><b>Successfully Uploaded : </b> {response.uploaded_users}</li>
                                                        <li class="list-group-item"><b>Duplication Email : </b>{response.duplicate_users}</li>
                                                        <li class="list-group-item"><b>Failed due to incorrect data : </b>{response.incorrect_data}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BulkUpload;