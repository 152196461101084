import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
const headers = [
    { title: '#', prop: 'id' },
    { title: 'Team Name', prop: 'name', isFilterable: true, isSortable: true },
    { title: 'Abrv.', prop: 'abrv', isFilterable: true, isSortable: true },
    { title: 'Handle', prop: 'handle', isFilterable: true, isSortable: true },
    { title: 'Planning Horizon', prop: 'planning', isFilterable: true, isSortable: true },
    { title: 'Color', prop: 'color' },
    { title: 'Action', prop: 'action' },

];




const TeamTable = ({ data, handleDeleteTeam, handleEditTeam }) => {
    const [teamList, setTeamList] = React.useState([])

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 1;
        if (data.length > 0) {
            const teams = data.team
            teams.map((elm) => {
                if (elm.status === 1) {
                    demo = {
                        id: i,
                        name: elm.name,
                        abrv: elm.abrv,
                        handle: elm.handle,
                        planning: elm.planning,
                        status: false,
                        color: <><div>
                            <button type="button" className="btn btn-secondary" style={{ background: elm.color, width: '50px', height: '20px' }}></button>
                        </div></>,
                        action: <><div className="d-flex addremove_btn">
                            <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Teammodal" data-bs-placement="bottom" title="Edit" data-bs-whatever="@mdo" onClick={() => handleEditTeam(elm, 'team')}><i className="fas fa-pencil-alt"></i></button>
                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteTeam(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                        </div></>

                    }
                } else {
                    demo = {
                        id: i,
                        name: elm.name,
                        abrv: elm.abrv,
                        handle: elm.handle,
                        planning: elm.planning,
                        status: 'line-through',
                        color: <><div>
                            <button type="button" className="btn btn-secondary" style={{ background: elm.color, width: '50px', height: '20px' }}></button>
                        </div></>,
                        action: <><div className="d-flex addremove_btn">
                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteTeam(elm._id, 1)}><i class="fas fa-trash-restore"></i></button>
                        </div></>

                    }
                }
                list.push(demo)
                i++

            })


        }
        setTeamList(list)


    }, [data])



    return (
        <>
            <DatatableWrapper
                body={teamList}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody rowProps={(row) => ({
                        style: { textDecorationLine: row.status }
                    })} />
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                       
                    </Col>
                </Row>
            </DatatableWrapper></>
    );
};

export default TeamTable;
