import React from 'react'
import { ganttCloseConfig } from './CapGanttConfig'
import { projectConfig, ganttResourcesConfig } from '../../../LAP/healper/GanttComponets/GanttConfig';
import {
    BryntumGantt,
    BryntumProjectModel,
    BryntumSplitter
} from '@bryntum/gantt-react'
import Apis from '../../../../../api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'

const findInArrayNew = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.id === id) {
            return i
        }
    }
    return -1
}

const findTaskInArray = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.discipline_id === id) {
            return i
        }
    }
    return -1
}

const findTaskSegmentIndex = (arr, date) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.task_start === date) {
            return i
        }
    }
    return -1
}

const findInArray = (arr, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.fromTask === id) {
            return i
        }
    }
    return -1
}



const findAllTask = (arr, id) => {
    let list = [];
    let toTAsk = [];
    arr.map((elm, index) => {
        if (id === elm.fromTask) {
            let abc = list.find((a) => a === elm.fromTask);
            if (!abc) {
                list.push(elm.fromTask);
            }
            list.push(elm.toTask);
            toTAsk.push(elm.toTask);
        } else {
            let abc = toTAsk.find((elms) => elms === elm.fromTask);
            if (abc) {
                let xyz = list.find((elmm) => elmm === elm.toTask);
                if (!xyz) {
                    list.push(elm.toTask);
                }
                toTAsk.push(elm.toTask);
            }
        }
    })
    return list;
};

const CapGhanttCloseChart = ({ team, taskData, attributes, resources, resourcesShow, resourcesData, onChange, commitShows, setCloseCommit_id, calanderData }) => {
    const [style, setStyle] = React.useState({
        display: 'none'
    })
    const [resources_data, setResource] = React.useState([])
    const [show_loader, setLoaderShow] = React.useState(false);
    const [close_commit_id, setcloseCommitId] = React.useState({
        task: [],
        not_task: [],
        complete_task: [],
        task_released: []
    })
    const [dependencie_task, settaskDependencies] = React.useState([])





    /* Gantt Chart variables */
    const gantt = React.useRef();
    const project = React.useRef();
    const resourceproject = React.useRef();
    const resourcegantt = React.useRef();
    const [tasks, setTasks] = React.useState([]);
    const [dependencies, setDependencies] = React.useState([]);
    const [rdependencies, setRDependencies] = React.useState([]);
    const [rassignments, setRAssignments] = React.useState([]);
    const [calendars, setCalendars] = React.useState([{
        id: 'general',
        name: 'General',
        intervals: [
            {
                recurrentStartDate: 'on Sat at 0:00',
                recurrentEndDate: 'on Mon at 0:00',
                isWorking: false
            }
        ],
        expanded: true,
    }]);

    /* Reset data */

    React.useEffect(() => {
        setcloseCommitId({
            task: [],
            not_task: [],
            complete_task: [],
            task_released: []
        })
        settaskDependencies([])
    }, [team])
    React.useEffect(() => { setCloseCommit_id(close_commit_id) }, [close_commit_id])


    /* Prepare resources and gantt chart calander data */

    React.useEffect(() => {
        const calendars = [{
            id: 'general',
            name: 'General',
            intervals: [
                {
                    recurrentStartDate: 'on Sat at 0:00',
                    recurrentEndDate: 'on Mon at 0:00',
                    isWorking: false
                }
            ],
            expanded: true,
        }];
        var projectCalendarValues = calanderData.projectCalendarValues
        if (projectCalendarValues.length !== 0) {
            projectCalendarValues.map((elm) => {
                let startDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime();
                let endDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000).getTime();
                var demo = {
                    endDate: new Date(endDate),
                    startDate: new Date(startDate),
                    isWorking: elm.isWorkingDay
                }
                calendars[0].intervals.push(demo)

            })
        }

        setCalendars(calendars)

    }, [calanderData.projectCalendarValues])
    /* Prepare resources gantt chart data */

    React.useEffect(() => {
        setResource([]);
        if (
            resourcesData.length !== 0 &&
            resources.length !== 0 &&
            attributes.length !== 0 &&
            resourcesShow
        ) {
            setLoaderShow(true);
            setTimeout(() => {
                let resourcelist = [];
                let demo = null;
                let teams = resources;
                let taskResources = resourcesData.taskResources;
                teams.map((team) => {
                    demo = {
                        id: team.id,
                        name: team.name,
                        startDate: new Date(),
                        duration: 100,
                        rollup: true,
                        manuallyScheduled: false,
                        expanded: true,
                        eventColor: "#ffffff00",
                        cls: "resources-header",
                        children: [],
                    };
                    resourcelist.push(demo);
                    return resourcelist;
                });
                taskResources.map((task, index) => {
                    let startDate = new Date(
                        new Date(task.startDate).setHours(0, 0, 0, 0)
                    );
                    const projectIndex = findInArrayNew(resourcelist, task.team.value);
                    const discipline = task.discipline.value ? task.discipline : task.discipline[0]
                    var taskResourceChilder = resourcelist[projectIndex] ? resourcelist[projectIndex].children : [];

                    const taskIndex = findTaskInArray(taskResourceChilder, discipline.value);

                    const eventColor = attributes.projectattribute.filter((attrib) => {
                        if (attrib._id === discipline.value) {
                            if (attrib.color) {
                                return attrib.color
                            } else {
                                return "#8ee997"
                            }
                        }
                    })

                    if (taskIndex === -1) {
                        let working = [];
                        let array = [];
                        var duration = task.duration;
                        for (let i = 0; i < duration; i++) {
                            working.push(task.crewsize);
                            let array_demo = {
                                duration: duration,
                                task_start: new Date(
                                    new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000
                                ).setHours(0, 0, 0, 0),
                            };
                            array.push(array_demo);
                        }
                        let task_demo = {
                            id: task._id,
                            name: discipline.label,
                            startDate: startDate,
                            manuallyScheduled: true,
                            expanded: false,
                            team_id: task.team.value,
                            discipline_id: discipline.value,
                            hoursWorked: working,
                            duration: duration,
                            eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997',
                            cls: "resources-header",
                            task_array: array,
                        };
                        if (resourcelist[projectIndex]) {
                            if (resourcelist[projectIndex].children.length === 0) {
                                resourcelist[projectIndex].children.push(task_demo);
                            } else {
                                for (
                                    let b = 0;
                                    b < resourcelist[projectIndex].children.length;
                                    b++
                                ) {
                                    resourcelist[projectIndex].children[b].duration =
                                        parseInt(resourcelist[projectIndex].children[b].duration) +
                                        parseInt(task.duration);
                                    for (let c = 0; c < task.duration; c++) {
                                        resourcelist[projectIndex].children[b].hoursWorked.push(0);
                                        let array_demo = {
                                            duration: task.duration,
                                            task_start: new Date(
                                                new Date(task.startDate).getTime() +
                                                c * 24 * 60 * 60 * 1000
                                            ).setHours(0, 0, 0, 0),
                                        };
                                        resourcelist[projectIndex].children[b].task_array.push(
                                            array_demo
                                        );
                                    }
                                }
                                resourcelist[projectIndex].children.push(task_demo);
                            }
                        }

                        return resourcelist;
                    } else {
                        const taskSegmentIndex = findTaskSegmentIndex(
                            resourcelist[projectIndex].children[taskIndex].task_array,
                            new Date(task.startDate).setHours(0, 0, 0, 0)
                        );

                        if (taskSegmentIndex === -1) {
                            resourcelist[projectIndex].children[taskIndex].duration =
                                parseInt(
                                    resourcelist[projectIndex].children[taskIndex].duration
                                ) + parseInt(task.duration);
                            for (let i = 0; i < task.duration; i++) {
                                resourcelist[projectIndex].children[taskIndex].hoursWorked.push(
                                    task.crewsize
                                );
                                demo = {
                                    duration: task.duration,
                                    task_start: new Date(
                                        new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000
                                    ).setHours(0, 0, 0, 0),
                                };
                                resourcelist[projectIndex].children[taskIndex].task_array.push(
                                    demo
                                );
                            }
                            for (
                                let b = 0;
                                b < resourcelist[projectIndex].children.length;
                                b++
                            ) {
                                if (b !== taskIndex) {
                                    resourcelist[projectIndex].children[b].duration =
                                        parseInt(resourcelist[projectIndex].children[b].duration) +
                                        parseInt(task.duration);
                                    for (let a = 0; a < task.duration; a++) {
                                        resourcelist[projectIndex].children[b].hoursWorked.push(0);
                                        demo = {
                                            duration: 1,
                                            task_start: new Date(
                                                new Date(task.startdurationDate).getTime() +
                                                a * 24 * 60 * 60 * 1000
                                            ).setHours(0, 0, 0, 0),
                                        };
                                        resourcelist[projectIndex].children[b].task_array.push(
                                            demo
                                        );
                                    }
                                }
                            }
                        } else {
                            for (let d = 0; d < task.duration; d++) {
                                if (
                                    resourcelist[projectIndex].children[taskIndex].hoursWorked[
                                    parseInt(d) + parseInt(taskSegmentIndex)
                                    ]
                                ) {
                                    resourcelist[projectIndex].children[taskIndex].hoursWorked[
                                        parseInt(d) + parseInt(taskSegmentIndex)
                                    ] =
                                        parseInt(
                                            resourcelist[projectIndex].children[taskIndex]
                                                .hoursWorked[parseInt(d) + parseInt(taskSegmentIndex)]
                                        ) + parseInt(task.crewsize);
                                } else {
                                    resourcelist[projectIndex].children[
                                        taskIndex
                                    ].hoursWorked.push(task.crewsize);
                                    resourcelist[projectIndex].children[taskIndex].duration =
                                        parseInt(
                                            resourcelist[projectIndex].children[taskIndex].duration
                                        ) + parseInt(1);
                                    demo = {
                                        duration: 1,
                                        task_start: new Date(
                                            new Date(task.startDate).getTime() +
                                            d * 24 * 60 * 60 * 1000
                                        ).setHours(0, 0, 0, 0),
                                    };
                                    resourcelist[projectIndex].children[
                                        taskIndex
                                    ].task_array.push(demo);
                                }
                            }
                        }
                    }
                    return resourcelist;
                });
                let resourcList = [];
                resourcelist.map((elm) => {
                    if (elm.children.length !== 0) {
                        resourcList.push(elm);
                    }
                    return resourcList;
                });
                setLoaderShow(false);
                setResource(resourcList);
            }, 2000);
        }
    }, [resourcesData, resources, attributes, resourcesShow]);

    /* Show and hide reasources gantt chart */

    React.useEffect(() => {
        if (resourcesShow) {
            setStyle({
                ...style,
                display: 'block'
            })
        } else {
            setStyle({
                ...style,
                display: 'none'
            })
        }

    }, [resourcesShow])

    /* Sync gantt chart or resource chart */

    React.useEffect(() => {
        resourcegantt.current.instance.addPartner(gantt.current.instance);
    }, [])

    /* Prepare gantt chart data */

    React.useEffect(() => {
        if (taskData.project) {
            if (taskData.close_commit) {
                let commit_plan = taskData.commit_plan
                let taskDatas = taskData.tasks
                let dependencieData = taskData.link
                let dependencie_task_list = []
                let complete_task = []
                let commit_id = []
                let not_complete_task = []
                let not_complete_task_reason = []
                let task_released = []
                let demo = null
                taskDatas.map((task) => {
                    dependencieData.map((elm) => {
                        if (task._id === elm.fromTask) {
                            var status_show_next = (task.status_code[0].value === 1) ? true : (task.markedAsCommit === true) ? true : (task.markedAsUnCommit === true) ? true : false;
                            const xyz = dependencieData.find(acc => (acc.toTask === elm.toTask && acc._id !== elm._id));
                            if (xyz) {
                                const markedAsCommit = taskDatas.find(a => a._id === xyz.fromTask && a.markedAsCommit === false);
                                if (markedAsCommit) {
                                    status_show_next = markedAsCommit.markedAsCommit
                                    if (task_released.includes(task._id) === false) {
                                        task_released.push(elm.toTask)
                                    }
                                }
                                const markedAsUnCommit = taskDatas.find(a => a._id === xyz.fromTask && a.markedAsUnCommit === true);
                                if (markedAsUnCommit) {
                                    status_show_next = markedAsUnCommit.markedAsUnCommit
                                }
                            }

                            demo = {
                                id: elm.toTask,
                                fromTask: elm.fromTask,
                                toTask: elm.toTask,
                                status_show: status_show_next,
                                markedAsCommit: task.markedAsCommit,
                                markedAsUnCommit: task.markedAsUnCommit
                            }

                            dependencie_task_list.push(demo)

                        }
                        return dependencie_task_list
                    })
                    if (task.markedAsCommit === true) {
                        if (complete_task.includes(task._id) === false) {
                            complete_task.push(task._id)
                            const plan_id = commit_plan.find(a => a.task_id === task._id)
                            if (commit_id.includes(plan_id._id) === false) {
                                commit_id.push(plan_id._id)
                            }
                        }

                    }
                    if (task.markedAsUnCommit === true) {
                        if (not_complete_task.includes(task._id) === false) {
                            not_complete_task.push(task._id)
                            not_complete_task_reason.push({
                                reason_id: task.reason_code_id,
                                reason_code: task.reason_description
                            })


                        }
                    }
                    return dependencie_task_list
                })
                settaskDependencies(dependencie_task_list)
                setcloseCommitId({
                    task: complete_task,
                    complete_task: commit_id,
                    not_task: not_complete_task,
                    reason: not_complete_task_reason,
                    task_released: task_released
                })
                commitShows({ value: false })
            }
        }
    }, [taskData])

    React.useEffect(() => {
        if (taskData.project && attributes.length !== 0) {
            const user = JSON.parse(localStorage.getItem('user-info'))
            setLoaderShow(true)
            setTimeout(() => {
                let list = []
                let demo = null
                let projects = taskData.project
                let milestones = taskData.milestones
                let tasks = taskData.tasks
                let commit_plan = taskData.commit_plan
                let keycount = []


                projects.map((project) => {
                    const location = project.location_id.map((loc) => {
                        return loc.label
                    })
                    demo = {
                        id: project._id,
                        commit_id: '',
                        type: project.type,
                        name: project.name,
                        startDate: new Date(new Date(project.startDate).setHours(0, 0, 0, 0)),
                        taskrefdate: new Date(project.complition_date).setHours(0, 0, 0, 0),
                        isCompletedSuccessfully: project.isCompletedSuccessfully,
                        subproject: project.subproject,
                        phase_plane: project.phase_plane,
                        work_packages: project.work_packages,
                        location_id: project.location_id,
                        location_name: location.toString(),
                        status_code: project.status_code,
                        old_status_code: project.old_status_code,
                        wbs_code: project.wbs_code,
                        team: project.team,
                        member: project.member,
                        duration: 0,
                        nextindex: 0,
                        rollup: true,
                        expanded: true,
                        crewsize: project.crewsize,
                        discipline: project.discipline,
                        manuallyScheduled: false,
                        inactive: false,
                        eventColor: "#8ee997",
                        markedAsCommit: project.markedAsCommit,
                        markedAsUnCommit: project.markedAsUnCommit,
                        reason_code_id: project.reason_code_id,
                        reason_description: project.reason_description,
                        children: [],
                        user_role: user.role
                    }
                    list.push(demo)
                    demo = { key: 0 }
                    keycount.push(demo)
                    return list
                })
                projects.map((project, key) => {
                    milestones.map((milestone) => {
                        if (milestone.parent === project._id) {
                            tasks.map((task) => {
                                if (task.parent === milestone._id) {
                                    let startDate = new Date(new Date(task.startDate).setHours(0, 0, 0, 0))
                                    let working = []
                                    for (let i = 0; i < task.duration; i++) {
                                        working.push(task.crewsize)
                                    }
                                    const discipline = task.discipline.value ? task.discipline : task.discipline[0]
                                    const eventColor = attributes.projectattribute.filter((attrib) => {
                                        if (attrib._id === discipline.value) {
                                            if (attrib.color) {
                                                return attrib.color
                                            } else {
                                                return "#8ee997"
                                            }
                                        }
                                    })
                                    const location = project.location_id.map((loc) => {
                                        return loc.label
                                    })
                                    if (commit_plan.length !== 0) {
                                        commit_plan.map((commit) => {
                                            if (commit.task_id === task._id) {
                                                demo = {
                                                    id: task._id,
                                                    commit_id: commit._id,
                                                    type: task.type,
                                                    name: task.name,
                                                    startDate: startDate,
                                                    taskrefdate: task.complition_date,
                                                    isCompletedSuccessfully: task.isCompletedSuccessfully,
                                                    subproject: task.subproject,
                                                    phase_plane: task.phase_plane,
                                                    work_packages: task.work_packages,
                                                    location_id: task.location_id,
                                                    location_name: location.toString(),
                                                    status_code: task.status_code,
                                                    old_status_code: task.old_status_code,
                                                    wbs_code: task.wbs_code,
                                                    team: task.team,
                                                    member: task.member,
                                                    duration: task.duration,
                                                    rollup: true,
                                                    manuallyScheduled: task.old_status_code[0].label === 'Released' || task.old_status_code[0].label === 'Complete' || task.old_status_code[0].value === 4 ? true : false,
                                                    crewsize: task.crewsize,
                                                    discipline: task.discipline,
                                                    percentDone: task.work_done,
                                                    inactive: task.status_code[0].value === 5 || task.status_code[0].value === 6 ? true : false,
                                                    nextindex: 0,
                                                    hoursWorked: working,
                                                    eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997',
                                                    cls: 'important',
                                                    markedAsCommit: task.markedAsCommit,
                                                    markedAsUnCommit: task.markedAsUnCommit,
                                                    reason_code_id: task.reason_code_id,
                                                    reason_description: task.reason_description,
                                                    user_role: user.role
                                                }
                                            }

                                        })
                                    }
                                    list[key].children.push(demo)
                                    let count = keycount[key].key + 1
                                    keycount[key].key = count
                                }
                                return list
                            })
                        }
                        return list
                    })
                    return list
                })

                setTasks([])
                setTasks(list)
                setDependencies([])
                let dependenciesList = []
                const dependencies = taskData.link
                dependencies.map((elm) => {
                    demo = {
                        id: elm._id,
                        fromTask: elm.fromTask,
                        toTask: elm.toTask,
                        lag: elm.lag
                    }
                    dependenciesList.push(demo)
                    return dependenciesList
                })

                setDependencies(dependenciesList)
                setLoaderShow(false)
            }, 500);
        } else {
            setTasks([])
            setDependencies([])
            settaskDependencies([])
        }

    }, [taskData, attributes])



    /*functions for complete and uncomplete the task */

    const handleSelect = async (event) => {
        const value = event.target.getAttribute("data-value");
        const id = event.target.getAttribute("data-task")
        const checked = event.target.checked
        let depdendencytask = dependencie_task
        const list = findAllTask(depdendencytask, id)

        if (value === 'close') {
            const raw = {
                "id": id,
                "markedAsCommit": checked,
                "markedAsUnCommit": false,
                "reason_code_id": '',
                "reason_description": '',
                "timeStamp": new Date().getTime()
            }
            await updateTask(raw)
            const task_data = depdendencytask.find(a => a.fromTask === id)
            var markedAsUnCommit_status = false;
            if (task_data) {
                const next_task = depdendencytask.find(b => b.id === task_data.id && b.markedAsUnCommit === true && b.fromTask !== id)
                if (next_task) {
                    markedAsUnCommit_status = true
                }
            }
            list.map(async (elm) => {
                if (elm !== id) {
                    const raw = {
                        "id": elm,
                        "markedAsCommit": false,
                        "markedAsUnCommit": markedAsUnCommit_status,
                        "reason_code_id": markedAsUnCommit_status ? '61e5aa3673ef5a540682b237' : '',
                        "reason_description": markedAsUnCommit_status ? 'Predecessor tasks not completed' : '',
                        "timeStamp": new Date().getTime()
                    }
                    await updateTask(raw)
                }
                return true
            })
        } else {
            const raw = {
                "id": id,
                "markedAsCommit": false,
                "markedAsUnCommit": checked,
                "reason_code_id": '',
                "reason_description": '',
                "timeStamp": new Date().getTime()
            }
            await updateTask(raw)
            list.map(async (elm) => {
                if (elm !== id) {
                    const raw = {
                        "id": elm,
                        "markedAsCommit": false,
                        "markedAsUnCommit": checked,
                        "reason_code_id": '61e5aa3673ef5a540682b237',
                        "reason_description": 'Predecessor tasks not completed',
                        "timeStamp": new Date().getTime()
                    }
                    await updateTask(raw)
                }
                return true
            })
        }
        setTimeout(() => {
            onChange()
        }, 500);
    }



    /* APi Calls */
    const updateTask = (raw) => {
        Apis.updateCommitTask(raw).then((res) => {
            if (raw.refresh) {
                setTimeout(() => {
                    onChange()
                }, 500);
            }
            return true
        }).catch((error) => {
            console.log(error.response.data)
        })
    }



    return (
        <>
         <Backdrop
                sx={{ bgcolor: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={show_loader}
            />
            <Snackbar
                open={show_loader}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#308fe8',

                    }}
                    size={30}
                    thickness={4}
                />
            </Snackbar>            
            <BryntumProjectModel
                ref={project}
                {...projectConfig}
                tasks={tasks}
                calendars={calendars}
                dependencies={dependencies}
                resources={resources}
            />
            <BryntumGantt
                ref={gantt}
                {...ganttCloseConfig}
                project={project}
                extraData={{
                    select: handleSelect,
                    updateTask: updateTask,
                    taskDep: dependencie_task
                }}

            />
            <BryntumSplitter />

            <div class="gant_chart_resource" style={style}>
                <BryntumProjectModel
                    ref={resourceproject}
                    {...projectConfig}
                    tasks={resources_data}
                    calendars={calendars}
                    assignments={rassignments}
                    dependencies={rdependencies}

                />
                <BryntumGantt
                    ref={resourcegantt}
                    {...ganttResourcesConfig}
                    project={resourceproject}

                />
            </div>
        </>
    )
}

export default CapGhanttCloseChart