import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import keyBy from 'lodash/keyBy';
import Apis from '../../../../../api';
import IncidentGraph from '../IncidentGraph';
import IssueGraph from './IssueGraph';
const LegendWrapperStyle = { lineHeight: 4 }

const indexName = {
    quality_0: "Total Defects",
    quality_1: "Defect Free Working Days",
    safety_0: "Incident",
    safety_1: "No Incident",
    cost_0: "Defects",
    cost_1: "Defect Free",
    reliability_0: "Defects",
    reliability_1: "Defect Free",
    people_0: "Defects",
    people_1: "Defect Free",
    delivery_0: "Delays",
    delivery_1: "On Time Delivery",
}

const DetailGraph = ({ title, toDate, teamId, slug, teams }) => {
    const [apiResponse, setapiResonse] = React.useState([]);
    const [show_issue_list, setShowIssueList] = React.useState();
    const [dashboardData, setDashboardData] = React.useState([]);
    const [response, setapResonse] = React.useState([]);
    const [workingDays, setWorkingDaya] = React.useState([])
    const [calendar, setCalendar] = React.useState([])
    const [graphData, setGraphData] = React.useState([])
    const [refresh, setRefresh] = React.useState(false)
    const [issueResponse, setissueApiResonse] = React.useState([]);
    const [safetyDataCount, setSafetyCount] = React.useState({
        weekend: 0,
        incident: 0,
        no_incident: 0,
    });

    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const title_data = data.find(setting => setting.key === slug);
                const graph = title_data ? title_data.value : 'manual';
                setShowIssueList(graph);
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [slug])

    React.useEffect(() => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        let filters = [
            { type: slug }
        ]
        let demo
        if (teamId) {
            demo = { "team_id": teamId.value }
            filters.push(demo)
        }

        if (show_issue_list === 'manual') {
            filters.push({ timeStamp: { $gte: new Date(fromDate).getTime() } },
                { timeStamp: { $lte: new Date(toDates).getTime() } })
            const raw = {
                "filter": filters
            }
            Apis.getDashboardData(raw).then((res) => {
                setapiResonse(res.data.data.dashboard_data)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            filters.push({ reported_date: { $gte: new Date(fromDate).getTime() } },
                { reported_date: { $lte: new Date(toDates).getTime() } })
            const raw = {
                "filter": filters
            }
            Apis.getDashboardIssue(raw).then((res) => {
                setissueApiResonse(res.data.data.dashboard_data)
            }).catch((error) => {
                console.log(error)
            })
        }

    }, [slug, toDate, teamId, show_issue_list, refresh])

    React.useEffect(() => {
        var tempData = []
        apiResponse && apiResponse.map((elm) => {
            const dayOfWeek = new Date(elm.timeStamp).getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                elm.value = '';
            }
            const index = tempData.findIndex(obj => obj.timeStamp === elm.timeStamp);
            if (index === -1) {
                tempData.push(elm)
            } else {
                var value = elm.value
                var tempValue = tempData[index].value
                if (value.length !== 0 && tempValue.length !== 0) {
                    tempData[index].value = parseInt(tempValue) + parseInt(value)
                } else if (tempValue.length === 0 && value.length !== 0) {
                    tempData[index].value = value
                }

            }
        })
        setapResonse(tempData)

    }, [apiResponse])

    React.useEffect(() => {
        var tempData = []
        issueResponse && issueResponse.map((elm) => {
            var startDate = new Date(new Date(elm.reported_date).setHours(0, 0, 0, 0)).getTime()
            const index = tempData.findIndex(obj => obj.timeStamp === startDate);
            if (index === -1) {
                var demo = {
                    _id: elm._id,
                    value: 1,
                    timeStamp: startDate,
                    team_id: elm.team_id,
                    type: elm.type
                }
                tempData.push(demo)
            } else {
                tempData[index].value = tempData[index].value + 1
            }
        })

        setapResonse(tempData)

    }, [issueResponse])

    React.useEffect(() => {
        Apis.getCalendar().then((res) => {
            var calendarData = res.data.data
            if (calendarData.projectSetting !== undefined) {
                setCalendar(calendarData.projectSetting.value.dates)
            }
        }).catch(error => {
            console.log(error.response.data)
        })
    }, [])

    React.useEffect(() => {
        var tempWorkingDay = []
        calendar && calendar.map((elm) => {
            var demo = {
                timeStamp: new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime(),
                isWorkingDay: elm.isWorkingDay
            }
            tempWorkingDay.push(demo)

        })
        setWorkingDaya(tempWorkingDay)

    }, [calendar])
    const workingDaysByID = keyBy(workingDays, 'timeStamp')

    React.useEffect(() => {
        var tempData = []

        response && response.map((elm) => {

            const date = new Date(elm.timeStamp)
            const month = date.toLocaleString('default', { month: 'short' })
            const weekend = workingDaysByID[elm.timeStamp]?.isWorkingDay ? 1 : 0
            const index = tempData.findIndex(obj => obj.month === month);
            if (weekend) {
                elm.value = ''
            }
            const value = elm.value
            if (index === -1) {
                var demo = {
                    month: month,
                    value: elm.value,
                    weekend_count: weekend,
                    no_incident: value.length !== 0 ? 1 : 0,
                    data: [elm]
                }
                tempData.push(demo)
            } else {
                const tempValue = tempData[index].value

                if (value.length !== 0 && tempValue.length !== 0) {
                    tempData[index].value = parseInt(tempValue) + parseInt(value)
                } else if (tempValue.length === 0 && value.length !== 0) {
                    tempData[index].value = elm.value
                }
                const data_index = tempData[index].data.findIndex(obj => obj.timeStamp === elm.timeStamp);

                if (data_index === -1) {
                    tempData[index].data.push(elm)
                    if (value.length !== 0) {
                        tempData[index].no_incident = tempData[index].no_incident + 1
                    }
                } else {

                    const tempdataValue = tempData[index].data[data_index].value


                    if (value.length !== 0 && tempdataValue.length !== 0) {
                        tempData[index].data[data_index].value = value
                        tempData[index].no_incident = tempData[index].no_incident + 1
                    } else if (tempValue.length === 0 && value.length !== 0) {
                        tempData[index].data[data_index].value = value
                        tempData[index].no_incident = tempData[index].no_incident + 1
                    } else {
                        console.log('..')
                        console.log(tempdataValue.length + '->' + tempdataValue)
                        console.log(value.length + '->' + value)
                        console.log(tempValue)
                        console.log('..')
                    }
                }

            }
        })

        setDashboardData(tempData)

    }, [response])

    const dashboardDataByID = keyBy(dashboardData, 'month')


    React.useEffect(() => {
        const tempData = [];
        const year = toDate.getFullYear();
        const current_date = new Date();
        const current_year = current_date.getFullYear();
        const current_month = current_date.getMonth()


        for (let i = 0; i <= 11; i++) {
            const date = new Date(year, i, i + 1)
            const month = date.toLocaleString('default', { month: 'short' })
            const incident = dashboardDataByID[month]?.value ? dashboardDataByID[month]?.value : 0
            const weekend_count = dashboardDataByID[month]?.weekend_count ? dashboardDataByID[month]?.weekend_count : 0
            const no_incident_count = dashboardDataByID[month]?.no_incident ? dashboardDataByID[month]?.no_incident : 0
            const weekend = countWeekends(year, i)
            const dayInMonth = new Date(year, i + 1, 0).getDate();

            const working_days = dayInMonth - weekend - weekend_count
            var no_incident = working_days

            if (no_incident_count > 0) {
                no_incident = working_days - no_incident_count

            }
            if (year <= current_year && i <= current_month) {
                var demo = {
                    name: date.toLocaleString('default', { month: 'short' }),
                    long: date.toLocaleString('default', { month: 'long' }),
                    incident: incident,
                    no_incident: no_incident,
                    dayInMonth: dayInMonth,
                    weekend: weekend
                }
            } else {
                var demo = {
                    name: date.toLocaleString('default', { month: 'short' }),
                    long: date.toLocaleString('default', { month: 'long' }),
                    incident: 0,
                    no_incident: 0,
                    dayInMonth: dayInMonth,
                    weekend: weekend
                }
            }

            tempData.push(demo)
        }

        setGraphData(tempData)

    }, [dashboardData])

    React.useEffect(() => {
        var total_incident = 0
        var total_no_incident = 0
        var total_weekend = 0
        graphData && graphData.map((elm) => {
            total_incident += parseInt(elm.incident);
            total_no_incident += parseInt(elm.no_incident);
            total_weekend += parseInt(elm.weekend);

        })
        setSafetyCount({
            weekend: total_weekend,
            incident: total_incident,
            no_incident: total_no_incident,
        })
    }, [graphData])

    const countWeekends = (year, month) => {
        let weekendsCount = 0;

        // Loop through all days of the month
        for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
            const currentDate = new Date(year, month, day);
            const dayOfWeek = currentDate.getDay();

            // Check if it's Saturday (6) or Sunday (0)
            if (dayOfWeek === 6 || dayOfWeek === 0) {
                weekendsCount++;
            }
        }

        return weekendsCount;
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-4">
                    <IncidentGraph
                        title={slug}
                        toDate={toDate}
                        teamId={teamId}
                        show_link={false}
                        refresh={refresh}

                    />
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <h4 className="card-footer text-center fs-16 border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            {title + ' Count Bar Graph'}
                        </h4>
                        <div className="card-body" style={{ height: '595px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={graphData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >

                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip
                                        formatter={(value, name) => [value ? `${Math.round(value)}` : `0`, name === 'no_incident' ? "No Incident" : 'Incident']}
                                    />
                                    <Legend
                                        wrapperStyle={LegendWrapperStyle}
                                        verticalAlign="top"
                                        formatter={(value) => value === 'no_incident' ? indexName[slug + '_1'] : indexName[slug + '_0']}
                                    />
                                    <Bar dataKey="no_incident" fill="#6fc764" />
                                    <Bar dataKey="incident" fill="red" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-footer text-center fs-3 fw-bolder border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            {title + ' Calender View'}
                        </div>
                        <div className="card-body">
                            <table className='table table-striped' cellPadding="5" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <th className='text-center'>{elm.long}</th>
                                                </>
                                            )
                                        })}
                                        <th className='text-center'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{indexName[slug + '_0']}</td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.incident}</td>
                                                </>
                                            )
                                        })}

                                        <td className='text-center'>{safetyDataCount && safetyDataCount.incident}</td>
                                    </tr>
                                    <tr>
                                        <td>{indexName[slug + '_1']}</td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.no_incident}</td>
                                                </>
                                            )
                                        })}
                                        <td className='text-center'>{safetyDataCount && safetyDataCount.no_incident}</td>
                                    </tr>
                                    <tr>
                                        <td>Holiday/Weekend</td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.weekend}</td>
                                                </>
                                            )
                                        })}
                                        <td className='text-center'>{safetyDataCount && safetyDataCount.weekend}</td>
                                    </tr>
                                    <tr>
                                        <td>Days in the month</td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.dayInMonth}</td>
                                                </>
                                            )
                                        })}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
                {show_issue_list !== 'manual' ? <>
                    <IssueGraph
                        slug={slug}
                        toDate={toDate}
                        teamId={teamId}
                        title={title}
                        teams={teams}
                        graphData={graphData}
                        graphrefresh={refresh}
                        setGraphrefresh={setRefresh} />
                </> : <></>}
            </div>


        </>
    );
};

export default DetailGraph;