import React from 'react'
import Apis from '../../../api'
import swal from 'sweetalert'

const CostComponentsApi = (callback) => {
    const [costComponent, setCodes] = React.useState([])
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [values, setValues] = React.useState({
        cost_code: '',
        cost_type: '',
        cost_uom: '',
        rate_per_uom: '',
        comments: '',
        id: '',
        owner: user.id
    })

    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    /* Function Enter values */
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleEdit = (code, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        setValues({
            ...values,
            cost_code: code.cost_code,
            cost_type: code.cost_type,
            cost_uom: code.cost_uom,
            rate_per_uom: code.rate_per_uom,
            comments: code.comments,
            id: code._id
        })

    }

    /* Function For Get Cost Codes List */

    React.useEffect(() => {
        getComponents()
    }, [])

    const getComponents = () => {
        Apis.getCostComponents().then((res) => {
            setCodes(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
    }

    const handleSubmit = (formdata, event) => {
        if (formdata.cost_code === '') {
            setError({
                ...error,
                error: 'Cost Code is required',
                color: 'error',
                open: true
            })
        } else if (formdata.cost_type === '') {
            setError({
                ...error,
                error: 'Cost Type is required',
                color: 'error',
                open: true
            })
        } else if (formdata.cost_uom === '') {
            setError({
                ...error,
                error: 'Cost UOM is required',
                color: 'error',
                open: true
            })
        } else if (formdata.rate_per_uom === '') {
            setError({
                ...error,
                error: 'Rate Per UOM is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                const raw = {
                    'id': formdata.id,
                    'cost_code': formdata.cost_code,
                    'cost_type': formdata.cost_type,
                    'cost_uom': formdata.cost_uom,
                    'rate_per_uom': formdata.rate_per_uom,
                    'comments': formdata.comments
                }
                Apis.updateCostComponent(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        cost_code: '',
                        cost_type: '',
                        cost_uom: '',
                        rate_per_uom: '',
                        comments: '',
                        id: ''
                    })
                    getComponents()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    'cost_code': formdata.cost_code,
                    'cost_type': formdata.cost_type,
                    'cost_uom': formdata.cost_uom,
                    'rate_per_uom': formdata.rate_per_uom,
                    'comments': formdata.comments,
                    'owner': user.id
                }
                Apis.addCostComponent(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        cost_code: '',
                        cost_type: '',
                        cost_uom: '',
                        rate_per_uom: '',
                        comments: '',
                        id: ''
                    })
                    getComponents()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }

        }
    }

    /* Function For Delete Cost Attribute */

    const deleteComponent = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Attribute Id is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        'status': status
                    }
                    Apis.deleteCostComponent(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        getComponents()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.message,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        });
    }

    return {
        costComponent,
        values,
        error,
        handleChange,
        handleSubmit,
        deleteComponent,
        handleEdit
    }
}

export default CostComponentsApi