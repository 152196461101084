import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import Alert from '../../../helpers/Alert'
import CostTrackMpApi from './CostTrackMpApi'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Moment from 'moment';
import { components } from "react-select"
import { default as ReactSelect } from "react-select"


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}

                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

function CostTrackMP() {
    const { teamList, attributes, costCodes, costAttributes, values, error, costTrackMp, costComponent,
        handleChange, handleSubmit, handleDateChange, handleEdit, handleSaveEdit, handleClose, handleSelectChange } = CostTrackMpApi()
    const lasttwoweek = new Date(Date.now() - 12096e5)
    const fortnightAway = new Date(Date.now() + 18144e5)



    const TeamList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.team.map((elm, index) => {
                if (elm.status === 1) {
                    return (<>
                        <option value={elm._id}>{elm.name}</option>
                    </>)
                }
                return list
            }))
        }
        return list
    }

    const WorkList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.projectattribute.map((elm, index) => {
                if (elm.attribute_name === 'Discipline' && elm.status === 1) {
                    return (<>
                        <option value={elm._id}>{elm.tag_name}</option>
                    </>)
                }
                return list
            }))
        }
        return list
    }

    const LocationList = (lists) => {
        let demo
        let list = []
        if (lists.length > 0) {
            lists.projectattribute.map((elm) => {
                if (elm.attribute_name === 'Location' && elm.status === 1) {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                return null
            })
        }
        return list
    }

    const CostCodeList = (lists, costComponent) => {
        let list = null
        if (costComponent.length > 0) {
            return (list = costComponent.costcomponents.map((elm, index) => {
                if (elm.status === 1) {
                    if (lists.length > 0) {
                        return (lists.costcodes.map((elms) => {
                            if (elms.status === 1) {
                                if (elm.cost_code === elms._id) {
                                    return (<>
                                        <option value={elm._id} >{elms.cost_code}</option>
                                    </>)
                                }
                            }
                            return (<></>)
                        }))
                    }
                }
                return list
            }))
        }
        return list
    }


    const TrackList = (lists, id) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costtrackmp.map((elm) => {
                if (id === '') {
                    return (<>
                        <tr>
                            <td >{GetTeam(teamList, elm.team_id)}</td>
                            <td >{GetWorkStr(attributes, elm.location_id, 'Location')}</td>
                            <td >{GetWorkStr(attributes, elm.discipline_id, 'Discipline')}</td>
                            <td >{GetCostCode(costComponent, costCodes, elm.cost_component_id, 'name')}</td>
                            <td >{GetCostCode(costComponent, costCodes, elm.cost_component_id, 'description')}</td>
                            <td >{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'cost_type')}</td>
                            <td >{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'unit_of_meas')}</td>
                            <td >{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'rate_of_uom')}</td>
                            <td >{GetCost(lists, elm._id)}</td>

                        </tr>
                    </>)
                } else if (id === elm.team_id) {
                    return (<>
                        <tr>
                            <td>{GetTeam(teamList, elm.team_id)}</td>
                            <td>{GetWorkStr(attributes, elm.location_id, 'Location')}</td>
                            <td>{GetWorkStr(attributes, elm.discipline_id, 'Discipline')}</td>
                            <td>{GetCostCode(costComponent, costCodes, elm.cost_component_id, 'name')}</td>
                            <td>{GetCostCode(costComponent, costCodes, elm.cost_component_id, 'description')}</td>
                            <td>{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'cost_type')}</td>
                            <td>{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'unit_of_meas')}</td>
                            <td>{GetCostCode(costComponent, costAttributes, elm.cost_component_id, 'rate_of_uom')}</td>
                            <td>{GetCost(lists, elm._id)}</td>

                        </tr>
                    </>)
                }
                return list
            }))
        }
        return list
    }

    const GetTeam = (lists, id) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.team.map((elm) => {
                if (elm._id === id) {
                    return (<>{elm.name}</>)
                }
                return (<></>)
            }))
        }
        return list
    }

    const GetWorkStr = (lists, id, tag) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.projectattribute.map((elm) => {
                if (tag === 'Discipline') {
                    if (elm._id === id) {
                        return (<>{elm.tag_name}</>)
                    }
                } else if (tag === 'Location') {
                    return (id.map((elms) => {
                        if (elm._id === elms.value) {
                            return (<>{elm.tag_name + ' '}</>)
                        }
                        return (<></>)
                    }))
                }
                return list
            }))
        }
        return list
    }
    const GetCostCode = (lists, attribute, id, tag) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.costcomponents.map((elm) => {
                if (elm._id === id) {
                    if (attribute.length > 0) {
                        if (tag === 'name' || tag === 'description') {
                            return (attribute.costcodes.map((elms) => {
                                if (elms.status === 1) {
                                    if (elm.cost_code === elms._id) {
                                        if (tag === 'name') {
                                            return (<>{elms.cost_code}</>)
                                        } else if (tag === 'description') {
                                            return (<>{elms.description}</>)
                                        }
                                    }
                                }
                                return (<></>)
                            }))
                        } else if (tag === 'cost_type' || tag === 'unit_of_meas') {
                            return (attribute.costattribute.map((elms) => {
                                if (elms.status === 1) {
                                    if (tag === 'cost_type') {
                                        if (elm.cost_type === elms._id) {
                                            return (<>{elms.tag_name}</>)
                                        }
                                    } else if (tag === 'unit_of_meas') {
                                        if (elm.cost_uom === elms._id) {
                                            return (<>{elms.tag_name}</>)
                                        }
                                    }
                                }
                                return (<></>)
                            }))
                        } else if (tag === 'rate_of_uom') {
                            return (<>{parseInt(elm.rate_per_uom).toLocaleString('en-US')}</>)
                        }
                    }
                }
                return list
            }))
        }
        return list
    }

    const DateList = () => {
        let lists = []
        for (let i = 0; i < 35; i++) {
            lists.push(i)
        }
        return (lists.map((elm) => {
            return (<><th className="track_mp_th" scope="col" >{Moment(new Date(Date.now() - (12096e5 - (86400000 * elm)))).format("DD/MM/YY")}</th></>)
        }))


    }

    const CostList = (costlists, id) => {
        let lists = []
        for (let i = 0; i < 35; i++) {
            lists.push(i)
        }
        let list = null
        if (costlists.length > 0) {
            return (list = costlists.costtrackmp.map((elm) => {
                if (id === '') {
                    return (<><tr>{lists.map((element) => {
                        return (<td className='track_mp_td'>{
                            costlists.costtrackmeta.map((elms) => {
                                let date = Moment(new Date(Date.now() - (1209600000 - (86400000 * element)))).format("DD MMM YYYY")
                                if (elm._id === elms.track_mp_id) {
                                    if (date === elms.dates) {
                                        return (<><span data-bs-toggle="modal" data-bs-target="#editprice" onClick={() => handleEdit(elms, elm)}>{elms.value}</span></>)
                                    }

                                }
                                return (<></>)
                            })
                        }</td>
                        )
                    })
                    }</tr></>)
                } else if (id === elm.team_id) {
                    return (<><tr>{lists.map((element) => {
                        return (<td className='track_mp_td'>{
                            costlists.costtrackmeta.map((elms) => {
                                let date = Moment(new Date(Date.now() - (1209600000 - (86400000 * element)))).format("DD MMM YYYY")
                                if (elm._id === elms.track_mp_id) {
                                    if (date === elms.dates) {
                                        return (<><span data-bs-toggle="modal" data-bs-target="#editprice" onClick={() => handleEdit(elms, elm)}>{elms.value}</span></>)
                                    } else {
                                        return (<></>)
                                    }

                                }
                                return (<></>)
                            })
                        }</td>
                        )
                    })
                    }</tr></>)
                }
                return list
            }))
        }

        return list
    }
    const GetCost = (costlists, id) => {
        let lists = []
        for (let i = 0; i < 35; i++) {
            lists.push(i)
        }
        let list = 0
        if (costlists.length > 0) {
            lists.map((element) => {
                costlists.costtrackmeta.map((elms) => {
                    let date = Moment(new Date(Date.now() - (1209600000 - (86400000 * element)))).format("DD MMM YYYY")
                    if (id === elms.track_mp_id) {
                        if (date === elms.dates) {
                            list = parseInt(list) + parseInt(elms.value)
                        }

                    }
                    return list
                })
                return list
            })

        }

        return list
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Track MP | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='CostTracker' show='CostTrackMP'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Track-MP</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Cost Tracker</li>
                                            <li className="breadcrumb-item active">Track-MP</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="trmp_righttable">
                                <div className="container-fluid">
                                    <div className="righttable-title mb-4">
                                        <div className="tabletile_left">
                                            <h5>Team</h5>
                                            <select className="form-select" aria-label="Default select example" name="team" value={values.team} onChange={handleChange}>
                                                <option value=''>Show all Teams</option>
                                                {TeamList(teamList)}
                                            </select>
                                        </div>
                                        <div className="tabletitle_icon">
                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#TeamAssociation">Add <i className="fas fa-plus"></i></button>
                                        </div>
                                    </div>
                                    <div className="trmp_maintable">
                                        <div className="trmp_scrltable table-responsive">
                                            <table className="table">
                                                <thead>

                                                    <tr>
                                                        <th scope="col">Team</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Discipline</th>
                                                        <th scope="col">Cost code</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Resource Type</th>
                                                        <th scope="col">Unit</th>
                                                        <th scope="col">Rates</th>
                                                        <th scope="col">Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {TrackList(costTrackMp, values.team)}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="trmp_scrltable table-responsive">
                                            <table className="table ">
                                                <thead>
                                                    <tr>
                                                        {DateList()}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {CostList(costTrackMp, values.team)}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>

            {/* Popup */}

            <div className="modal fade" id="TeamAssociation" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered TeamAssociation-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Cost Track MP</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className='row'>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Team</label>
                                            <select className="form-select" aria-label="Default select example" name='team_id' value={values.team_id} onChange={handleChange} >
                                                <option value=''>Select Team</option>
                                                {TeamList(teamList)}

                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Location</label>
                                            <ReactSelect
                                                options={LocationList(attributes)}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={handleSelectChange}
                                                allowSelectAll={true}
                                                value={values.location_id}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Discipline</label>
                                            <select className="form-select" aria-label="Default select example" name='discipline_id' value={values.discipline_id} onChange={handleChange} >
                                                <option value=''>Select Discipline</option>
                                                {WorkList(attributes)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Cost Code</label>
                                            <select className="form-select" aria-label="Default select example" name='cost_component_id' value={values.cost_component_id} onChange={handleChange} >
                                                <option value=''>Select Cost Code</option>
                                                {CostCodeList(costCodes, costComponent)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Calendar</label>
                                            <DatePicker
                                                selected={values.startDate}
                                                onChange={handleDateChange}
                                                maxDate={fortnightAway}
                                                minDate={lasttwoweek}
                                                name="startDate"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Cost value</label>
                                            <input type="number" className="form-control" name='cost_value' value={values.cost_value} onChange={handleChange} />
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleSubmit(values)} >Add Cost Track MP</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Popup */}
            <div className="modal fade" id="editprice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered TeamAssociation-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Amount({values.date})</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className='row'>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Team</label>
                                            <select className="form-select" aria-label="Default select example" name='team_id' value={values.team_id} disabled onChange={handleChange} >
                                                <option value=''>Select Team</option>
                                                {TeamList(teamList)}

                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Location</label>
                                            <ReactSelect
                                                options={LocationList(attributes)}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={handleSelectChange}
                                                allowSelectAll={true}
                                                value={values.location_id}
                                                isDisabled={true}
                                                className="form-select"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Discipline</label>
                                            <select className="form-select" aria-label="Default select example" name='project_id' value={values.project_id} disabled onChange={handleChange} >
                                                <option value=''>Select Discipline</option>
                                                {WorkList(attributes)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Cost Code</label>
                                            <select className="form-select" aria-label="Default select example" name='cost_component_id' disabled value={values.cost_component_id} onChange={handleChange} >
                                                <option value=''>Select Cost Code</option>
                                                {CostCodeList(costCodes, costComponent)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-lg-4 mt-3'>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Cost value</label>
                                            <input type="number" className="form-control" name='cost_value' value={values.cost_value} onChange={handleChange} />
                                        </div>
                                    </div>

                                </div>


                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleSaveEdit(values)}  >Edit Cost</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleClose()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CostTrackMP
