import React from 'react'
import Apis from '../../../api'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'

const ProjectApi = (callback) => {
    /* BAsic Varables */
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [values, setValues] = React.useState({
        user_id: user.id,
        user_name: user.name,
        id: '',
        plan_id: '',
        key: 'system_password',
        system_password_status: false,
        system_password: '',
        project_name: '',
        project_description: '',
        edit_card: 0,
        project_lock: 0,
        project_lock_status: '',
        plan_lock: 0,
        plan_lock_status: '',
        plan_phase: 0,
        project_id: '',
        plan_name: '',
        plan_description: '',
        work_packages: [],
        edit_plan_card: 0,
    })

    const [projectList, setProjectList] = React.useState([]);
    const [planList, setPlanList] = React.useState([]);
    const { attributes } = AttributeApiCall()
    const [work_package_list, setWorkPackage] = React.useState([])


    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        const raw = {
            'user_id': user.id
        }

        Apis.getProject(raw).then((res) => {
            setProjectList(res.data.data)
            window.localStorage.setItem('project-info', JSON.stringify(res.data.data))
        }).catch((error) => {
            console.log(error.response.data)
        })
    }, [])

    React.useEffect(() => {
        let demo
        let list = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === "Work Package") {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                return list
            })
            setWorkPackage(list)
        }
    }, [attributes])

    /* Basic Functions */


    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setValues({
            ...values,
            [name]: value,
        })
        setError({
            ...error,
            open: false
        })
    }
    /* Check System Password */
    const handleCheckSystemPassword = (formdata, event) => {
        setError({
            ...error,
            open: false
        })
        if (formdata.system_password === '') {
            setError({
                ...error,
                error: 'System Password is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                if (formdata.project_lock === 1) {
                    const raw = {
                        'id': formdata.id,
                        'user_id': formdata.user_id,
                        'key': formdata.key,
                        'system_password': formdata.system_password,
                        'is_locked': !formdata.project_lock_status
                    }
                    Apis.lockProject(raw).then((res) => {
                        setValues({
                            ...values,
                            system_password: '',
                            id: '',
                            project_lock: 0,
                            plan_phase: 0,
                        })
                        ProjectList()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                } else if (formdata.plan_lock === 1) {
                    const raw = {
                        'id': formdata.id,
                        'user_id': formdata.user_id,
                        'key': formdata.key,
                        'system_password': formdata.system_password,
                        'is_locked': !formdata.plan_lock_status
                    }
                    Apis.lockPlan(raw).then((res) => {
                        setValues({
                            ...values,
                            system_password: '',
                            id: '',
                            plan_lock: 0,
                        })
                        const formdatas = {
                            'user_id': formdata.user_id,
                            'project_id': formdata.project_id
                        }
                        PlanList(formdatas)
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }

            } else {
                const raw = {
                    'id': formdata.user_id,
                    'key': formdata.key,
                    'system_password': formdata.system_password
                }
                Apis.checkSystemInfo(raw).then((res) => {
                    setValues({
                        ...values,
                        system_password: '',
                        system_password_status: true,
                    })
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    /* Get Project */

    const ProjectList = () => {
        const raw = {
            'user_id': user.id
        }
        Apis.getProject(raw).then((res) => {
            setProjectList(res.data.data)
            window.localStorage.setItem('project-info', JSON.stringify(res.data.data))
        }).catch((error) => {
            console.log(error.response.data)
        })

    }

    /* Get Plan List */

    const PlanList = (formdata) => {
        const raw = {
            'user_id': formdata.user_id,
            'project_id': formdata.project_id
        }
        Apis.getPlan(raw).then((res) => {
            setPlanList(res.data.data)
        }).catch((error) => {
            console.log(error.response.data)
        })
    }

    /* Add Project */

    const handleProjectSubmit = (formdata, event) => {
        setError({
            ...error,
            open: false
        })
        if (formdata.project_name === '') {
            setError({
                ...error,
                error: 'Project name is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                console.log(formdata)
                const raw = {
                    'id': formdata.id,
                    'name': formdata.project_name,
                    'description': formdata.project_description,
                }
                Apis.updateProject(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        id: '',
                        project_name: '',
                        project_description: '',
                        edit_card: 0
                    })
                    ProjectList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const raw = {
                    'name': formdata.project_name,
                    'description': formdata.project_description,
                    'user_id': formdata.user_id
                }
                Apis.addProject(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        project_name: '',
                        project_description: '',
                    })
                    ProjectList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const EditProject = (elm) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            project_name: elm.name,
            project_description: elm.description,
            edit_card: 1,
        })

    }
    const CancelEditProject = (event) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: '',
            project_name: '',
            project_description: '',
            edit_card: 0,
            edit_plan_card: 0,
            plan_name: '',
            plan_description: '',
            work_packages: '',

        })
    }

    const LockProject = (elm) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            project_lock: 1,
            project_lock_status: elm.is_locked
        })
    }

    const LockPlane = (elm) => {
        console.log(elm)
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            plan_lock: 1,
            plan_lock_status: elm.is_locked
        })
    }

    /* Plan Functions */

    const ShowPlaneList = (elm) => {
        setError({
            ...error,
            open: false
        })

        if (values.plan_phase === 0) {
            setValues({
                ...values,
                project_id: elm._id,
                id: elm._id,
                project_name: '',
                project_description: '',
                edit_card: 0,

            })
            const formdata = {
                'user_id': values.user_id,
                'project_id': elm._id
            }
            PlanList(formdata)
        } else if (elm._id === values.project_id) {
            setValues({
                ...values,
                project_id: '',

            })
        } else {
            setValues({
                ...values,
                project_id: elm._id,
                id: elm._id,
                project_name: '',
                project_description: '',
                edit_card: 0,
            })
            const formdata = {
                'user_id': values.user_id,
                'project_id': elm._id
            }
            PlanList(formdata)
        }
    }
    const handleSelectChange = (selected) => {
        setValues({
            ...values,
            work_packages: selected
        })
    }

    const handlePlanSubmit = (formdata) => {
        setError({
            ...error,
            open: false
        })

        if (formdata.plan_name === '') {
            setError({
                ...error,
                error: 'Plan name is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.plan_id !== '') {

                const raw = {
                    'plan_id': formdata.plan_id,
                    'name': formdata.plan_name,
                    'description': formdata.plan_description,
                    'packages': formdata.work_packages
                }

                Apis.updatePlan(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        plan_name: '',
                        plan_description: '',
                        work_packages: [],
                        edit_plan_card: 0,
                        plan_id: ''
                    })
                    const formdatas = {
                        'user_id': formdata.user_id,
                        'project_id': formdata.project_id
                    }

                    PlanList(formdatas)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const raw = {
                    'project_id': formdata.project_id,
                    'name': formdata.plan_name,
                    'description': formdata.plan_description,
                    'user_id': formdata.user_id,
                    'packages': formdata.work_packages
                }

                Apis.addPlan(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        plan_name: '',
                        plan_description: '',
                        work_packages: [],

                    })
                    const formdatas = {
                        'user_id': formdata.user_id,
                        'project_id': formdata.project_id
                    }
                    PlanList(formdatas)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const EditPlans = (elm) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            plan_id: elm._id,
            plan_name: elm.name,
            plan_description: elm.description,
            work_packages: elm.packages,
            edit_plan_card: 1,
        })
    }



    return {
        error,
        values,
        projectList,
        planList,
        work_package_list,
        handleChange,
        handleCheckSystemPassword,
        handleProjectSubmit,
        EditProject,
        CancelEditProject,
        LockProject,
        ShowPlaneList,
        handleSelectChange,
        handlePlanSubmit,
        EditPlans,
        LockPlane,
        setError
    }
}

export default ProjectApi