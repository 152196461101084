import swal from 'sweetalert'
import React from 'react'
import Cookies from 'js-cookie'

const Logout = (callback) => {
    const [toggle, setToggle] = React.useState(true)

    const handleLogout = () => {
        swal({
            title: "Are you sure you want to log out?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((logout) => {
            if (logout) {
                Cookies.remove('login')
                localStorage.removeItem('user-info')
                localStorage.removeItem('verfication-token')
                document.cookie = "login=false;expires=Thu, 01 Jan 1970 00:00:00 UTC;"
                window.location.replace('/')
            }
        });
    }

    const toggleFullscreen = (event) => {
        if (
            !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    const toggleSidebar = (id, event) => {
        if (id === true) {
            document.body.classList.add('sidebar-enable')
            document.body.classList.add('vertical-collpsed')
            setToggle(false)
        } else {
            document.body.classList.remove('sidebar-enable')
            document.body.classList.remove('vertical-collpsed')
            setToggle(true)
        }

    }
    return {
        handleLogout,
        toggleFullscreen,
        toggleSidebar,
        toggle
    }
}

export default Logout
