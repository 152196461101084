import React from 'react'
import { default as ReactSelect } from "react-select"
import Modal from 'react-bootstrap/Modal'
import { components } from "react-select"

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const SECONDARY_OPTION_PREFIX = '$$'
const SECONDARY_SELECT_OPTIONS = {
    ALL: `${SECONDARY_OPTION_PREFIX}all`,
    ADD: `${SECONDARY_OPTION_PREFIX}add`,
    Status_ALl: {
        value: 2,
        label: 'Work in Process'
    },
}
const showAllOption = { value: SECONDARY_SELECT_OPTIONS.ALL, label: '-Show All-' }


const LapToolbar = ({ state = [], teams = [], members = [], sub_project = [], phase_plane = [], work_packages = [], status_codes = [], onChange, handleShow, attributes }) => {
    var teamsValue, subValues, phaseValue, workVAlue = SECONDARY_SELECT_OPTIONS.ALL
    var statusValue = SECONDARY_SELECT_OPTIONS.Status_ALl
    const [resourcesShow, setResourceShow] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const [location_pop, setLocationPopup] = React.useState(false)
    const [location_List, setLocationLists] = React.useState([])
    const [filters, setfilter] = React.useState({
        location: '',
        member: '',
    })
    const handleApplyFilter = (filters) => {
        setLocationPopup(false)

        onChange({
            name: 'location_id',
            value: filters.location,
        })
        onChange({
            name: 'member',
            value: filters.member,
        })
        if (filters.location.length === 0 && filters.member.length === 0) {
            setShow(false)
        } else {
            setShow(true)
        }



    }
    const handleClearFilter = () => {
        setfilter({
            location: '',
            member: '',
        })
        setShow(!show)
        onChange({
            name: 'location_id',
            value: '',
        })
        onChange({
            name: 'member',
            value: '',
        })

    }

    if (state['team']) {
        teamsValue = teams.find((i) => i.value === state['team'].value)
    }

    if (state['subproject']) {
        subValues = sub_project.find((i) => i.value === state['subproject'].value)
        if (state['subproject'].value === '$$all') {
            phaseValue = SECONDARY_SELECT_OPTIONS.ALL
            workVAlue = SECONDARY_SELECT_OPTIONS.ALL
        }
    }
    if (state['phase_plane']) {
        phaseValue = phase_plane.find((i) => i.value === state['phase_plane'].value)
        if (state['phase_plane'].value === '$$all') {
            workVAlue = SECONDARY_SELECT_OPTIONS.ALL
        }
    }
    if (state['work_packages']) {
        workVAlue = work_packages.find((i) => i.value === state['work_packages'].value)
    }
    if (state['status_code']) {
        statusValue = status_codes.find((i) => i.value === state['status_code'].value)
    }
    React.useEffect(() => {
        handleShow(resourcesShow)
    }, [resourcesShow])


    React.useEffect(() => {
        let demo
        let list = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === 'Location') {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name + ' (' + elm.handle + ')'
                    }
                    list.push(demo)
                }
                return list
            })
            setLocationLists(list)
        }
    }, [attributes])
    const handleChange = (option, params) => {
        if (option === null) {
            setfilter({
                ...filters,
                [params.name]: ''
            })
        } else {
            setfilter({
                ...filters,
                [params.name]: option
            })
        }

    }


    const onTeamChange = (option, params) => {
        if (typeof onChange !== 'function') return
        if (params.name === 'status_code') {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? SECONDARY_SELECT_OPTIONS.Status_ALl : option,
            })
        } else {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? SECONDARY_SELECT_OPTIONS.ALL : option,
            })
        }
        if (params.name === 'subproject') {
            onChange({
                name: 'phase_plane',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
            onChange({
                name: 'work_packages',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
        }
        if (params.name === 'phase_plane') {
            onChange({
                name: 'work_packages',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
        }
    }
    return (
        <>
            <div className='row'>
                <div className="col-2">
                    <div className="mb-2">
                        <label className="col-form-label">Sub Project</label>
                        <ReactSelect
                            options={[...sub_project]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={subValues ?? showAllOption}
                            name="subproject"
                            isClearable
                            onChange={onTeamChange}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="mb-2">
                        <label className="col-form-label">Phase Plan</label>
                        <ReactSelect
                            options={[...phase_plane]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={phaseValue ?? showAllOption}
                            isDisabled={phase_plane.length === 0 ? true : false}
                            name="phase_plane"
                            isClearable
                            onChange={onTeamChange}
                        />

                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Work Package</label>
                        <ReactSelect
                            options={[...work_packages]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={workVAlue ?? showAllOption}
                            isDisabled={work_packages.length === 0 ? true : false}
                            name="work_packages"
                            isClearable
                            onChange={onTeamChange}
                        />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Status Codes</label>
                        <ReactSelect
                            options={[...status_codes]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            name="status_code"
                            onChange={onTeamChange}
                            value={statusValue ?? showAllOption}
                        />

                    </div>
                </div>
                <div className="col-lg-2 p-0">
                    <div className="mb-2">


                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <label className="col-form-label">Filter</label>
                                <div className="row">
                                    <div className="col-lg-6"><button className='btn btn-primary' data-bs-toggle="tooltip" data-bs-placement="bottom" title="Location Filter" onClick={() => setLocationPopup(!location_pop)}><i className='mdi mdi-filter'></i></button></div>
                                    <div className="col-lg-6"> <button className='btn btn-danger' style={show ? { display: 'block' } : { display: 'none' }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clear Filter " onClick={handleClearFilter}><i className='fa fa-times'></i></button></div>
                                </div>

                            </div>

                            <div className="col-lg-6 d-flex justify-content-center align-items-center p-0 flex-column">
                                <label className="col-form-label px-2">Show Resources</label>
                                <input style={{ padding: '15px' }} className="form-check-input" type="checkbox" name="show_resources" onChange={() => setResourceShow(!resourcesShow)} id="flexCheckDefault" checked={resourcesShow} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Team</label>
                        <ReactSelect
                            options={[...teams]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            name="team"
                            isClearable
                            value={teamsValue ?? showAllOption}
                            onChange={onTeamChange}
                        />

                    </div>
                </div>
            </div>
            {/* LOcation filter popup */}

            <Modal show={location_pop} aria-labelledby="contained-modal-title-vcenter" onHide={() => setLocationPopup(!location_pop)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Filter </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className="col-sm-6">
                                    <div className='mb-2'>
                                        <label className="col-form-label">Location</label>
                                        <ReactSelect
                                            options={location_List}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            name="location"
                                            components={{
                                                Option
                                            }}
                                            onChange={handleChange}
                                            value={filters.location}
                                            allowSelectAll={true}
                                        />

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='mb-2'>
                                        <label className="col-form-label">Member</label>
                                        <ReactSelect
                                            options={[...members]}
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            name="member"
                                            isClearable
                                            value={filters.member ?? showAllOption}
                                            onChange={handleChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleApplyFilter(filters)} >Apply Filter</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setLocationPopup(!location_pop)}>Cancel</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default LapToolbar