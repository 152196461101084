import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <button {...props} ref={ref} color="primary" className="btn btn-primary float-end">
            Project Calender
        </button>
    )
})

const compareDateByYearMonthDate = (dateA, dateB) => {
    const a = new Date(dateA.getFullYear(), dateA.getMonth(), dateA.getDate()).getTime()
    const b = new Date(dateB.getFullYear(), dateB.getMonth(), dateB.getDate()).getTime()
    return a === b
}

const isNonWorkingDay = (date, workTimeDays = []) => {
    const day = date.getDay()
    return !workTimeDays[day]
}

const ProjectCalander = (
    {
        onSelect,
        onUnselect,
        onUnselectNonWorkingDay,
        onSelectNonWorkingDay,
        highlightDays = [],
        highlightWeeklyDays = {},
        disabled,
        ...datePickerProps
    }
) => {
    const [visibleMonthDate, setVisibleMonthDate] = React.useState(new Date())

    const datePickerRef = React.useRef()

    React.useEffect(() => {
        if (datePickerProps.open) return
        setVisibleMonthDate(new Date())
    }, [datePickerProps.open])

    const handleMonthChange = (date) => {
        setVisibleMonthDate(date)
    }

    const handleSelect = (date) => {
        if (isNonWorkingDay(date, highlightWeeklyDays.days)) {
            for (let i = 0; i < highlightWeeklyDays.excludeDays?.length; i++) {
                const isDateInExcludedDays = compareDateByYearMonthDate(date, highlightWeeklyDays.excludeDays[i])
                if (isDateInExcludedDays) {
                    if (typeof onSelectNonWorkingDay === 'function') onSelectNonWorkingDay(date, i)
                    return
                }
            }
            if (typeof onUnselectNonWorkingDay === 'function') onUnselectNonWorkingDay(date)
        } else {
            for (let i = 0; i < highlightDays.length; i++) {
                const isDateInHiglitedDays = compareDateByYearMonthDate(date, highlightDays[i])
                if (isDateInHiglitedDays) {
                    if (typeof onUnselect === 'function') onUnselect(date, i)
                    return
                }
            }
            if (typeof onSelect === 'function') onSelect(date)
        }
    }

    const highlitedDaysInVisibleMonth = (() => {
        if (!visibleMonthDate) return []
        const year = visibleMonthDate.getFullYear()
        const month = visibleMonthDate.getMonth()
        const firstDay = new Date(new Date(year, month, 1) - 14)
        const lastDay = new Date(year, month + 1, 14)
        const currentDate = new Date(firstDay)
        const highlitedDays = []
        while (currentDate <= lastDay) {
            const dayNumber = currentDate.getDay()            
            const isThisDayHighlited = !highlightWeeklyDays.days[dayNumber]
            const isDateInExcluded = highlightWeeklyDays.excludeDays?.some((date) =>
                compareDateByYearMonthDate(date, currentDate)
            )
            if (isThisDayHighlited && !isDateInExcluded) {
                highlitedDays.push(new Date(currentDate))
            }
            currentDate.setDate(currentDate.getDate() + 1)
        }
        return highlitedDays
    })()
    return (
        <DatePicker
            {...datePickerProps}
            ref={datePickerRef}
            highlightDates={[...highlightDays, ...highlitedDaysInVisibleMonth]}
            shouldCloseOnSelect={false}
            onSelect={handleSelect}
            onMonthChange={handleMonthChange}
            onChange={handleMonthChange}
            calendarStartDay={1}
            customInput={<CustomInput />}
            disabled={disabled}
        />
    )
}

export default ProjectCalander