import {
    Toolbar
} from '@bryntum/gantt';

/**
 * @module GanttToolbar
 */

/**
 * @extends Core/widget/Toolbar
 */

export default class GanttToolbar extends Toolbar {


    // Factoryable type name
    static get type() {
        return 'gantttoolbars';
    }

    static get $name() {
        return 'GanttToolbar';
    }

    // Called when toolbar is added to the Gantt panel
    set parent(parent) {
        super.parent = parent;

        const me = this;

        me.gantt = parent;
        me.styleNode = document.createElement('style');
        document.head.appendChild(me.styleNode);
    }

    get parent() {
        return super.parent;
    }

    static get configurable() {
        return {
            items: [
                {
                    type: 'buttonGroup',
                    items: [
                        {
                            ref: 'expandAllButton',
                            icon: 'b-fa b-fa-angle-double-down',
                            tooltip: 'Expand all',
                            onAction: 'up.onExpandAllClick'
                        },
                        {
                            ref: 'collapseAllButton',
                            icon: 'b-fa b-fa-angle-double-up',
                            tooltip: 'Collapse all',
                            onAction: 'up.onCollapseAllClick'
                        }
                    ]
                },
                {
                    type: 'buttonGroup',
                    items: [
                        {
                            type: 'button',
                            ref: 'backtoday',
                            text: 'Go To Todays Date',
                            tooltip: 'Go To Todays Date',
                            icon: 'b-fa-hourglass',
                            onAction: 'up.onBackTodayDate'

                        }
                    ]
                },

            ]
        };
    }

    /* Custom Filters */

    onExpandAllClick() {
        this.gantt.expandAll();
    }
    onCollapseAllClick() {
        this.gantt.collapseAll();
    }
    onBackTodayDate() {
        this.gantt.scrollToDate(new Date(), {
            block: 'center'
        })
    }
}


GanttToolbar.initClass();
