import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import AttributeApiCall from './AttributeApiCall'
import Alert from '../../../helpers/Alert'
import { SketchPicker } from 'react-color'
import LocationTable from './components/LocationTable'
import DisciplineTable from './components/DisciplineTable'


function ProjectTags() {
    const { attributes,
        values,
        error,
        state,
        popUp,
        handleChange,
        deleteAttribute,
        handleClick,
        handleColorChange,
        handleSubmitTag,
        handlePopUp,
        handleClose,
        handleEdit } = AttributeApiCall()

    const [title, setTitle] = React.useState('Location Tags')

    const styles = {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        colors: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${values.color}`,
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },

    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title={'Project Tags- ' + title + ' | P2IC'}></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='projectTags'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Project Tags</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Tags</li>
                                            <li className="breadcrumb-item active">{title}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="button-items">
                                                <button className={(title === 'Location Tags') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Location Tags')} >Location Tags</button>
                                                <button className={(title === 'Discipline Tags') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Discipline Tags')} >Discipline Tags</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className={(title === 'Discipline Tags') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Discipline Tags</h4>
                                                <button type="button" className="btn btn-primary" onClick={() => handlePopUp('Discipline')} data-bs-toggle="modal" data-bs-target="#Disciplane_Tags" data-bs-whatever="@mdo" >Add<i className="fas fa-plus"></i></button>
                                            </div>
                                            <div className="table-responsive">
                                                <DisciplineTable
                                                    data={attributes}
                                                    handleDelete={deleteAttribute}
                                                    handleEdit={handleEdit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="Disciplane_Tags" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered disciplanetags_modal">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Discipline Tag</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>

                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5">
                                                                <div className="mb-3">
                                                                    <label htmlFor="recipient-name" className="col-form-label">Discipline Tag</label>
                                                                    <input type="text" className="form-control" name='tag_name' value={values.tag_name} onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <div className="mb-3">
                                                                    <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                    <input type="text" className="form-control" name='handle' value={values.handle} onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <div>
                                                                    <label htmlFor="recipient-name" className="col-form-label">Color</label>
                                                                </div>
                                                                <div>
                                                                    <div style={styles.swatch} onClick={handleClick} >
                                                                        <div style={styles.colors} />
                                                                    </div>
                                                                    {state.show_color_picker && (
                                                                        <div style={styles.popover} >
                                                                            <div style={styles.cover} onClick={handleClick} />
                                                                            <SketchPicker
                                                                                color={values.color}
                                                                                onChangeComplete={handleColorChange} />
                                                                        
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-primary" data-bs-dismiss={(values.tag_name !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Discipline')}>{(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(title === 'Location Tags') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Location Tags</h4>
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loactionTag" data-bs-whatever="@mdo" onClick={() => handlePopUp('Location')}>Add<i className="fas fa-plus"></i></button>

                                            </div>
                                            <div className="table-responsive">
                                                <LocationTable
                                                    data={attributes}
                                                    handleDelete={deleteAttribute}
                                                    handleEdit={handleEdit}
                                                />

                                            </div>
                                        </div>
                                        <div id="loactionTag" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Location Tag</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form>

                                                            <div className="row align-items-center">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="recipient-name" className="col-form-label">Location Tag</label>
                                                                        <input type="text" name='loction_tag' onChange={handleChange} value={values.loction_tag} className="form-control" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                        <input type="text" className="form-control" name='location_handle' value={values.location_handle} onChange={handleChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.loction_tag !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Location')}> {(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default ProjectTags
