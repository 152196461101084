import React from 'react';
import { Link } from 'react-router-dom'

// Defines a simple button React component
export default class DemoButton extends React.Component {

    render() {
        if (this.props.text === 'AddTask') {
            return <button style={{ color: '#606263' }} className="btn btn-primary btn-sm" onClick={this.props.onClick} title="Add New Task"><i className="fas fa-plus" style={{ color: '#ffffff' }}></i></button>
        } else if (this.props.text === 'EditMeilstone') {
            return <a  style={{ color: '#606263' ,cursor:'pointer'}} onDoubleClick={this.props.onClick}>{this.props.name}</a>
        }
        else if (this.props.text === 'EditTask') {
            return <a  style={{ color: '#606263' ,cursor:'pointer'}} onDoubleClick={this.props.onClick} >{this.props.name}</a>
        }

    }
}
