import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import DataTable from 'react-data-table-component'
import Apis from '../../../../api'
import Moment from 'moment';
function convertArrayOfObjectsToCSV(array) {

    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);


    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;


    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr++;

        });
        result += lineDelimiter;

    });

    return result;

}


function downloadCSV(array) {

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);

    if (csv == null) return;

    const filename = 'Members-login-log' + new Date().toDateString() + '.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;

    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();

}

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;

const columns = [
    {
        name: 'Member',
        selector: row => row.name,
        sortable: true
    },
    {
        name: 'Member email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'IP Address',
        selector: row => row.ipAddress,
        sortable: true,
    },
    {
        name: 'Login date',
        selector: row => row.date,
        sortable: true,
    },
];

const RootCausesReportTable = ({ toDate, fromDate }) => {

    const [reason_task, setReasonTask] = useState([])
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        const filter = [{}]
        let demo

        if (fromDate) {
            demo = { login_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { login_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }

        const raw = {
            "filter": filter,
        }
        Apis.userLoginLog(raw).then((response) => {

            setTimeout(() => {
                DataTableDate(response.data.data)
            }, 1000);

        }).catch((error) => {
            console.log(error.response.data)
        })

        const DataTableDate = (userLog) => {

            const userLogs = userLog.log.reverse()

            let list = []
            userLogs.map((log, key) => {
                let demo = {
                    id: key + 1,
                    name: log.first_name + ' ' + log.last_name,
                    email: log.email,
                    ipAddress: log.ipAddress,
                    date: Moment(new Date(log.login_date)).format("DD MMM YYYY, h:mm a")
                }
                list.push(demo)
            })

            setReasonTask(list)
            setPending(false)
        }


    }, [fromDate, toDate])


    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(reason_task)} />, [reason_task]);

    return (
        <> <DataTable
            title="Member Login Logs"
            actions={actionsMemo}
            columns={columns}
            data={reason_task}
            progressPending={pending}
            pagination

        /></>
    )
}

export default RootCausesReportTable