import React, { forwardRef } from 'react'
import Select from 'react-select'
import styles from './styles.module.scss'
import { Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { Label } from 'reactstrap'


const SECONDARY_OPTION_PREFIX = '$$'

const SECONDARY_SELECT_OPTIONS = {
    ALL: `${SECONDARY_OPTION_PREFIX}all`,
    ADD: `${SECONDARY_OPTION_PREFIX}add`,
}

const TOOLBAR_FIELD_NAMES = {
    TEAMS: 'team',
    RESET: 'reset',
    ALL_DATES: 'all_dates',
    FROM_DATE: 'from_date',
    TO_DATE: 'to_date',
}

const customPlaceholderStyles = (provided) => ({ ...provided, fontSize: '0.8em' })
const customOptionStyles = (provided, state) =>
    state.value === SECONDARY_SELECT_OPTIONS.ALL
        ? { ...provided, color: 'hsl(0, 0%, 80%)', fontSize: '0.8em' }
        : provided

const customStyles = {
    placeholder: customPlaceholderStyles,
    option: customOptionStyles,
    control: (base) => ({
        ...base,
        height: 28,
        minHeight: 28,
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0 3px',
    }),
    clearIndicator: (styles) => ({
        ...styles,
        padding: '0 3px',
    }),
    valueContainer: (styles) => ({
        ...styles,
        height: 28,
    }),
}
const showAllOption = { value: SECONDARY_SELECT_OPTIONS.ALL, label: '-Show All-' }
const CustomDatePickerInput = forwardRef(({ value, ...props }, ref) => {
    const formattedValue = value
        ? new Date(value).getDate() + '/' + parseInt(new Date(value).getMonth() + 1) + '/' + new Date(value).getFullYear()
        : ''

    return <Input type="text" {...props} value={formattedValue} ref={ref} />
})



const AnalyticsToolbar = ({ teams = [], state = {}, disabled = {}, onChange, trendLine = false }) => {
    const teamsValue = teams.find((i) => i.value === state[TOOLBAR_FIELD_NAMES.TEAMS])

    const onTeamChange = (option, params) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: params.name,
            value: params.action === 'clear' ? SECONDARY_SELECT_OPTIONS.ALL : option.value,
        })
    }

    const onFromDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: TOOLBAR_FIELD_NAMES.FROM_DATE,
            value: date,
        })
    }

    const onToDateChange = (date) => {
        if (typeof onChange !== 'function') return
        onChange({
            name: TOOLBAR_FIELD_NAMES.TO_DATE,
            value: date,
        })
    }

    return (
        <div className='container-fluid'>
            <div className="row justify-content-between">
                <div className="col">
                    <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                        Team
                    </Label>
                    <Select
                        id="analytics-filter-panel-teams"
                        defaultValue={showAllOption}
                        value={teamsValue ?? showAllOption}
                        styles={customStyles}
                        placeholder="Filter Team..."
                        className={styles.ganttToolbarSelectField}
                        options={[showAllOption, ...teams]}
                        name={TOOLBAR_FIELD_NAMES.TEAMS}
                        onChange={onTeamChange}
                        isClearable
                        isDisabled={disabled[TOOLBAR_FIELD_NAMES.TEAMS]}
                    />
                </div>

                <div className="col">
                    <Label for="analytics-filter-panel-from-date" className={styles.ganttToolbarSelectLabel}>
                        From Date
                    </Label>
                    <DatePicker
                        id="analytics-filter-panel-from-date"
                        selected={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        onChange={onFromDateChange}
                        selectsStart
                        startDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        endDate={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        portalId="analytics-filter-panel-from-date-portal"
                        name={TOOLBAR_FIELD_NAMES.FROM_DATE}
                        disabled={disabled[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        customInput={<CustomDatePickerInput />}
                        showPopperArrow={false}


                    />
                </div>

                <div className="col">
                    <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                        To Date
                    </Label>
                    <DatePicker
                        id="analytics-filter-panel-to-date"
                        selected={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        onChange={onToDateChange}
                        selectsEnd
                        startDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        endDate={state[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        minDate={state[TOOLBAR_FIELD_NAMES.FROM_DATE]}
                        portalId="analytics-filter-panel-to-date-portal"
                        name={TOOLBAR_FIELD_NAMES.TO_DATE}
                        disabled={disabled[TOOLBAR_FIELD_NAMES.TO_DATE]}
                        customInput={<CustomDatePickerInput />}
                        showPopperArrow={false}

                    />
                </div>
                {trendLine ? <>
                    <div className="col-lg-2">
                        <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                            Show Trend Line
                        </Label>
                        <div className="col-lg-3 d-flex justify-content-end align-items-center p-0">
                            <input style={{ padding: '10px' }} className="form-check-input" type="checkbox" name="show_trendline" onChange={() =>
                                onChange({
                                    name: 'trend_line',
                                    value: !state['trend_line'],
                                })
                            } checked={state['trend_line']} />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <Label for="analytics-filter-panel-to-date" className={styles.ganttToolbarSelectLabel}>
                            Show Min & Max
                        </Label>
                        <div className="col-lg-3 d-flex justify-content-end align-items-center p-0">
                            <input style={{ padding: '10px' }} className="form-check-input" type="checkbox" name="show_min_max" onChange={() =>
                                onChange({
                                    name: 'min_max_line',
                                    value: !state['min_max_line'],
                                })
                            } checked={state['min_max_line']} />
                        </div>
                    </div>
                </> : <></>}

            </div>
        </div>
    )
}

export default AnalyticsToolbar
