import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader, EmptyTablePlaceholder
} from "react-bs-datatable";
const headers = [
    { title: '#', prop: 'id' },
    { title: 'Phase Plan', prop: 'tag_name', isFilterable: true, isSortable: true },
    { title: 'Description', prop: 'description', isFilterable: true, isSortable: true },
    {
        title: 'Work Package Tags', prop: 'packages', isFilterable: true, isSortable: true
    },
    { title: 'Action', prop: 'action' },

];

const PhasePlanTable = ({ plan_data, handleDelete, handleEdit }) => {
    const [planList, setPlanList] = React.useState([]);
    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 1;
        if (plan_data.length > 0) {
            const plans = plan_data.plans
            plans && plans.map((elm) => {
                if (elm.is_locked === true) {
                    demo = {
                        id: i,
                        tag_name: elm.name,
                        description: elm.description,
                        work_tag: <><div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                            {elm.packages && elm.packages.map((elms) => {
                                return (<><span class="badge bg-success">{elms.label}</span></>)
                            })}
                        </div></>,
                        packages: elm.packages && elm.packages.map((elms) => {
                            return elms.label + ','
                        })
                        ,
                        action: <><div className="d-flex addremove_btn">
                            <button className="btn btn-primary btn-sm" type="button" onClick={() => handleDelete(elm)}><i class="fas fas fa-lock-open"></i></button>
                        </div></>

                    }
                } else {
                    demo = {
                        id: i,
                        tag_name: elm.name,
                        description: elm.description,
                        work_tag: <><div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                            {elm.packages && elm.packages.map((elms) => {
                                return (<><span class="badge bg-success">{elms.label}</span></>)
                            })}
                        </div></>,
                        packages: elm.packages && elm.packages.map((elms) => {
                            return elms.label + ','
                        }),
                        action: <> <div className="d-flex addremove_btn">
                            <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={() => handleDelete(elm)}><i class="fas fa-lock"></i></button>
                        </div> </>

                    }
                }
                list.push(demo)
                i++
            })
        }
        setPlanList(list)
    }, [plan_data])
    return (
        <>
            <DatatableWrapper
                body={planList}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>

                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody>
                        {(rows) =>
                            rows.length === 0 ? (
                                <EmptyTablePlaceholder />
                            ) : (
                                rows.map((rowData, rowIdx) =>
                                    rowData.packages ? (
                                        <tr>
                                            <td >{rowData.id}</td>
                                            <td >{rowData.tag_name}</td>
                                            <td >{rowData.description}</td>
                                            <td >{rowData.work_tag}</td>
                                            <td >{rowData.action}</td>

                                        </tr>
                                    ) : (
                                        <tr>
                                            <td >{rowData.id}</td>
                                            <td >{rowData.tag_name}</td>
                                            <td >{rowData.description}</td>
                                            <td >{rowData.work_tag}</td>
                                            <td >{rowData.action}</td>
                                        </tr>
                                    )
                                )
                            )
                        }
                    </TableBody>
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >

                    </Col>
                </Row>
            </DatatableWrapper>
        </>
    );
};

export default PhasePlanTable;