import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
const headers = [
    { title: 'Team', prop: 'team', isFilterable: true, isSortable: true },
    { title: 'Member', prop: 'member', isFilterable: true, isSortable: true },
    { title: 'Role', prop: 'role', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' },

];




const AssociationTable = ({ data, teamList, memberList, handleDeleteAssociation, handleEditTeam }) => {
    const [associationrow, setAssociationList] = React.useState([])
    const TeamName = (id, lists) => {
        let name = undefined
        if (lists.length > 0) {
            return (name = lists.team.map((team) => {
                if (team._id === id) {
                    return team.name
                }
                return name
            }))
        }
        return name
    }
    const MemberName = (id, lists) => {
        let name = undefined
        if (lists.length > 0) {
            return (name = lists.member.map((member) => {
                if (member._id === id) {
                    return (member.first_name + ' ' + member.last_name)
                }
                return name
            }))
        }
        return name
    }
    React.useEffect(() => {
        let list = []
        let demo = null
        const associationList = data

        if (associationList.length > 0) {
            if (teamList.length > 0 && memberList.length > 0) {
                associationList.association.map((elm, index) => {
                    let team_name = TeamName(elm.team_id, teamList)
                    team_name = team_name.filter(function (element) {
                        return element !== undefined;
                    });
                    let mamber_name = MemberName(elm.member_id, memberList)
                    mamber_name = mamber_name.filter(function (element) {
                        return element !== undefined;
                    });
                    if (elm.status === 1) {
                        demo = {
                            id: index + 1,
                            team: team_name[0],
                            member: mamber_name[0],
                            role: elm.role,
                            status: false,
                            action: <>
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#TeamAssociation" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'association')}>
                                    <i className="fas fa-pencil-alt"></i>
                                </button>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteAssociation(elm._id, 0)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </>,
                        }
                    } else {
                        demo = {
                            id: index + 1,
                            team: team_name[0],
                            member: mamber_name[0],
                            role: elm.role,
                            status: 'line-through',
                            action: <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteAssociation(elm._id, 1)}><i className="fas fa-trash-restore"></i></button>,
                        }
                    }


                    list.push(demo)
                    return list
                })
            }
            setAssociationList(list)
        }

    }, [data, teamList, memberList])



    return (
        <>
            <DatatableWrapper
                body={associationrow}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody rowProps={(row) => ({
                        style: { textDecorationLine: row.status }
                    })} />
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >

                    </Col>
                </Row>
            </DatatableWrapper></>

    );
};

export default AssociationTable;