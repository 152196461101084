import React from 'react';
export default class Radio extends React.Component {
    render() {
        const RadioButton = (props) => {
            const TaskData = props.taskData
            const id = props.id
            const commit_id = props.commit_id
            let second_id = '';
            const status = props.status
            if (status === 1) {
                return (<>
                    <input
                        type="radio"
                        className="btn-check"
                        name={id}
                        id={id + "_success"}
                        value={props.markedAsCommit}
                        data-value="close"
                        data-task={id}
                        data-commit={commit_id}
                        onChange={props.onClick}
                        autoComplete="off"
                        checked={props.markedAsCommit ? true : false}
                    />
                    <label className="btn btn-outline-success" htmlFor={id + "_success"} style={{
                        borderRadius: '25px',
                        fontSize: '8px',
                        margin: '10px',
                        height: '25px',
                        width: '25px'
                    }}><i className="ti-check" style={{
                        fontSize: '11px',
                        margin: '-6px'
                    }}></i></label>

                    <input
                        type="radio"
                        className="btn-check"
                        name={id}
                        id={id + "_danger"}
                        data-value="open"
                        value={props.markedAsUnCommit}
                        onChange={props.onClick}
                        data-task={id}
                        data-commit={commit_id}
                        autoComplete="off"
                        checked={props.markedAsUnCommit ? true : false}
                    />
                    <label className="btn btn-outline-danger" htmlFor={id + "_danger"} style={{
                        borderRadius: '25px',
                        fontSize: '8px',
                        margin: '10px',
                        height: '25px',
                        width: '25px'
                    }}><i className="ti-close" style={{
                        fontSize: '11px',
                        margin: '-6px'
                    }}></i></label>
                </>)
            } else {
                return (TaskData.map((elm, index) => {
                    if (elm.id === id && elm.id !== second_id && elm.status_show === true) {
                        second_id = elm.id;
                        if (props.reason_code_id === '61e5aa3673ef5a540682b237') {
                            return (<>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={id}
                                    id={id + "_success"}
                                    value={props.markedAsCommit}
                                    data-task={id}
                                    data-commit={commit_id}
                                    data-value="close"
                                    autoComplete="off"
                                    checked={props.markedAsCommit ? true : false}
                                    readOnly={true}
                                />
                                <label className="btn btn-outline-success" htmlFor={id + "_success"} style={{
                                    borderRadius: '25px',
                                    fontSize: '8px',
                                    margin: '10px',
                                    height: '25px',
                                    width: '25px'
                                }}><i className="ti-check" style={{
                                    fontSize: '11px',
                                    margin: '-6px'
                                }}></i></label>

                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={id}
                                    id={id + "_danger"}
                                    value={props.markedAsUnCommit}
                                    data-value="open"
                                    onChange={props.onClick}
                                    data-task={id}
                                    data-commit={commit_id}
                                    autoComplete="off"
                                    checked={props.markedAsUnCommit ? true : false}
                                    readOnly={true}
                                />
                                <label className="btn btn-outline-danger" htmlFor={id + "_danger"} style={{
                                    borderRadius: '25px',
                                    fontSize: '8px',
                                    margin: '10px',
                                    height: '25px',
                                    width: '25px'
                                }}><i className="ti-close" style={{
                                    fontSize: '11px',
                                    margin: '-6px'
                                }}></i></label>
                            </>)
                        } else {
                            return (<>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={id}
                                    id={id + "_success"}
                                    value={props.markedAsCommit}
                                    data-task={id}
                                    data-commit={commit_id}
                                    data-value="close"
                                    onChange={props.onClick}
                                    autoComplete="off"
                                    checked={props.markedAsCommit ? true : false}
                                />
                                <label className="btn btn-outline-success" htmlFor={id + "_success"} style={{
                                    borderRadius: '25px',
                                    fontSize: '8px',
                                    margin: '10px',
                                    height: '25px',
                                    width: '25px'
                                }}><i className="ti-check" style={{
                                    fontSize: '11px',
                                    margin: '-6px'
                                }}></i></label>

                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={id}
                                    id={id + "_danger"}
                                    value={props.markedAsUnCommit}
                                    data-value="open"
                                    onChange={props.onClick}
                                    data-task={id}
                                    data-commit={commit_id}
                                    autoComplete="off"
                                    checked={props.markedAsUnCommit ? true : false}
                                />
                                <label className="btn btn-outline-danger" htmlFor={id + "_danger"} style={{
                                    borderRadius: '25px',
                                    fontSize: '8px',
                                    margin: '10px',
                                    height: '25px',
                                    width: '25px'
                                }}><i className="ti-close" style={{
                                    fontSize: '11px',
                                    margin: '-6px'
                                }}></i></label>
                            </>)
                        }

                    } else {
                        return (<></>)
                    }


                }))
            }
        }
        return (<>{RadioButton(this.props)}</>)

    }
}