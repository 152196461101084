import React from 'react'
import Header from '../../../Layout/Header'
import Sidebar from '../../../Layout/Sidebar'
import Footer from '../../../Layout/Footer'
import CollaboratorApi from '../../../ProjectCollaborator/CollaboratorApi'
import AnalyticsToolbar from '../../../Analytics/helpers/AnalyticsToolbar'
import RootCausesReportTable from './healper'
import { Row, Col, Button } from 'reactstrap'
import Card from 'react-bootstrap/Card';

function CommitmentReports() {
    const { teamList } = CollaboratorApi()
    const [teams, setTeams] = React.useState([])
    const [toolbarState, setToolbarState] = React.useState({})
    let teamsLoading = false
    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    const onResetToolbarState = () => setToolbarState({})
    /**********Filters*********/
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Commitment plan reports | P2IC'></Header>
                <Sidebar title='' show='AnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="container-fluid">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h6 className="page-title">Analytics & Reports</h6>
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item active">Analytics & Reports</li>
                                                <li className="breadcrumb-item active">Commitment plan reports</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <Row className='pr_tagsrow mb-3' >
                                    <Col className='col-lg-12'>
                                        <Row  >
                                            <Col className="col-lg-9">
                                                <AnalyticsToolbar
                                                    state={toolbarState}
                                                    teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                                                    onChange={onToolbarStateChange}
                                                    onReset={onResetToolbarState}
                                                />
                                            </Col>
                                            <Col className="col-lg-3">
                                                <div className='container-fluid'>
                                                    <Row className=" align-self-end justify-content-end">
                                                        <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                                            <Button
                                                                color="danger"
                                                                className="btn-primary btn waves-effect waves-light"
                                                                onClick={onResetToolbarState}
                                                            >
                                                                Reset
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='pr_tagsrow'>
                                    <Col className='col-lg-12'>
                                        <Row  >
                                            <Col className="col-lg-12">
                                                <Card >
                                                    <Card.Body >
                                                        <RootCausesReportTable
                                                            fromDate={toolbarState.from_date}
                                                            toDate={toolbarState.to_date}
                                                            teamId={toolbarState.team}
                                                            teams={teams}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="row">
                                    <Footer></Footer>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommitmentReports