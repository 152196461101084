import React, { useState, useRef, useEffect } from "react";
import { ganttConfig } from "./CapGanttConfig";
import {
  projectConfig,
  ganttResourcesConfig,
} from "../../../LAP/healper/GanttComponets/GanttConfig";
import { BryntumGantt, BryntumProjectModel } from "@bryntum/gantt-react";
import Apis from "../../../../../api";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'



const findAllTask = (arr, id) => {
  let list = [];
  let toTAsk = [];
  arr.map((elm, index) => {
    if (id === elm.fromTask) {
      let abc = list.find((a) => a === elm.fromTask);
      if (!abc) {
        list.push(elm.fromTask);
      }
      list.push(elm.toTask);
      toTAsk.push(elm.toTask);
    } else {
      let abc = toTAsk.find((elms) => elms === elm.fromTask);
      if (abc) {
        let xyz = list.find((elmm) => elmm === elm.toTask);
        if (!xyz) {
          list.push(elm.toTask);
        }
        toTAsk.push(elm.toTask);
      }
    }
  })
  return list;
};

const findInArrayNew = (arr, id) => {
  for (var i = 0; i < arr.length; i++) {
    var el = arr[i];
    if (el.id === id) {
      return i;
    }
  }
  return -1;
};

const findTaskInArray = (arr, id) => {
  for (var i = 0; i < arr.length; i++) {
    var el = arr[i];
    if (el.discipline_id === id) {
      return i;
    }
  }
  return -1;
};

const findTaskSegmentIndex = (arr, date) => {
  for (var i = 0; i < arr.length; i++) {
    var el = arr[i];
    if (el.task_start === date) {
      return i;
    }
  }
  return -1;
};

const CapGhanttAddChart = ({
  resourcesData,
  team,
  taskData,
  attributes,
  resources,
  resourcesShow,
  onChange,
  commitShows,
  setCommit_id,
  calanderData,
}) => {
  const [style, setStyle] = useState({
    display: "none",
  });
  const gantt = useRef();
  const project = useRef();
  const resourceproject = useRef();
  const resourcegantt = useRef();

  const [commit_id, setCommitId] = useState([]);
  const [dependencie_tasks, settaskDependencies] = useState([]);
  const [show_loader, setLoaderShow] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [resources_data, setResource] = useState([]);
  const rdependencies = [];
  const rassignments = [];
  const [calendars, setCalendars] = useState([
    {
      id: "general",
      name: "General",
      intervals: [
        {
          recurrentStartDate: "on Sat at 0:00",
          recurrentEndDate: "on Mon at 0:00",
          isWorking: false,
        },
      ],
      expanded: true,
    },
  ]);
  /* Reset data */
  React.useEffect(() => {
    setCommitId([]);
    settaskDependencies([]);
  }, [team, taskData]);

  /* Prepare resources and gantt chart calander data */

  React.useEffect(() => {
    const calendars = [
      {
        id: "general",
        name: "General",
        intervals: [
          {
            recurrentStartDate: "on Sat at 0:00",
            recurrentEndDate: "on Mon at 0:00",
            isWorking: false,
          },
        ],
        expanded: true,
      },
    ];
    var projectCalendarValues = calanderData.projectCalendarValues;
    if (projectCalendarValues.length !== 0) {
      projectCalendarValues.map((elm) => {
        let startDate = new Date(
          new Date(elm.date).setHours(0, 0, 0, 0)
        ).getTime();
        let endDate = new Date(
          new Date(elm.date).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000
        ).getTime();
        var demo = {
          endDate: new Date(endDate),
          startDate: new Date(startDate),
          isWorking: elm.isWorkingDay,
        };
        calendars[0].intervals.push(demo);
      });
    }

    setCalendars(calendars);
  }, [calanderData.projectCalendarValues]);

  /* Prepare resources gantt chart data */

  React.useEffect(() => {
    setResource([]);
    if (
      resourcesData.length !== 0 &&
      resources.length !== 0 &&
      attributes.length !== 0 &&
      resourcesShow
    ) {
      setLoaderShow(true);
      setTimeout(() => {
        let resourcelist = [];
        let demo = null;
        let teams = resources;
        let taskResources = resourcesData.taskResources;
        teams.map((team) => {
          demo = {
            id: team.id,
            name: team.name,
            startDate: new Date(),
            duration: 100,
            rollup: true,
            manuallyScheduled: false,
            expanded: true,
            eventColor: "#ffffff00",
            cls: "resources-header",
            children: [],
          };
          resourcelist.push(demo);
          return resourcelist;
        });
        taskResources.map((task, index) => {
          let startDate = new Date(
            new Date(task.startDate).setHours(0, 0, 0, 0)
          );
          const projectIndex = findInArrayNew(resourcelist, task.team.value);
          const discipline = task.discipline.value ? task.discipline : task.discipline[0]
          var taskResourceChilder = resourcelist[projectIndex] ? resourcelist[projectIndex].children : [];

          const taskIndex = findTaskInArray(taskResourceChilder, discipline.value);

          const eventColor = attributes.projectattribute.filter((attrib) => {
            if (attrib._id === discipline.value) {
              if (attrib.color) {
                return attrib.color
              } else {
                return "#8ee997"
              }
            }
          })

          if (taskIndex === -1) {
            let working = [];
            let array = [];
            var duration = task.duration;
            for (let i = 0; i < duration; i++) {
              working.push(task.crewsize);
              let array_demo = {
                duration: duration,
                task_start: new Date(
                  new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000
                ).setHours(0, 0, 0, 0),
              };
              array.push(array_demo);
            }
            let task_demo = {
              id: task._id,
              name: discipline.label,
              startDate: startDate,
              manuallyScheduled: true,
              expanded: false,
              team_id: task.team.value,
              discipline_id: discipline.value,
              hoursWorked: working,
              duration: duration,
              eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997',
              cls: "resources-header",
              task_array: array,
            };
            if (resourcelist[projectIndex]) {
              if (resourcelist[projectIndex].children.length === 0) {
                resourcelist[projectIndex].children.push(task_demo);
              } else {
                for (
                  let b = 0;
                  b < resourcelist[projectIndex].children.length;
                  b++
                ) {
                  resourcelist[projectIndex].children[b].duration =
                    parseInt(resourcelist[projectIndex].children[b].duration) +
                    parseInt(task.duration);
                  for (let c = 0; c < task.duration; c++) {
                    resourcelist[projectIndex].children[b].hoursWorked.push(0);
                    let array_demo = {
                      duration: task.duration,
                      task_start: new Date(
                        new Date(task.startDate).getTime() +
                        c * 24 * 60 * 60 * 1000
                      ).setHours(0, 0, 0, 0),
                    };
                    resourcelist[projectIndex].children[b].task_array.push(
                      array_demo
                    );
                  }
                }
                resourcelist[projectIndex].children.push(task_demo);
              }
            }

            return resourcelist;
          } else {
            const taskSegmentIndex = findTaskSegmentIndex(
              resourcelist[projectIndex].children[taskIndex].task_array,
              new Date(task.startDate).setHours(0, 0, 0, 0)
            );

            if (taskSegmentIndex === -1) {
              resourcelist[projectIndex].children[taskIndex].duration =
                parseInt(
                  resourcelist[projectIndex].children[taskIndex].duration
                ) + parseInt(task.duration);
              for (let i = 0; i < task.duration; i++) {
                resourcelist[projectIndex].children[taskIndex].hoursWorked.push(
                  task.crewsize
                );
                demo = {
                  duration: task.duration,
                  task_start: new Date(
                    new Date(task.startDate).getTime() + i * 24 * 60 * 60 * 1000
                  ).setHours(0, 0, 0, 0),
                };
                resourcelist[projectIndex].children[taskIndex].task_array.push(
                  demo
                );
              }
              for (
                let b = 0;
                b < resourcelist[projectIndex].children.length;
                b++
              ) {
                if (b !== taskIndex) {
                  resourcelist[projectIndex].children[b].duration =
                    parseInt(resourcelist[projectIndex].children[b].duration) +
                    parseInt(task.duration);
                  for (let a = 0; a < task.duration; a++) {
                    resourcelist[projectIndex].children[b].hoursWorked.push(0);
                    demo = {
                      duration: 1,
                      task_start: new Date(
                        new Date(task.startdurationDate).getTime() +
                        a * 24 * 60 * 60 * 1000
                      ).setHours(0, 0, 0, 0),
                    };
                    resourcelist[projectIndex].children[b].task_array.push(
                      demo
                    );
                  }
                }
              }
            } else {
              for (let d = 0; d < task.duration; d++) {
                if (
                  resourcelist[projectIndex].children[taskIndex].hoursWorked[
                  parseInt(d) + parseInt(taskSegmentIndex)
                  ]
                ) {
                  resourcelist[projectIndex].children[taskIndex].hoursWorked[
                    parseInt(d) + parseInt(taskSegmentIndex)
                  ] =
                    parseInt(
                      resourcelist[projectIndex].children[taskIndex]
                        .hoursWorked[parseInt(d) + parseInt(taskSegmentIndex)]
                    ) + parseInt(task.crewsize);
                } else {
                  resourcelist[projectIndex].children[
                    taskIndex
                  ].hoursWorked.push(task.crewsize);
                  resourcelist[projectIndex].children[taskIndex].duration =
                    parseInt(
                      resourcelist[projectIndex].children[taskIndex].duration
                    ) + parseInt(1);
                  demo = {
                    duration: 1,
                    task_start: new Date(
                      new Date(task.startDate).getTime() +
                      d * 24 * 60 * 60 * 1000
                    ).setHours(0, 0, 0, 0),
                  };
                  resourcelist[projectIndex].children[
                    taskIndex
                  ].task_array.push(demo);
                }
              }
            }
          }
          return resourcelist;
        });
        let resourcList = [];
        resourcelist.map((elm) => {
          if (elm.children.length !== 0) {
            resourcList.push(elm);
          }
          return resourcList;
        });
        setLoaderShow(false);
        setResource(resourcList);
      }, 2000);
    }
  }, [resourcesData, resources, attributes, resourcesShow]);

  /* Show and hide reasources gantt chart */

  React.useEffect(() => {
    if (resourcesShow) {
      setStyle({
        ...style,
        display: "block",
      });
    } else {
      setStyle({
        ...style,
        display: "none",
      });
    }
  }, [resourcesShow]);

  /* Sync gantt chart or resource chart */
  React.useEffect(() => {
    resourcegantt.current.instance.addPartner(gantt.current.instance);
  }, []);

  React.useEffect(() => {
    setCommit_id(commit_id);
  }, [commit_id]);

  /* Prepare gantt chart data */

  React.useEffect(() => {
    setCommitId([]);
    settaskDependencies([])
    if (taskData.project) {
      setTimeout(() => {
        if (taskData.close_commit === false) {
          let dependencie_task_list = [];
          let commit_id = [];
          let taskDatas = taskData.tasks;
          let dependencieData = taskData.link;
          let demo;
          taskDatas.map((task) => {
            dependencieData.map((elm) => {
              if (task._id === elm.fromTask) {
                var status_show_next = task.status_code[0].value === 1 ? true : false;
                status_show_next = task.markedAsCommit === true ? true : false;
                const dependencieTask = dependencieData.find(
                  (acc) => acc.toTask === elm.toTask && acc._id !== elm._id
                );

                if (dependencieTask) {
                  const markedAsCommit = taskDatas.find(a => a._id === dependencieTask.fromTask && a.markedAsCommit === false);
                  if (markedAsCommit) {
                    status_show_next = markedAsCommit.markedAsCommit;
                  }
                }
                demo = {
                  id: elm.toTask,
                  fromTask: elm.fromTask,
                  toTask: elm.toTask,
                  status_show: status_show_next,
                };

                dependencie_task_list.push(demo);
                return dependencie_task_list;
              }
            });
            if (task.markedAsCommit === true) {
              if (!commit_id.includes(task._id)) {
                commit_id.push(task._id);
              }
            }
            return dependencie_task_list;
          });

          if (commit_id.length !== 0) {
            commitShows({ value: false });
          } else {
            commitShows({ value: true });
          }

          setCommitId(commit_id);
          settaskDependencies(dependencie_task_list);
        }
      }, 100);
    }
  }, [taskData]);

  React.useEffect(() => {
    if (taskData.project && attributes.length !== 0) {
      const user = JSON.parse(localStorage.getItem('user-info'))
      setLoaderShow(true);
      setTimeout(() => {
        let list = [];
        let demo = null;
        let projects = taskData.project;
        let milestones = taskData.milestones;
        let tasks = taskData.tasks;
        let keycount = [];
        projects.map((project) => {
          const location = project.location_id.map((loc) => {
            return loc.label;
          });
          demo = {
            id: project._id,
            type: project.type,
            name: project.name,
            startDate: new Date(
              new Date(project.startDate).setHours(0, 0, 0, 0)
            ),
            taskrefdate: new Date(project.complition_date).setHours(0, 0, 0, 0),
            isCompletedSuccessfully: project.isCompletedSuccessfully,
            subproject: project.subproject,
            phase_plane: project.phase_plane,
            work_packages: project.work_packages,
            location_id: project.location_id,
            location_name: location.toString(),
            status_code: project.status_code,
            wbs_code: project.wbs_code,
            team: project.team,
            member: project.member,
            duration: 0,
            nextindex: 0,
            rollup: true,
            expanded: commit_id.length === 0 ? true : true,
            crewsize: project.crewsize,
            discipline: project.discipline,
            manuallyScheduled: false,
            inactive: false,
            eventColor: "#8ee997",
            markedAsCommit: project.markedAsCommit,
            children: [],
            user_role: user.role
          };
          list.push(demo);
          demo = { key: 0 };
          keycount.push(demo);
          return list;
        });
        projects.map((project, key) => {
          milestones.map((milestone) => {
            if (milestone.parent === project._id) {
              tasks.map((task) => {

                if (task.parent === milestone._id) {
                  let startDate = new Date(
                    new Date(task.startDate).setHours(0, 0, 0, 0)
                  );
                  let working = [];
                  for (let i = 0; i < task.duration; i++) {
                    working.push(task.crewsize);
                  }
                  const discipline = task.discipline.value ? task.discipline : task.discipline[0]
                  const eventColor = attributes.projectattribute.filter((attrib) => {
                    if (attrib._id === discipline.value) {
                      if (attrib.color) {
                        return attrib.color
                      } else {
                        return "#8ee997"
                      }
                    }
                  })
                  const location = project.location_id.map((loc) => {
                    return loc.label;
                  });


                  demo = {
                    id: task._id,
                    type: task.type,
                    name: task.name,
                    startDate: startDate,
                    taskrefdate: task.complition_date,
                    isCompletedSuccessfully: task.isCompletedSuccessfully,
                    subproject: task.subproject,
                    phase_plane: task.phase_plane,
                    work_packages: task.work_packages,
                    location_id: task.location_id,
                    location_name: location.toString(),
                    status_code: task.status_code,
                    wbs_code: task.wbs_code,
                    team: task.team,
                    member: task.member,
                    duration: task.duration,
                    rollup: true,
                    manuallyScheduled:
                      task.status_code[0].label === "Released" ||
                        task.status_code[0].label === "Complete" ||
                        task.status_code[0].value === 4
                        ? true
                        : false,
                    crewsize: task.crewsize,
                    discipline: task.discipline,
                    percentDone: task.work_done,
                    inactive:
                      task.status_code[0].value === 5 ||
                        task.status_code[0].value === 6
                        ? true
                        : false,
                    nextindex: 0,
                    hoursWorked: working,
                    eventColor: eventColor[0].color ? eventColor[0].color : '#8ee997',
                    cls: "important",
                    markedAsCommit: task.markedAsCommit,
                    user_role: user.role
                  };

                  list[key].children.push(demo);
                  if (task.markedAsCommit === true) {
                    list[key].expanded = true;
                  }
                  let count = keycount[key].key + 1;
                  keycount[key].key = count;
                }
                return list;
              });
            }
            return list;
          });
          return list;
        });
        projects.map((project, key) => {
          milestones.map((milestone) => {
            if (milestone.parent === project._id) {
              const location = project.location_id.map((loc) => {
                return loc.label;
              });
              demo = {
                id: milestone._id,
                type: milestone.type,
                name: milestone.name,
                startDate: new Date(
                  new Date(milestone.startDate).setHours(0, 0, 0, 0)
                ),
                taskrefdate: new Date(milestone.complition_date).setHours(0, 0, 0, 0),
                isCompletedSuccessfully: milestone.isCompletedSuccessfully,
                subproject: milestone.subproject,
                phase_plane: milestone.phase_plane,
                work_packages: milestone.work_packages,
                location_id: milestone.location_id,
                location_name: location.toString(),
                status_code: milestone.status_code,
                wbs_code: milestone.wbs_code,
                team: milestone.team,
                member: milestone.member,
                duration: 0,
                rollup: true,
                manuallyScheduled:
                  milestone.status_code[0].label === "Released" ? true : false,
                crewsize: milestone.crewsize,
                discipline: milestone.discipline,
                percentDone: milestone.work_done,
                inactive:
                  milestone.status_code[0].value === 5 ||
                    milestone.status_code[0].value === 6
                    ? true
                    : false,
                nextindex: keycount[key].key,
                eventColor: null,
                markedAsCommit: milestone.markedAsCommit,
                user_role: user.role
              };
              list[key].children.push(demo);
            }
            return list;
          });
          return list;
        });
        setTasks([]);
        setTasks(list);
        setDependencies([]);

        let dependenciesList = [];
        const dependencies = taskData.link;
        dependencies.map((elm) => {
          demo = {
            id: elm._id,
            fromTask: elm.fromTask,
            toTask: elm.toTask,
            lag: elm.lag,
          };
          dependenciesList.push(demo);
          return dependenciesList;
        });
        setDependencies(dependenciesList);
        setLoaderShow(false);
      }, 2000);
    } else {
      setTasks([]);
      setDependencies([]);
      settaskDependencies([]);
    }
  }, [taskData, attributes]);

  const handleChanges = async (event) => {
    const id = event.target.getAttribute("data-task");
    const checked = event.target.checked;
    const list = findAllTask(dependencie_tasks, id);


    if (checked === true) {
      const raw = {
        id: id,
        value: true,
        timeStamp: new Date().getTime()
      };
      updateTask(raw);
    } else if (checked === false) {
      const raw = {
        id: id,
        value: false,
        timeStamp: new Date().getTime()
      };
      updateTask(raw);

      list.map((elm) => {
        if (elm !== id) {
          const raw = {
            id: elm,
            value: false,
            timeStamp: new Date().getTime()
          };
          updateTask(raw);
        }
        return true;
      });
    }
    setLoaderShow(true);
    setTimeout(() => {
      onChange();
    }, 1000);
  };

  /* APi Calls */
  const updateTask = (raw) => {
    Apis.updateCommitmentTask(raw)
      .then((res) => {
        return true;
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ bgcolor: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show_loader}
      />
      <Snackbar
        open={show_loader}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#308fe8',

          }}
          size={30}
          thickness={4}
        />
      </Snackbar>
      <BryntumProjectModel
        ref={project}
        {...projectConfig}
        tasks={tasks}
        calendars={calendars}
        assignments={assignments}
        dependencies={dependencies}
        resources={resources}
      />
      <BryntumGantt
        ref={gantt}
        {...ganttConfig}
        project={project}
        extraData={{ change: handleChanges, taskDep: dependencie_tasks }}
      />

      <div class="gant_chart_resource" style={style}>
        <BryntumProjectModel
          ref={resourceproject}
          {...projectConfig}
          tasks={resources_data}
          calendars={calendars}
          assignments={rassignments}
          dependencies={rdependencies}
        />
        <BryntumGantt
          ref={resourcegantt}
          {...ganttResourcesConfig}
          project={resourceproject}
        />
      </div>
    </>
  );
};

export default CapGhanttAddChart;
