import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ProjectCalendar from './ProjectCalander'


const EditableProjectCalendar = ({
    days = [],
    isLoading = false,
    disabled = false,
    editMode = false,
    onEditStart,
    onSave,
    onCancel,
    className = '',
    defaultValues = [],
}) => {
    const initialDays = useMemo(() => {
        return defaultValues.reduce(
            (acc, day) => {
                if (day.isWorkingDay) acc.workingDays.push(new Date(day.date))
                if (!day.isWorkingDay) acc.nonWorkingDays.push(new Date(day.date))
                return acc
            },
            {
                nonWorkingDays: [],
                workingDays: [],
            }
        )
    }, [defaultValues])

    const [nonWorkinDays, setNonWorkinDays] = useState([])
    const [workingDays, setWorkingDays] = useState([])
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    useEffect(() => {
        setNonWorkinDays(initialDays.nonWorkingDays)
    }, [initialDays.nonWorkingDays])

    useEffect(() => {
        setWorkingDays(initialDays.workingDays)
    }, [initialDays.workingDays])

    const handleSelect = (date) => {
        setNonWorkinDays((prev) => [...prev, date])
    }

    const handleUnselect = (_date, index) => {
        setNonWorkinDays((prev) => prev.filter((_, i) => i !== index))
    }

    const handleSelectNonWorkingDay = (_date, index) => {
        setWorkingDays((prev) => prev.filter((_, i) => i !== index))
    }

    const handleUnselectNonWorkingDay = (date) => {
        setWorkingDays((prev) => [...prev, date])
    }

    const handleEdit = () => {
        if (!editMode && typeof onEditStart === 'function') {
            onEditStart()
            setIsCalendarOpen(true)
        }
        if (editMode && typeof onSave === 'function') {
            const data = [
                ...workingDays.map((date) => ({ date, isWorkingDay: true })),
                ...nonWorkinDays.map((date) => ({ date, isWorkingDay: false })),
            ]
            onSave(data)
        }
    }

    const handleCancel = () => {
        setNonWorkinDays([])
        setWorkingDays([])
        if (typeof onCancel === 'function') onCancel()
    }

    const handleCalendarClose = () => setIsCalendarOpen(false)
    const handleCalendarOpen = () => setIsCalendarOpen(true)
    const handleCalendarClickOutside = () => setIsCalendarOpen(false)

    return (
        <div className='float-end' >
            <div className="mr-1 float-start">
                <ProjectCalendar
                    highlightWeeklyDays={{                       
                        days,
                        excludeDays: workingDays,
                    }}
                    highlightDays={nonWorkinDays}
                    onSelect={handleSelect}
                    onUnselect={handleUnselect}
                    onSelectNonWorkingDay={handleSelectNonWorkingDay}
                    onUnselectNonWorkingDay={handleUnselectNonWorkingDay}
                    disabled={disabled || !editMode}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    onClickOutside={handleCalendarClickOutside}
                    open={isCalendarOpen}
                />
            </div>

            <button onClick={handleEdit} disabled={disabled} color="primary" className="btn btn-primary mx-1 float-end ">
                {isLoading ? (
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                ) : editMode ? (
                    <span className="mdi mdi-check-outline" aria-hidden="true" />
                ) : (
                    <span className="mdi mdi-pencil" aria-hidden="true" />
                )}
            </button>

            {editMode && (
                <button onClick={handleCancel} disabled={disabled} color="secondary" className="btn btn-primary float-end mx-1">
                    <span className="mdi mdi-window-close" aria-hidden="true" />
                </button>
            )}
        </div>
    )
}

EditableProjectCalendar.propTypes = {
    workingDays: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
    onEditStart: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string,
    initialValues: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.instanceOf(Date),
            isWorkingDay: PropTypes.bool,
        })
    ),
}

export default EditableProjectCalendar
