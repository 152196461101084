import React from 'react';
export default class Button extends React.Component {
    render() {
        const ButtonRender = (props) => {
            const TaskData = props.taskData
            const id = props.id
            let second_id = '';
            const status = props.status

            if (status === 1) {
                return <>
                    <input
                        type="checkbox"
                        className="btn-check"
                        data-task={id}
                        value={props.markedAsCommit}
                        onChange={props.onClick}
                        id={id}
                        autoComplete="off"
                        checked={props.markedAsCommit} />
                    <label className="btn btn-outline-success" htmlFor={id} style={{
                        borderRadius: '25px',
                        fontSize: '8px',
                        margin: '10px',
                        height: '25px',
                        width: '25px'
                    }}><i className="ti-check" style={{
                        fontSize: '11px',
                        margin: '-6px'
                    }}></i></label>
                </>
            } else {

                return (TaskData.map((elm, index) => {
                    if (elm.id === id && elm.id !== second_id) {                      
                        second_id = elm.id;
                        if (elm.status_show) {
                            return <>
                                <input
                                    type="checkbox"
                                    className="btn-check"
                                    data-task={id}
                                    value={props.markedAsCommit}
                                    onChange={props.onClick}
                                    id={id}
                                    autoComplete="off"
                                    checked={props.markedAsCommit} />
                                <label className="btn btn-outline-success" htmlFor={id} style={{
                                    borderRadius: '25px',
                                    fontSize: '8px',
                                    margin: '10px',
                                    height: '25px',
                                    width: '25px'
                                }}><i className="ti-check" style={{
                                    fontSize: '11px',
                                    margin: '-6px'
                                }}></i></label>
                            </>
                        }

                    } else {
                        return <></>
                    }

                }))
            }

        }
        return (<>{ButtonRender(this.props)}</>)

    }
}