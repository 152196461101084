import React from 'react'
import GanttComponts from './GanttComponts'
import Apis from '../../../../api'



const GanttChart = ({ team, location_id, subproject, phase_plane, work_packages, status_code, calanderData }) => {

    const [taskData, setTaskData] = React.useState([])

    React.useEffect(() => {
        let filters = [
            { type: 'Task' },
            { delete_status: false }
        ]
        let demo = null
        if (team && team !== '$$all') {
            demo = { team: team }
            filters.push(demo)
        }
        if (subproject && subproject !== '$$all') {
            demo = { subproject: subproject }
            filters.push(demo)
        }
        if (phase_plane && phase_plane !== '$$all') {
            demo = { phase_plane: phase_plane }
            filters.push(demo)
        }
        if (work_packages && work_packages !== '$$all') {
            demo = { work_packages: work_packages }
            filters.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }
        const raw = {
            filters: filters,
            status_code: status_code
        }
        Apis.taskVarianceFilter(raw).then((res) => {
            setTaskData(res.data)
        }).catch((error) => {
            console.log(error.response.data)
        })


    }, [team, subproject, phase_plane, work_packages, status_code, location_id])
    return (
        <>
            <GanttComponts
                taskData={taskData}
                calanderData={calanderData}
            />
        </>
    )
}

export default GanttChart