import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import DataTable from 'react-data-table-component'
import Apis from '../../../../../../api';
import Moment from 'moment';
import { Link } from 'react-router-dom'

const columns = [
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true
    },
    {
        name: 'Team',
        selector: row => row.team,
        sortable: true,
    },
    {
        name: 'Link of PDF',
        selector: row => row.link,
        sortable: false,
    },
];

const RootCausesReportTable = ({ teamId, toDate, fromDate, teams }) => {

    const [reason_task, setReasonTask] = useState([])
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        const filter = [{ is_active: false }]
        let filters = [{ type: 'Task' }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = { team_id: teamId }
            filter.push(demo)

        }
        if (fromDate) {
            demo = { closing_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { closing_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }

        const raw = {
            "filter": filter,
            "filters": filters
        }
        Apis.getAnalytice(raw).then((response) => {
            setTimeout(() => {
                DataTableDate(response.data)
            }, 1000);

        }).catch((error) => {
            console.log(error.response.data)
        })

        const DataTableDate = (taskdata) => {

            const commit_data = taskdata.commit.reverse()
            const tasks = taskdata.tasks
            let list = []
            commit_data.map((commit, key) => {

                const index = FindArrIndex(list, commit.closing_date)
                let task_index = FindArryIndex(tasks, commit.task_id)

                if (task_index !== -1) {
                    if (index === -1) {

                        let demo = {
                            id: key + 1,
                            team: teams[FindArryIndex(teams, commit.team_id)].name,
                            date: Moment(new Date(commit.closing_date)).format("Do MMM YYYY"),
                            closeing_date: commit.closing_date,
                            link: <><Link to="#" onClick={() => DownloadPdf(commit.closing_date, commit.team_id)}><i className="fas fa-file-pdf" style={{ fontSize: '30px', color: '#626ed4' }}></i></Link></>
                        }
                        list.push(demo)
                    }
                }


                return list
            })

            setReasonTask(list)
            setPending(false)
        }


    }, [fromDate, teamId, toDate, teams])
    const FindArrIndex = (arr, id) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el.closeing_date === id) {
                return i
            }
        }
        return -1
    }

    const FindArryIndex = (arr, id) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el._id === id) {
                return i
            }
        }
        return -1
    }


    const DownloadPdf = (date, team_id) => {
        const user = JSON.parse(localStorage.getItem('user-info'))

        const raw = {
            "closing_date": date,
            "team_id": team_id,
            "team": teams[FindArryIndex(teams, team_id)].name,
            "name": user.name,
            "time": new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).getTime()
        }
        Apis.getCommitmentReport(raw).then((response) => {
            console.log(response.data.url)
            window.open(response.data.url, "_blank");

        }).catch((error) => {
            console.log(error.response.data)
        })
    }


    return (
        <> <DataTable
            columns={columns}
            data={reason_task}
            progressPending={pending}
            pagination

        /></>
    )
}

export default RootCausesReportTable