import React from 'react';
import Apis from '../../../../../../api';
export default class Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reasonCode: [],
            reason_code_id: props.reason_code_id
        };

    }
    componentDidMount() {
        this.loadCommitHistory();
    }

    loadCommitHistory() {
        Apis.reasonCodes().then((res) => {
            var response = res.data.data
            this.setState({ reasonCode: response.reason_codes })

        }).catch((error) => {
            console.log(error.response)
        })
    }
    render() {
        const { reasonCode } = this.state;
        const handleReasonSelete = (event) => {
            const value = event.target.value
            const id = event.target.getAttribute("data-id")
            const reason_code_id = event.target.getAttribute("data-reason_code_id")
            const raw = {
                "id": id,
                "markedAsCommit": false,
                "markedAsUnCommit": true,
                "reason_code_id": value,
                "reason_description": '',
                "refresh": true,
                "timeStamp": new Date().getTime()
            }
            if (reason_code_id !== '61e5aa3673ef5a540682b237') {
                this.setState({ reason_code_id: value })
                this.props.updateTask(raw)
            }
            return true

        }


        const ReasonSelect = (props, reasonCode) => {
            if (props.markedAsUnCommit) {
                return (<>
                    <select className="form-select"
                        aria-label="Default select example"
                        onChange={handleReasonSelete}
                        data-id={props.id}
                        data-reason_code_id={props.reason_code_id}
                        value={this.state.reason_code_id}
                    >
                        <option defaultValue>Select Reason Code</option>
                        {reasonCode && reasonCode.map((elm) => {
                            if (elm.is_active) {
                                return (
                                    <option value={elm._id}>{elm.reason}</option>
                                )
                            }

                        })}
                    </select></>)
            }



        }
        return (<>{ReasonSelect(this.props, reasonCode)}</>)
    }
}