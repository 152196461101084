import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import Alert from '../../../helpers/Alert'
import CostCodesApi from './CostCodesApi'

function CostCodes() {
    const { costCodes,
        values,
        error,
        handleChange,
        handleSubmitCodes,
        handleEdit,
        deleteCode
    } = CostCodesApi()

    const CodesLists = (lists) => {

        let list = null
        if (lists.length > 0) {
            return (list = lists.costcodes.map((elm, index) => {

                if (elm.status === 1) {
                    return (
                        <>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <div>{elm.cost_code}</div>
                                </td>
                                <td>
                                    <div>{elm.description}</div>
                                </td>
                                <td>
                                    <div>{"$ " + parseInt(elm.total_budget).toLocaleString()}</div>
                                </td>
                                <td><div>{elm.current_spend}</div></td>
                                <td>
                                    <div className="d-flex addremove_btn">
                                        <button type="button" data-bs-target="#costCodesPackage" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                        <button onClick={() => deleteCode(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" ><i className="fas fa-trash-alt"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    )
                } else {
                    return (
                        <>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <strike>  <div>{elm.cost_code}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{elm.description}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{"$ " + parseInt(elm.total_budget).toLocaleString()}</div></strike>
                                </td>
                                <td>
                                    <strike>  <div>{elm.current_spend}</div></strike>
                                </td>
                                <td>
                                    <div className="d-flex addremove_btn">

                                        <button onClick={() => deleteCode(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" ><i class="fas fa-trash-restore"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    )
                }
            }))
        }
        return list

    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Cost Codes | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='CostTracker' show='CostCodes'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Cost Codes</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Cost Tracker</li>
                                            <li className="breadcrumb-item active">Cost Codes</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Cost Codes</h4>
                                                        <button type="button" data-bs-target="#costCodesPackage" className="btn btn-primary" data-bs-toggle="modal" data-bs-whatever="@mdo" >Add<i className="fas fa-plus"></i></button>
                                                        <div id="costCodesPackage" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Cost Codes</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Cost Codes</label>
                                                                                        <input type="text" name='cost_code' onChange={handleChange} value={values.cost_code} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Total Budget</label>
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-text">$</div>
                                                                                            <input type="number" className="form-control" name='total_budget' value={values.total_budget} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Description</label>
                                                                                        <textarea className="form-control" name='description' value={values.description} onChange={handleChange} row="6" ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.work_tag !== '') ? "modal" : ""} onClick={() => handleSubmitCodes(values)}> {(values.id !== '') ? 'Update Code' : 'Add Code'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Cost Codes</th>
                                                                    <th scope="col">Description</th>
                                                                    <th scope="col">Total Budget</th>
                                                                    <th scope="col">Current Spend</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {CodesLists(costCodes)}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
        </>
    )
}

export default CostCodes