import {
    Toolbar
} from '@bryntum/gantt';

/**
 * @module GanttToolbar
 */

/**
 * @extends Core/widget/Toolbar
 */

export default class GanttToolbar extends Toolbar {


    // Factoryable type name
    static get type() {
        return 'gantttoolbarss';
    }

    static get $name() {
        return 'GanttToolbar';
    }

    // Called when toolbar is added to the Gantt panel
    set parent(parent) {
        super.parent = parent;

        const me = this;

        me.gantt = parent;
        me.styleNode = document.createElement('style');
        document.head.appendChild(me.styleNode);
    }

    get parent() {
        return super.parent;
    }

    static get configurable() {

        const items = [];
        const user = JSON.parse(localStorage.getItem('user-info'))

        if (user?.role !== 'member') {
            
            items.push({
                type: 'buttonGroup',
                items: [
                    {
                        type: 'button',
                        color: 'b-green',
                        ref: 'addTaskButton',
                        icon: 'b-fa b-fa-plus',
                        text: 'Milestone',
                        tooltip: 'Milestone',
                        dataset: {
                            bsTarget: "#confirmPassword",
                            bsToggle: "modal"
                        }
                    }
                ]
            })
        }

        items.push(
            {
                type: 'buttonGroup',
                items: [
                    {
                        ref: 'expandAllButton',
                        icon: 'b-fa b-fa-angle-double-down',
                        tooltip: 'Expand all',
                        onAction: 'up.onExpandAllClick'
                    },
                    {
                        ref: 'collapseAllButton',
                        icon: 'b-fa b-fa-angle-double-up',
                        tooltip: 'Collapse all',
                        onAction: 'up.onCollapseAllClick'
                    }
                ]
            }
        )
        
        items.push({
            type: 'buttonGroup',
            items: [
                {
                    type: 'button',
                    ref: 'backtoday',
                    text: 'Go To Todays Date',
                    tooltip: 'Go To Todays Date',
                    icon: 'b-fa-hourglass',
                    onAction: 'up.onBackTodayDate'

                }
            ]
        })
        
        return {items}
       
    }

    /* Custom Filters */


    onExpandAllClick() {
        this.gantt.expandAll();
    }
    onCollapseAllClick() {
        this.gantt.collapseAll();
    }
    onBackTodayDate() {
        this.gantt.scrollToDate(new Date(), {
            block: 'center'
        })
    }


}


GanttToolbar.initClass();
