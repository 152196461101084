import React from 'react'
import CapGhanttAddChart from './CapGanttComponets/CapGhanttAddChart'
import CapGhanttCloseChart from './CapGanttComponets/CapGhanttCloseChart'
import Apis from '../../../../api'
import io from 'socket.io-client'
const config = require('../../../../config')

const socket = io(config.api_url + '/')


const CapGhanttChart = ({ team, location_id, subproject, phase_plane, work_packages, resources, resourcesShow, attributes, onChange, commitShow, setCommit_id, commitLength, setCloseCommit_id, calanderData, taskRefresh }) => {
    const [taskData, setTaskData] = React.useState([])
    const [showgant, setShowGant] = React.useState(true)
    const [refresh, setRefresh] = React.useState(false);
    const [resourcesData, setResourcesData] = React.useState([])
    React.useEffect(() => {
        let message = "hello";
        let demo
        if (team && team !== '$$all' && subproject && phase_plane && work_packages) {
            let filters = [
                { type: 'Task' },
                { "team.value": team.value },
                { delete_status: false },
                { isCompletedSuccessfully: false }]
            if (subproject && subproject !== '$$all' && subproject !== '$$alll') {
                demo = { "subproject.value": subproject.value }
                filters.push(demo)
            }
            if (phase_plane && phase_plane !== '$$all' && phase_plane !== '$$alll') {
                demo = { "phase_plane.value": phase_plane.value }
                filters.push(demo)
            }
            if (work_packages && work_packages !== '$$all' && work_packages !== '$$alll') {
                demo = { "work_packages.value": work_packages.value }
                filters.push(demo)
            }
            if (location_id) {
                demo = { location_id: location_id }
                filters.push(demo)
            }
            const raw = {
                filters: filters,
                team_id: team.value,
                status_code: {
                    value: 2
                }
            }
            
            socket.emit("task-commitment-filters", { message, raw });

        } else {
            setTaskData([])
            setShowGant(true)
            onChange({
                name: 'Commit Plan',
                text: "Commit the plan?",
                value: "add"
            })
        }

    }, [team, subproject, phase_plane, work_packages, location_id, refresh, taskRefresh])
    React.useEffect(() => {
        let message = "hello";
        let filters = [
            { type: 'Task' },
            { delete_status: false },
        ]
        let demo
        if (team && team !== '$$all') {
            demo = { "team.value": team.value }
            filters.push(demo)
        }
        if (subproject && subproject !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }
        const raw = {
            filters: filters,
            status_code: {
                value: 2
            }
        }

        socket.emit("resources-filters", { message, raw });
    }, [team, subproject, phase_plane, work_packages, location_id, refresh])


    React.useEffect(() => {
        socket.on("resource-filters-data", (data) => {
            setResourcesData(data.data)
        });
        socket.on("task-commitment-filters-data", (data) => {
           
            setTaskData(data.data)
            commitLength({ value: data.data.commit_plan.length })
            if (data.data.close_commit === true) {
                setShowGant(false)
                onChange({
                    name: 'Close Plan',
                    text: "Close the plan?",
                    value: "close"
                })
            } else {
                setShowGant(true)
                onChange({
                    name: 'Commit Plan',
                    text: "Commit the plan?",
                    value: "add"
                })
            }
        });
    }, [])

    const onRefresh = () => {
        setRefresh(Math.random())
    }
    const commitShows = ({ value }) => {        
        commitShow({
            value: value
        })
    }

    return (
        <>
            {showgant ?
                <CapGhanttAddChart
                    team={team}
                    taskData={taskData}
                    attributes={attributes}
                    resources={resources}
                    resourcesShow={resourcesShow}
                    resourcesData={resourcesData}
                    onChange={onRefresh}
                    commitShows={commitShows}
                    setCommit_id={setCommit_id}
                    calanderData={calanderData}
                />
                : <CapGhanttCloseChart
                    team={team}
                    taskData={taskData}
                    attributes={attributes}
                    resources={resources}
                    resourcesShow={resourcesShow}
                    resourcesData={resourcesData}
                    onChange={onRefresh}
                    commitShows={commitShows}
                    setCloseCommit_id={setCloseCommit_id}
                    calanderData={calanderData}
                />}


        </>
    )
}

export default CapGhanttChart