import React from 'react'
import Apis from '../../../api'
import swal from 'sweetalert'

const CostAttributeApi = (callback) => {
    const [costAttributes, setAttribute] = React.useState([])
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [values, setValues] = React.useState({
        attribute_name: '',
        tag_name: '',
        handle: '',
        unit_tag: '',
        unit_handle: '',
        id: '',
        owner: user.id
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    /* Function Enter values */
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === 'tag_name') {
            setValues({
                ...values,
                [name]: value,
                handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'unit_tag') {
            setValues({
                ...values,
                [name]: value,
                unit_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,
            })
        }
    }

    const handleEdit = (attribute, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        if (attribute.attribute_name === "Unit of Measurement") {
            setValues({
                ...values,
                attribute_name: attribute.attribute_name,
                unit_tag: attribute.tag_name,
                unit_handle: attribute.handle,
                id: attribute._id
            })
        } else if (attribute.attribute_name === "Cost Type") {
            setValues({
                ...values,
                attribute_name: attribute.attribute_name,
                tag_name: attribute.tag_name,
                handle: attribute.handle,
                id: attribute._id
            })
        }
    }

    /* Function For Get Cost Attribute List */

    React.useEffect(() => {
        getAttribute()
    }, [])

    const getAttribute = () => {
        Apis.getCostAttribute().then((res) => {
            setAttribute(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
    }


    /* Function For Add And Update Attributes */

    const handleSubmitTag = (formData, tag, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        /* Update Api */
        if (formData.id !== '') {
            if (formData.attribute_name === "Unit of Measurement") {
                if (formData.unit_tag === '' || formData.unit_tag === undefined) {
                    setError({
                        ...error,
                        error: 'Unit of measurement tag is required',
                        color: 'error',
                        open: true
                    })
                } else if (formData.unit_handle === '' || formData.unit_handle === undefined) {
                    setError({
                        ...error,
                        error: 'Unit handle is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': formData.id,
                        'attribute_name': formData.attribute_name,
                        'tag_name': formData.unit_tag,
                        'handle': formData.unit_handle,

                    }
                    Apis.updateCostAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setValues({
                            ...values,
                            attribute_name: '',
                            tag_name: '',
                            handle: '',
                            unit_tag: '',
                            unit_handle: '',
                            id: ''
                        })

                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }
            } else if (formData.attribute_name === "Cost Type") {
                if (formData.tag_name === '' || formData.tag_name === undefined) {
                    setError({
                        ...error,
                        error: 'Work Package tag is required',
                        color: 'error',
                        open: true
                    })
                } else if (formData.handle === '' || formData.handle === undefined) {
                    setError({
                        ...error,
                        error: 'Work Package handle is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': formData.id,
                        'attribute_name': formData.attribute_name,
                        'tag_name': formData.tag_name,
                        'handle': formData.handle,
                    }
                    Apis.updateCostAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setValues({
                            ...values,
                            attribute_name: '',
                            tag_name: '',
                            handle: '',
                            unit_tag: '',
                            unit_handle: '',
                            id: ''
                        })
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }
            }
            /* Add Api Call */
        } else if (tag === 'Unit of Measurement' && formData.id === '') {
            if (formData.unit_tag === '' || formData.unit_tag === undefined) {
                setError({
                    ...error,
                    error: 'Unit of measurement tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.unit_handle === '' || formData.unit_handle === undefined) {
                setError({
                    ...error,
                    error: 'Unit handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Unit of Measurement",
                    'tag_name': formData.unit_tag,
                    'handle': formData.unit_handle,
                    'owner': user.id
                }
                Apis.addCostAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        attribute_name: '',
                        tag_name: '',
                        handle: '',
                        unit_tag: '',
                        unit_handle: '',
                        id: ''
                    })
                    getAttribute()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        } else if (tag === 'Cost Type' && formData.id === '') {
            if (formData.tag_name === '' || formData.tag_name === undefined) {
                setError({
                    ...error,
                    error: 'Cost Type Attribute is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.handle === '' || formData.handle === undefined) {
                setError({
                    ...error,
                    error: 'Cost  handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Cost Type",
                    'tag_name': formData.tag_name,
                    'handle': formData.handle,
                    'owner': user.id
                }
                Apis.addCostAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        attribute_name: '',
                        tag_name: '',
                        handle: '',
                        unit_tag: '',
                        unit_handle: '',
                        id: ''
                    })
                    getAttribute()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }

    }

    /* Function For Delete Cost Attribute */

    const deleteAttribute = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Attribute Id is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        'status': status
                    }
                    Apis.deleteCostAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.message,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        });
    }

    return {
        costAttributes,
        values,
        error,
        handleChange,
        handleSubmitTag,
        handleEdit,
        deleteAttribute
    }
}

export default CostAttributeApi