import React from 'react'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Footer from '../../Layout/Footer'
import Apis from '../../../../api'
import swal from 'sweetalert'
import Toolbar from './compontes/toolbar'
import { Button } from 'reactstrap'
import LogList from './compontes/logList'


function MaterialEquipmentLogs() {
    const [materialList, setMaterialsList] = React.useState([])
    const [equipmentList, setEquipmentList] = React.useState([])
    const [taskData, setTaskDatas] = React.useState([])
    const [teamList, setTeam] = React.useState([])
    const [taskmaterialList, setTaskMaterialList] = React.useState([])
    const [taskequipmentList, setTaskEquipmentList] = React.useState([])
    const [suppliersList, setSupplierList] = React.useState([])
    const [refresh, setRefresh] = React.useState(false)


    const [toolbarState, setToolbarState] = React.useState({
        team: '',
        order_status: '',
        date: '',
        type_code: 1
    })

    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    const onResetToolbarState = () => setToolbarState({
        team: '',
        order_status: '',
        date: '',
        type_code: 1
    })

    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getTeam().then((res) => {
            setTeam(res.data.data.team)
        }).catch(error => {
            console.log(error.response.data)
        })
        Apis.getSuppliers().then((res) => {
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getMaterialEquipment().then((res) => {
            setEquipmentList(res.data.data.equipment)

        }).catch((error) => {
            console.log(error)
        })

    }, [])
    React.useEffect(() => {
        Apis.getAllTaskMaterial().then((res) => {
            setTaskMaterialList(res.data.data.taskmaterials)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getAllTaskEquipment().then((res) => {
            setTaskEquipmentList(res.data.data.taskequipments)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh])
    React.useEffect(() => {
        const filter = [{ type: 'Task' },
        { delete_status: false }]
        const raw = {
            "filter": filter
        }
        Apis.taskfilter(raw).then((res) => {
            setTaskDatas(res.data.tasks)
        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [])

    const OrderMaterial = (record) => {
        swal({
            title: "Are you sure want to order this material?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const user = JSON.parse(localStorage.getItem('user-info'))
                const raw = {
                    material_id: record._id,
                    user_id: user.id,
                    status: 'received',
                    ordered_time: new Date().getTime()
                }
                Apis.placeTaskMaterialOreded(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefresh(!refresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        });

    }
    const OrderEquipment = (record) => {
        swal({
            title: "Are you sure want to order this equipment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const user = JSON.parse(localStorage.getItem('user-info'))
                const raw = {
                    equipment_id: record._id,
                    user_id: user.id,
                    status: 'received',
                    ordered_time: new Date().getTime()
                }
                Apis.placeTaskEquipmentOreded(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefresh(!refresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        });

    }



    return (
        <>
            <div id="layout-wrapper">
                <Header title='M/E Logs | P2IC'></Header>
                <Sidebar title='Material_managment' show='MaterialEquipmentLogs'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">M/E Logs</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Material Manager</li>
                                            <li className="breadcrumb-item active">M/E Logs</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <Toolbar
                                                state={toolbarState}
                                                teams={teamList.map((i) => ({ value: i._id, label: i.name }))}
                                                onChange={onToolbarStateChange}
                                                onReset={onResetToolbarState}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <div className='container-fluid'>
                                                <div className="row align-self-end justify-content-end">
                                                    <div className="col-lg-6" style={{ display: 'contents' }}>
                                                        <Button
                                                            color="danger"
                                                            className="btn-primary btn waves-effect waves-light"
                                                            onClick={onResetToolbarState}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <LogList
                                                        state={toolbarState}
                                                        material={materialList}
                                                        equipment={equipmentList}
                                                        task={taskData}
                                                        team={teamList}
                                                        suppliers={suppliersList}
                                                        taskmaterial={taskmaterialList}
                                                        taskequipment={taskequipmentList}
                                                        refresh={refresh}
                                                        orderMaterial={OrderMaterial}
                                                        orderEquipment={OrderEquipment}
                                                        setRefresh={setRefresh}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default MaterialEquipmentLogs