import { DateHelper, StringHelper, TaskModel } from '@bryntum/gantt'
import './helper/GanttToolbar'

class MyTask extends TaskModel {
    static get fields() {
        return [
            'hoursWorked'
        ];
    }

    get workedHoursByDay() {
        const
            me = this,
            { startDate, endDate, isParent, duration } = me;

        // With Gantt using early rendering we can get here before dates are normalized, if so bail out to not cache
        // invalid values below
        if (!startDate || !endDate || !duration) {
            return null
        }

        if (!isParent && me._workedHoursByDay?.duration === duration && me._workedHoursByDay.startDateMs === startDate.getTime()) {
            return me._workedHoursByDay
        }

        const
            durationInDays = DateHelper.getDurationInUnit(startDate, endDate, 'd', false),
            workedHoursByDay = Array(durationInDays || 0).fill(0),
            calendar = me.project.calendar,
            hoursWorked = me.hoursWorked || [];

        let index = 0;

        // Rollup values from parent task's immediate children
        for (let i = 0; i < durationInDays; i++) {
            const
                intervalStart = DateHelper.add(startDate, i, 'd'),
                intervalEnd = DateHelper.add(intervalStart, 1, 'd');

            if (calendar.isWorkingTime(intervalStart, intervalEnd)) {
                if (isParent) {
                    workedHoursByDay[i] = this.children.reduce((total, child) => {
                        if (DateHelper.intersectSpans(child.startDate, child.endDate, intervalStart, intervalEnd)) {
                            const startDiff = DateHelper.getDurationInUnit(startDate, child.startDate, 'd');
                            return total += child.workedHoursByDay[i - startDiff];
                        }
                        else {
                            return total;
                        }
                    }, 0);
                }
                else {
                    workedHoursByDay[i] = hoursWorked[index];
                }
                index++;
            }
        }

        // Cache by start + duration
        workedHoursByDay.startDateMs = startDate.getTime();
        workedHoursByDay.duration = duration;

        me._workedHoursByDay = workedHoursByDay;

        return workedHoursByDay;
    }
}

export const ganttConfig = {
    tbar: { type: 'gantt_toolbar' },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    subGridConfigs: {
        locked: {
            flex: 8
        },
        normal: {
            flex: 10
        }
    },
    columnLines: true,
    viewPreset: {
        base: 'weekAndDay',
        tickWidth: 50,
        tickHeight: 50,
        shiftIncrement: 1,
        shiftUnit: 'day',

        timeResolution: {
            unit: 'day',
            increment: 1
        },
        headers: [
            {
                unit: 'month',
                dateFormat: 'MMM YYYY',
                align: 'center'
            },
            {
                unit: 'week',
                renderer: (startDate, endDate) => `${DateHelper.format(startDate, 'DD MMM')} - ${DateHelper.format(endDate, 'DD MMM')} (Week ${DateHelper.format(startDate, 'WW')})`
            },
            {
                unit: 'day',
                dateFormat: 'ddd, D'
            }
        ],

    },
    timeRangesFeature: {
        showHeaderElements: false,
        showCurrentTimeLine: true
    },
    columns: [
        {
            type: 'name',
            text: 'Milestone name',
            editor: false,
            width: 300,
            sortable: false,
            filterable: false,

            renderer: ({ record }) => {
                return record.name
            }
        },
        { type: 'duration', width: 130, editor: false, sortable: false, filterable: false }

    ],
    sortFeature: {
        field: 'task_start_date'
    },

    rollupsFeature: {
        disabled: true
    },
    baselinesFeature: {
        disabled: true
    },
    progressLineFeature: {
        disabled: true
    },
    taskTooltipFeature: {
        disabled: true
    },
    taskMenuFeature: false,
    filterFeature: true,
    taskDragFeature: {
        disabled: true
    },
    dependencyEditFeature: false,
    projectLinesFeature: {
        disabled: true
    },
    headerMenuFeature: {
        disabled: true
    },
    taskEditFeature: {
        disabled: true
    },
    percentBarFeature: false,
    dependenciesFeature: {
        disabled: true
    },
    taskResizeFeature: {
        disabled: true
    },
    taskRenderer({ taskRecord }) {
        if (!taskRecord.isMilestone) {
            return [
                {
                    tag: 'div',
                    class: 'taskName',
                    html: StringHelper.encodeHtml(taskRecord.name)
                }
            ]

        }
    },
}

export const projectConfig = {
    calendar: 'general',
    startDate: DateHelper.add(DateHelper.clearTime(new Date()), -200, 'day'),
    endDate: DateHelper.add(DateHelper.clearTime(new Date()), +200, 'day'),
    hoursPerDay: 12,
    daysPerWeek: 5,
    daysPerMonth: 20,
    autoLoad: true,
    stm: {
        autoRecord: true
    },

    taskModelClass: MyTask,
};