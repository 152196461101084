import React from 'react'
import swal from 'sweetalert'
import Apis from '../../../../api'
import keyBy from 'lodash/keyBy'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";

const headers = [
    { title: '#', prop: 'id' },
    { title: 'Company/Supplier', prop: 'company_name', isFilterable: true, isSortable: true },
    { title: 'Equipment Name', prop: 'equipment', isFilterable: true, isSortable: true },
    { title: 'Description', prop: 'description', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' }
];

const Equipment = ({ refresh, handleEdit, suppliers }) => {
    const [equipmentList, setEquipmentList] = React.useState([])
    const [equipments, setEquipments] = React.useState([])
    const [equipment, setEquipment] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(false)
    const supplierById = keyBy(suppliers, '_id')
    React.useEffect(() => {
        Apis.getMaterialEquipment().then((res) => {
            setEquipmentList(res.data.data.equipment)
        }).catch((error) => {
            console.log(error)
        })

    }, [refresh, refreshList])

    React.useEffect(() => {
        let list = []
        const user = JSON.parse(localStorage.getItem('user-info'))
        equipmentList.map((elm) => {
            if (user.role === 'supplier') {
                var email = supplierById[elm.supplier]?.email
                if (email === user.email) {
                    list.push(elm)
                }
            } else {
                list.push(elm)
            }
            return list

        })
        setEquipments(list)
    }, [equipmentList, suppliers])

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 0;
        equipments && equipments.map((elm) => {
            i++;
            if (elm.status) {
                demo = {
                    id: i,
                    company_name: supplierById[elm.supplier]?.company_name,
                    equipment: elm.equipment,
                    description: elm.description,
                    status: false,
                    action: <><div class="d-flex addremove_btn">
                        <button className="btn btn-primary btn-sm mx-1" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                    </div></>
                }
            } else {
                demo = {
                    id: i,
                    company_name: supplierById[elm.supplier]?.company_name,
                    equipment: elm.equipment,
                    description: elm.description,
                    status: 'line-through',
                    action: <> <div class="d-flex addremove_btn">
                        <button className="btn btn-primary btn-sm" onClick={() => handleDelete(elm._id, 1)}><i className="fas fa-trash-restore"></i></button>
                    </div></>
                }
            }
            list.push(demo)

        })
        setEquipment(list)

    }, [equipments])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                Apis.deleteMaterialEquiment(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }

    return (
        <>
            <div className="table-responsive">
                <DatatableWrapper
                    body={equipment}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [15, 25, 50, 100, 200]
                        }
                    }}>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter placeholder="Type here to search" />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody rowProps={(row) => ({
                            style: { textDecorationLine: row.status }
                        })} />
                    </Table>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >

                        </Col>
                    </Row>
                </DatatableWrapper>
            </div>
        </>
    )
}

export default Equipment