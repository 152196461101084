import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import PvrToolbar from './componts/PvrToolbar'
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import ProjectApi from '../Projects/ProjectApi'
import Apis from '../../../api'
import GanttChart from './componts/GanttChart'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'

const status_codes = [
    {
        value: 1,
        label: 'All'
    }, {
        value: 2,
        label: 'Work in Process'
    },
    {
        value: 3,
        label: 'Work Completed'
    }
]

function Program_Variance_Reports() {

    const { teamList, state } = CollaboratorApi()
    const { projectList } = ProjectApi()
    const { attributes } = AttributeApiCall()

    const [teams, setTeams] = React.useState([])
    const [projectLists, setProjectLists] = React.useState([])
    const [planList, setPlanList] = React.useState([])
    const [workList, setWorkList] = React.useState([])
    const [toolbarState, setToolbarState] = React.useState({
        status_code: { value: 1, label: "All" }
    })

    /* Filters option list */
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])

    React.useEffect(() => {
        if (projectList.length > 0) {
            setProjectLists(projectList.projects)
        }
    }, [projectList])

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (toolbarState.subproject) {
            const raw = {
                "project_id": toolbarState.subproject.value,
                "user_id": user.id
            }
            Apis.getPlan(raw).then((res) => {
                setTimeout(() => {
                    setPlanList(res.data.data.plans)
                    setWorkList([])
                }, 1000)


            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [toolbarState])

    React.useEffect(() => {
        if (toolbarState.phase_plane !== '$$all') {
            planList.map((elm) => {
                if (elm._id === toolbarState.phase_plane.value) {
                    elm.packages.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                    setWorkList(elm.packages)
                    return true
                }
                return true
            })

        }
    }, [planList, toolbarState])




    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Program Variance Reports | P2IC'></Header>
                <Sidebar title='' show='AnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Analytics & Reports</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Analytics & Reports</li>
                                            <li className="breadcrumb-item active">Program variance reports</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-projext-sec ps-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <PvrToolbar
                                        teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                                        sub_project={projectLists.map((i) => ({ value: i._id, label: i.name }))}
                                        phase_plane={planList.map((i) => ({ value: i._id, label: i.name }))}
                                        work_packages={workList.map((i) => ({ value: i.value, label: i.label }))}
                                        status_codes={status_codes.map((i) => ({ value: i.value, label: i.label }))}
                                        onChange={onToolbarStateChange}
                                        state={toolbarState}
                                        attributes={attributes}
                                    />
                                </div>
                                <div className='col-lg-12 gant_chart_lap' style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1
                                }}>
                                    <GanttChart
                                        team={toolbarState.team}
                                        location_id={toolbarState.location_id}
                                        subproject={toolbarState.subproject}
                                        phase_plane={toolbarState.phase_plane}
                                        work_packages={toolbarState.work_packages}
                                        status_code={toolbarState.status_code}
                                        calanderData={state}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Program_Variance_Reports