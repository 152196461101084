import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
const headers = [
    { title: '#', prop: 'id' },
    { title: 'First Name', prop: 'first_name', isFilterable: true, isSortable: true },
    { title: 'Last Name', prop: 'last_name', isFilterable: true, isSortable: true },
    { title: 'Abrv.', prop: 'abrv', isFilterable: true, isSortable: true },
    { title: 'Handle', prop: 'handle', isFilterable: true, isSortable: true },
    { title: 'Email', prop: 'email', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' },

];




const MemberTable = ({ data, handleDeleteMember, handleEditTeam, handleForgetPassword }) => {
    const [memberList, setMemberList] = React.useState([])


    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 1;
        if (data.length > 0) {
            const members = data.member
            members.map((elm, key) => {
                if (elm.status === 1 && elm.role !== 'admin') {
                    if (elm.is_registered === false) {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: '#ffa500',
                            status: false,
                            action: <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Resend" onClick={() => handleForgetPassword(elm.email)}>Resend</button>
                            </div></>

                        }
                    } else {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: false,
                            status: false,
                            action: <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                            </div></>

                        }
                    }

                    list.push(demo)
                    i++
                } else {
                    if (elm.role !== 'admin') {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: elm.is_registered,
                            status: 'line-through',
                            action: <> <div className="d-flex addremove_btn">
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteMember(elm._id, 1)} ><i class="fas fa-trash-restore"></i></button>
                            </div></>

                        }
                        list.push(demo)
                        i++
                    }
                }
                return list
            })
        }
        setMemberList(list)
    }, [data])



    return (
        <>
            <DatatableWrapper
                body={memberList}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody rowProps={(row) => ({
                        style: { background: row.is_registered, textDecorationLine: row.status }
                    })} />
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >

                    </Col>
                </Row>
            </DatatableWrapper></>
    );
};

export default MemberTable;
