import React from 'react'
import Apis from '../../../api'
import swal from 'sweetalert'

const AttributeApiCall = (callback) => {
    const [attributes, setAttribute] = React.useState([])
    const [values, setValues] = React.useState({
        attribute_name: '',
        tag_name: '',
        handle: '',
        color: '#4D4D4D',
        loction_tag: '',
        location_handle: '',
        work_tag: '',
        work_handle: '',
        id: ''
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [popUp, setPopUp] = React.useState({
        location: '',
        discipline: '',
        workPackage: '',

    })

    const [state, setState] = React.useState({
        color: '#4D4D4D',
        color_picker: [
            '#4D4D4D',
            '#999999',
            '#FFFFFF',
            '#F44E3B',
            '#FE9200',
            '#FCDC00',
            '#DBDF00',
            '#A4DD00',
            '#68CCCA',
            '#73D8FF',
            '#AEA1FF',
            '#FDA1FF',
            '#333333',
            '#808080',
            '#cccccc',
            '#D33115',
            '#E27300',
            '#FCC400',
            '#B0BC00',
            '#68BC00',
            '#16A5A5',
            '#009CE0',
            '#7B64FF',
            '#FA28FF',
            '#000000',
            '#666666',
            '#B3B3B3',
            '#9F0500',
            '#C45100',
            '#FB9E00',
            '#808900',
            '#194D33',
            '#0C797D',
            '#0062B1',
            '#653294',
            '#AB149E',
        ],
        show_color_picker: false
    })
    /* Get Attribute List  */
    React.useEffect(() => {
        getAttribute()
    }, [])

    const getAttribute = () => {
        Apis.getAttribute().then((res) => {
            setAttribute(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
    }




    /* Api For Delete Attribute */
    const deleteAttribute = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Attribute Id is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        'status': status
                    }
                    Apis.deleteAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.message,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        });


    }

    /* Api For Add Attribute */

    const handleSubmitTag = (formData, tag, event) => {
        /* Update Api */
        if (formData.id !== '') {
            if (formData.attribute_name === "Location") {
                if (formData.loction_tag === '' || formData.loction_tag === undefined) {
                    setError({
                        ...error,
                        error: 'Location tag is required',
                        color: 'error',
                        open: true
                    })
                } else if (formData.location_handle === '' || formData.location_handle === undefined) {
                    setError({
                        ...error,
                        error: 'Location handle is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': formData.id,
                        'attribute_name': formData.attribute_name,
                        'tag_name': formData.loction_tag,
                        'handle': formData.location_handle,
                        'color': undefined
                    }
                    Apis.updateAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        handleClose()
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }
            } else if (formData.attribute_name === "Work Package") {
                if (formData.work_tag === '' || formData.work_tag === undefined) {
                    setError({
                        ...error,
                        error: 'Work Package tag is required',
                        color: 'error',
                        open: true
                    })
                } else if (formData.work_handle === '' || formData.work_handle === undefined) {
                    setError({
                        ...error,
                        error: 'Work Package handle is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': formData.id,
                        'attribute_name': formData.attribute_name,
                        'tag_name': formData.work_tag,
                        'handle': formData.work_handle,
                        'color': undefined
                    }
                    Apis.updateAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        handleClose()
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }
            } else if (formData.attribute_name === "Discipline") {
                if (formData.tag_name === '' || formData.tag_name === undefined) {
                    setError({
                        ...error,
                        error: 'Discipline tag is required',
                        color: 'error',
                        open: true
                    })
                } else if (formData.handle === '' || formData.handle === undefined) {
                    setError({
                        ...error,
                        error: 'Discipline handle is required',
                        color: 'error',
                        open: true
                    })
                }
                else if (formData.color === '' || formData.color === undefined) {
                    setError({
                        ...error,
                        error: 'Discipline color is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': formData.id,
                        'attribute_name': formData.attribute_name,
                        'tag_name': formData.tag_name,
                        'handle': formData.handle,
                        'color': formData.color
                    }
                    Apis.updateAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        handleClose()
                        getAttribute()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
            /* Add Api Call */
        } else if (tag === 'Location' && formData.id === '') {
            if (formData.loction_tag === '' || formData.loction_tag === undefined) {
                setError({
                    ...error,
                    error: 'Location tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.location_handle === '' || formData.location_handle === undefined) {
                setError({
                    ...error,
                    error: 'Location handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Location",
                    'tag_name': formData.loction_tag,
                    'handle': formData.location_handle,
                    'color': undefined
                }
                Apis.addAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    handleClose()
                    getAttribute()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        } else if (tag === 'Work Package' && formData.id === '') {
            if (formData.work_tag === '' || formData.work_tag === undefined) {
                setError({
                    ...error,
                    error: 'Work Package tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.work_handle === '' || formData.work_handle === undefined) {
                setError({
                    ...error,
                    error: 'Work  handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Work Package",
                    'tag_name': formData.work_tag,
                    'handle': formData.work_handle,
                    'color': undefined
                }
                Apis.addAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    handleClose()
                    getAttribute()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        } else if (tag === 'Discipline' && formData.id === '') {
            if (formData.tag_name === '' || formData.tag_name === undefined) {
                setError({
                    ...error,
                    error: 'Discipline tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.handle === '' || formData.handle === undefined) {
                setError({
                    ...error,
                    error: 'Discipline handle is required',
                    color: 'error',
                    open: true
                })
            }
            else if (formData.color === '' || formData.color === undefined) {
                setError({
                    ...error,
                    error: 'Discipline color is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Discipline",
                    'tag_name': formData.tag_name,
                    'handle': formData.handle,
                    'color': formData.color
                }
                Apis.addAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    handleClose()
                    getAttribute()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }


        }

    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === 'loction_tag') {
            setValues({
                ...values,
                [name]: value,
                location_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'tag_name') {
            setValues({
                ...values,
                [name]: value,
                handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'work_tag') {
            setValues({
                ...values,
                [name]: value,
                work_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,

            })
        }
    }

    const handleClick = () => {
        setState({ ...state, show_color_picker: !state.show_color_picker })
    }

    const handleColorChange = (color) => {
        setValues({
            ...values,
            color: color.hex
        })
        setState({ ...state, color: color.hex })
    }
    const handlePopUp = (tag, event) => {

        if (tag === 'Location') {
            setPopUp({
                ...popUp,
                location: 'show'
            })
            setError({
                ...error,
                error: '',
                color: '',
                open: false
            })
            setValues({
                ...values,
                attribute_name: '',
                tag_name: '',
                handle: '',
                color: '#4D4D4D',
                loction_tag: '',
                location_handle: '',
                id: ''

            })
        } else if (tag === 'Work Package') {
            setPopUp({
                ...popUp,
                workPackage: 'show'
            })
            setError({
                ...error,
                error: '',
                color: '',
                open: false
            })
            setValues({
                ...values,
                attribute_name: '',
                tag_name: '',
                handle: '',
                color: '#4D4D4D',
                loction_tag: '',
                location_handle: '',
                work_handle: '',
                work_tag: '',
                id: ''

            })
        } else if (tag === 'Discipline') {
            setPopUp({
                ...popUp,
                discipline: 'show'
            })
            setError({
                ...error,
                error: '',
                color: '',
                open: false
            })
            setValues({
                ...values,
                attribute_name: '',
                tag_name: '',
                handle: '',
                color: '#4D4D4D',
                loction_tag: '',
                location_handle: '',
                id: ''

            })
        }
    }
    const handleClose = (event) => {
        setPopUp({
            ...popUp,
            discipline: '',
            location: '',
            workPackage: ''
        })
    }

    const handleEdit = (attribute, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        if (attribute.attribute_name === "Location") {
            setValues({
                ...values,
                attribute_name: attribute.attribute_name,
                loction_tag: attribute.tag_name,
                location_handle: attribute.handle,
                id: attribute._id
            })
            setPopUp({
                ...popUp,
                location: 'show'
            })
        } else if (attribute.attribute_name === "Work Package") {
            setValues({
                ...values,
                attribute_name: attribute.attribute_name,
                work_tag: attribute.tag_name,
                work_handle: attribute.handle,
                id: attribute._id
            })
            setPopUp({
                ...popUp,
                workPackage: 'show'
            })
        } else if (attribute.attribute_name === "Discipline") {
            setValues({
                ...values,
                attribute_name: attribute.attribute_name,
                tag_name: attribute.tag_name,
                handle: attribute.handle,
                color: attribute.color,
                id: attribute._id
            })
            setPopUp({
                ...popUp,
                discipline: 'show'
            })

        }
    }

    return {
        attributes,
        values,
        error,
        state,
        popUp,
        handleChange,
        deleteAttribute,
        handleClick,
        handleColorChange,
        handleSubmitTag,
        handlePopUp,
        handleClose,
        handleEdit
    }
}

export default AttributeApiCall
