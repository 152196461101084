import React from 'react'
import Auth from './Auth'
import Alert from '../../../helpers/Alert'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'

function ChangeSystemPassword() {
    const { values, error, handleChange, handleSystemSubmit } = Auth()
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Change System Password | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Change System Password</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Profile</li>
                                            <li className="breadcrumb-item active">Change System Password</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-6">
                                            <div className=" shadow-none card">
                                                <div className='card-block'>
                                                    <div className="account-box">
                                                        <div className="card-box shadow-none p-4">
                                                            <div className="p-4">
                                                                <form onSubmit={handleSystemSubmit}>
                                                                    <div className="form-group mb-3">
                                                                        <label for="oldPassword" className="form-label">Old Password</label>
                                                                        <input type="password" className="form-control" name='current_password' value={values.current_password} id="oldPassword" placeholder='Enter current password' onChange={handleChange} />
                                                                    </div>

                                                                    <div className="form-group mb-3">
                                                                        <label for="newPassword" className="form-label">New Password</label>
                                                                        <input type="password" className="form-control" name='new_password' value={values.new_password} id="newPassword" placeholder='Enter new password' onChange={handleChange} />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label for="confirmPasssword" className="form-label">Confirm Password</label>
                                                                        <input type="password" className="form-control" name='confirm_password' value={values.confirm_password} id="confirmPasssword" placeholder='Enter confirm password' onChange={handleChange} />
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <div className="col-12 text-end">
                                                                            <button class="btn btn-primary w-md waves-effect waves-light">Change Password</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default ChangeSystemPassword
